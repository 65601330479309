import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getPagosPorOrdenServicio = async (user, folio) => {
  let url = API + "orders/getPagosOrden/" + folio;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const nuevoPagoOrdenServicio = async (user, values) => {
  let url = API + "orders/nuevoPagoOrden/";

  //Reviso que el numero de recibo no tenga ninguna letra
  values.noRecibo = values.noRecibo.toString().replace(/\D/g, "");

  //Se le añade al número de recibo la letra que seleccionaron en el formulario
  values.noRecibo = values.letra.split("")[0] + values.noRecibo;

  //Borro la letra porque no la necesito en la llamada
  delete values.letra;

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);

    toast.error(error.response.data.message);

    return error;
  }
};

export const actualizarPagoOrdenServicio = async (user, values) => {
  let url = API + "orders/updatePago/" + values.id;

  //Reviso que el numero de recibo no tenga ninguna letra
  values.noRecibo = values.noRecibo.toString().replace(/\D/g, "");

  //Se le añade la letra que se selecciona y se le añade el número de recibo.
  values.noRecibo = values.letra.split("")[0] + values.noRecibo;

  //Se elimina la letra
  delete values.letra;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const eliminarPagoOrdenServicio = async (user, values) => {
  
  let url = API + "orders/eliminarPago/" + values;


  
  try {
    const response = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
