import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { AuthContext } from "../../auth/authContext";
import { createUser } from "../../API/ApiUsers";
import {
    deleteEliminarDocumento,
  postCrearDocumento,
  putEditarDocumento,
} from "../../API/ApiDocumentos";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa ").required("Requerido"),
  obligatorio: yup.string("Ingresa ").required("Requerido"),
});

export const DocumentosCrudComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      nombre:
        props.crudAction.action != "create" ? props.crudAction.data.nombre : "",
      obligatorio:
        props.crudAction.action != "create"
          ? props.crudAction.data.obligatorio
          : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      
      if (props.crudAction.action == "create") {
        postCrearDocumento(user, values).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Documento Agregado");
            props.setCrudAction({ data: null, action: null });
          } else {
            props.setCrudAction({ data: null, action: null });
          }
        });
      }
      if (props.crudAction.action == "update") {
        putEditarDocumento(user, props.crudAction.data.id, values).then(
          (resultado) => {
            if (resultado.status == 200) {
              toast.success("Documento Editado");
              props.setCrudAction({ data: null, action: null });
            } else {
              props.setCrudAction({ data: null, action: null });
            }
          }
        );
      }
      if (props.crudAction.action == "delete") {
        deleteEliminarDocumento(user, props.crudAction.data.id, values).then(
            (resultado) => {
              if (resultado.status == 200) {
                toast.success("Documento Eliminado");
                props.setCrudAction({ data: null, action: null });
              } else {
                props.setCrudAction({ data: null, action: null });
              }
            }
          );
      }

      
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-4 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>Agregar {props.nombre}</h6>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <TextField
                size="small"
                fullWidth
                id="nombre"
                name="nombre"
                label="Nombre"
                className="mb-3"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                variant="standard"
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                disabled={isDisabled}
              />
              <TextField
                size="small"
                fullWidth
                id="obligatorio"
                name="obligatorio"
                className="mb-3"
                label="Obligatorio"
                variant="standard"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  native: true,
                }}
                value={formik.values.obligatorio}
                onChange={formik.handleChange}
                error={
                  formik.touched.obligatorio &&
                  Boolean(formik.errors.obligatorio)
                }
                helperText={
                  formik.touched.obligatorio && formik.errors.obligatorio
                }
                disabled={isDisabled}
              >
                <option value={""}>Selecciona</option>
                <option value={"true"}>Obligatorio</option>
                <option value={"false"}>No Obligatorio</option>
              </TextField>

              <button
                className="my-4 btn btn-primary w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                {props.crudAction.action == "create" ? "Crear" : null}
                {props.crudAction.action == "update" ? "Actualizar" : null}
                {props.crudAction.action == "delete" ? "Eliminar" : null}{" "}
                {props.nombre}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
