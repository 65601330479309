import React from "react";
import styles from "./UltimosPagosRegistradosComponent.module.css";
import Button from "@mui/material/Button";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import jwt_decode from "jwt-decode";
import { AuthContext } from "./../../auth/authContext";
import { ActualizadorContext } from "./../../actualizadorContext/actualizadorContext";

import { DateTime } from "luxon";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { addPrecioMantenimientoAPI } from "../../API/ApiPagosRegistrados";

import style from "./UltimosPagosRegistradosComponent.module.css";
import { reimprimirTicket } from "../../API/ApiImpresoraTickets";

import Skeleton from "@mui/material/Skeleton";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

const UltimosPagosRegistradosComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const { actualizarVentanaContext, dispatchActualizarVentanaContext } =
    React.useContext(ActualizadorContext);

  const [isDisabled, setIsDisabled] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);

  const [finalData, setFinalData] = React.useState({
    pagosOrdenes: [],
    pagosPrevision: [],
    arregloUnificado: [],
  });

  React.useEffect(() => {
    //Primero necesito obtener la fecha del día de hoy para el corte
    const now = DateTime.now().toISODate();
    var { id } = jwt_decode(user.accessToken);

    addPrecioMantenimientoAPI(user, now, now, id).then((resultado) => {
      if (resultado.status === 200) {
        console.log(resultado.data);
        setFinalData(resultado.data);
        setIsLoading(false);
      } else {
        setFinalData({
          pagosOrdenes: [],
          pagosPrevision: [],
          arregloUnificado: [],
        });
      }
    });
  }, [actualizarVentanaContext]);

  return (
    <div className={`container-fluid ${style.contenedorUltimosPagos}`}>
      <div className="row">
        <div className="col-12">
          <h6>
            Útimos Pagos Registrados <br />{" "}
            <small>
              {" "}
              <CalendarTodayIcon fontSize="small" />{" "}
              {CambiarFormatoFechaHelper(DateTime.now().toISODate())}
            </small>
          </h6>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-2">
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
              <Skeleton
                variant="rectangular"
                width="100%"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ paddingTop: "30%" }} />
              </Skeleton>
            </>
          ) : (
            <>
              {finalData.arregloUnificado.map((pago) => (
                <React.Fragment key={pago.idpago}>
                  <div className="card mb-2">
                    <div className={`${styles.cardPago} card-body`}>
                      <div className="d-flex justify-content-between">
                        <h6></h6>
                        <h6>
                          {pago.seriePago}-{pago.folio}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h6>Fecha / Hora</h6>
                        <h6>
                          {DateTime.fromISO(pago.createdAt).toFormat("f")}
                        </h6>
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between strong">
                        <h6>Método</h6>
                        <h6>
                          {pago.idtipoPago == 1
                            ? "Efectivo"
                            : pago.idtipoPago == 2
                            ? "Tarjeta"
                            : "No Identificado  "}
                        </h6>
                      </div>
                      <div className="d-flex justify-content-between strong">
                        <h6>Total</h6>
                        <h6>${pago.monto}</h6>
                      </div>
                      <div className="d-flex justify-content-center strong">
                        <Button
                          variant="text"
                          onClick={() => {
                            reimprimirTicket({ idpago: pago.idpago }).then(
                              (resultado) => {}
                            );
                          }}
                        >
                          <LocalPrintshopIcon fontSize="small" /> Reimpresión
                        </Button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UltimosPagosRegistradosComponent;
