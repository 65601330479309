import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { addContratoCancelado, newContract } from "../../API/ApiContratos";

const validationSchema = yup.object({
  noContrato: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
  observaciones: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
});

export const CancelarFolioPrevisionComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      noContrato: "",
      observaciones: "",
      serie: "C",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      addContratoCancelado(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Contrato Cancelado Correctamente");
          props.setCrudAction({ action: null, data: null });
        }
        setIsDisabled(false);
         
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-9 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6> {props.nombre}</h6>
                <small className="text-muted">
                  Ingresa los datos del folio a Cancelar.
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">Datos de contrato a cancelar.</div>
                </div>

                <div className="row row-cols-md-2">
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Serie
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.serie}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"C"}
                        inputProps={{
                          name: "serie",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"C"}>C</option>
                        <option value={"SM"}>SM</option>
                        <option value={"BB"}>BB</option>
                        <option value={"S"}>S</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="noContrato"
                      name="noContrato"
                      label="Folio"
                      type={"number"}
                      min="1"
                      className="mb-3"
                      value={formik.values.noContrato}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.noContrato &&
                        Boolean(formik.errors.noContrato)
                      }
                      helperText={
                        formik.touched.noContrato && formik.errors.noContrato
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="row row-cols-md-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="observaciones"
                    name="observaciones"
                    label="Observaciones"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.observaciones &&
                      Boolean(formik.errors.observaciones)
                    }
                    helperText={
                      formik.touched.observaciones &&
                      formik.errors.observaciones
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <button
                className="my-4 btn btn-danger w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Proceder a cancelar folio
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
