import { DateTime } from "luxon";

const CambiarFormatoFechaHelper = (fecha) => {
  let manejador = DateTime.fromISO(fecha)
    .setZone("America/Mexico_City")
    .toFormat("D");

  if (manejador == "Invalid DateTime") {
    return "";
  }

  return manejador;
};

export default CambiarFormatoFechaHelper;
