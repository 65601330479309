import { NuevaAPICobranza } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getBitacoraDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/obtenerBitacoraContrato/" + values;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
export const getBitacoraDeOrden = async (user, values) => {
  let url = NuevaAPICobranza + "orden/obtenerBitacoraOrden/" + values;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const postComentarioBitacoraDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/addRegistroBitacora";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const postComentarioBitacoraDeOrden = async (user, values) => {
  let url = NuevaAPICobranza + "orden/addRegistroBitacora";

  
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteComentarioBitacoraDeContrato = async (user, values) => {
  let url = NuevaAPICobranza + "prevision/eliminarRegistroComentario/" + values;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};

export const deleteComentarioBitacoraDeOrdenDeServicio = async (
  user,
  values
) => {
  let url = NuevaAPICobranza + "orden/eliminarRegistroComentario/" + values;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
        maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
    return error;
  }
};
