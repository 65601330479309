import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  searchContracts,
  searchEspaciosPorContratoPersonasFinado,
} from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import styles from "./BuscadorDeEspaciosPorNombreComponent.module.css";

export const BuscadorDeEspaciosPorNombreComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [contratoInactivo, setContratoInactivo] = React.useState(false);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={finalData}
        getOptionLabel={(option) =>
          `Finado: ${option.persona} 
          ${
            option.auxNoContrato === null
              ? ""
              : ", Contrato: " + option.auxNoContrato
          } 
          
          ${option.auxTitular === null ? "" : ", Titular: " + option.auxTitular}
          `
        }
        onInputChange={(event, option) => {
          
          if (option !== "") {
            searchEspaciosPorContratoPersonasFinado(
              user,
              event.target.value.toString().toUpperCase()
            ).then((resultado) => {
              if (resultado.status == 200) {
                setFinalData(resultado.data);
              } else {
                setFinalData([]);
              }
            });
          }
        }}
        onChange={(event, option) => {
          if (option !== null) {
            props.setNumeroContratoEncontrado(option);
          }
          setContratoInactivo(false);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar por titular de contrato, número de contrato o nombre del finado."
          />
        )}
      />

      {contratoInactivo == true ? (
        <>
          <div className={styles.buscadorInabilitados}>
            <div className={styles.buscadorInabilitadosCenter}>
              <h5>
                {" "}
                <strong>
                  {" "}
                  Este Contrato esta inhabilitado, <br /> Confirma la
                  información en Cobranza{" "}
                </strong>{" "}
              </h5>

              <br />
              <br />
              <div
                className="btn btn-success"
                onClick={() => {
                  setContratoInactivo(false);
                }}
              >
                Cerrar
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
