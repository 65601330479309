import { NuevaAPICobranza } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getGenerarStringConekta = async (user, values) => {
    let url = NuevaAPICobranza + "prevision/generarStringContrato/" + values;
    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
          maxBodyLength: Infinity,
        },
      });
  
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      return error;
    }
  };