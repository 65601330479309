import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { createNuevoServicio, updateServicio } from "../../API/ApiServiciosOrdenesServicio";

const validationSchema = yup.object({
  nombreServicio: yup.string("Ingresa Nombre").required("Nombre requerido"),
});

export const EditServicioOrdenesServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      nombreServicio: props.crudAction.data.nombreServicio,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      updateServicio(user, props.crudAction.data.id, values).then((resultado) => {
        if (resultado.status == 200) {
          props.setCrudAction({ action: null, data: null });
        }

        setIsDisabled(false);
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-5">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>{props.nombre}</h6>
                  <small className="text-muted">
                    Ingresa la información siguiente:
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} className="row row-cols-md-1">
            <div className="col">
              <TextField
                size="small"
                fullWidth
                id="nombreServicio"
                name="nombreServicio"
                label="Nombre Servicio"
                type={"text"}
                className="mb-3"
                value={formik.values.nombreServicio}
                onChange={formik.handleChange}
                variant="standard"
                error={
                  formik.touched.nombreServicio &&
                  Boolean(formik.errors.nombreServicio)
                }
                helperText={
                  formik.touched.nombreServicio && formik.errors.nombreServicio
                }
                disabled={isDisabled}
              />
            </div>
            <div className="col">
              <button
                className="my-4 btn btn-warning w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Editar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
