import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { getAllUniplexByIdJardin } from "../../API/ApiUniplex";
import { AuthContext } from "../../auth/authContext";
import { AddUniplexScreenComponent } from "../../Components/AddUniplexScreenComponent/AddUniplexScreenComponent";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import { AsignarFallecidosAEspacioUniplexComponent } from "../../Components/AsignarFallecidosAEspacioUniplexComponent/AsignarFallecidosAEspacioUniplexComponent";
import { AsignarContratoAEspacioUniplexComponent } from "../../Components/AsignarContratoAEspacioUniplexComponent/AsignarContratoAEspacioUniplexComponent";
import { getAllDuplexByIdJardin } from "../../API/ApiDuplex";
import { AddDuplexScreenComponent } from "../../Components/AddDuplexScreenComponent/AddDuplexScreenComponent";
import { AsignarFallecidosAEspacioDuplexComponent } from "../../Components/AsignarFallecidosAEspacioDuplexComponent/AsignarFallecidosAEspacioDuplexComponent";
import { AsignarContratoAEspacioDuplexComponent } from "../../Components/AsignarContratoAEspacioDuplexComponent/AsignarContratoAEspacioDuplexComponent";
import { getAllMuralesByIdJardin } from "../../API/ApiMurales";
import { AddMuralesScreenComponent } from "../../Components/AddMuralesScreenComponent/AddMuralesScreenComponent";
import { AsignarFallecidosAEspacioMuralesComponent } from "../../Components/AsignarFallecidosAEspacioMuralesComponent/AsignarFallecidosAEspacioMuralesComponent";
import { AsignarContratoAEspacioMuralesComponent } from "../../Components/AsignarContratoAEspacioMuralesComponent/AsignarContratoAEspacioMuralesComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AsignarFechaDeinicioDePagoaEspacioComponent } from "./../../Components/AsignarFechaDeinicioDePagoaEspacioComponent/AsignarFechaDeinicioDePagoaEspacioComponent";
import CachedIcon from "@mui/icons-material/Cached";
const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const MuralesScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  const [finalData, setFinalData] = React.useState({
    totalEspacios: 0,
    totalEspaciosConContrato: 0,
    totalPersonasMuertas: 0,
    data: [],
  });

  let { jardin } = useParams();

  jardin = JSON.parse(jardin);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [asignarPersonaMural, setAsignarPersonaMural] = React.useState(false);
  const [agregarMural, setAgregarMural] = React.useState(false);
  const [asignarContratoMural, setAsignarContratoMural] = React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "7b45cd41-d68b-4a36-947d-cee457842ad1" &&
              permiso.allowed == 1
            ) {
              setAsignarPersonaMural(true);
            }
            if (
              permiso.guidFuncion == "424ab4c6-ed37-45c7-86c9-ed8c001388d0" &&
              permiso.allowed == 1
            ) {
              setAgregarMural(true);
            }
            if (
              permiso.guidFuncion == "41dbba86-4d75-4790-b6d9-486b1e092e37" &&
              permiso.allowed == 1
            ) {
              setAsignarContratoMural(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getAllMuralesByIdJardin(user, jardin.id).then((resultado) => {
      if (resultado.status == 200) {
        let totalEspacios = resultado.data.length;
        let totalEspaciosConContrato = 0;
        let totalPersonasMuertas = 0;

        resultado.data.sort((a, b) => {
          return a.noPosicion - b.noPosicion;
        });

        for (const espacio of resultado.data) {
          if (espacio.idcontrato !== 0 || espacio.idcontrato !== null) {
            totalEspaciosConContrato = totalEspaciosConContrato + 1;
          }

          if (espacio.persona != null || espacio.persona != " ") {
            totalPersonasMuertas = totalPersonasMuertas + 1;
          }
        }

        let armandoFinal = {
          totalEspacios: totalEspacios,
          totalEspaciosConContrato: totalEspaciosConContrato,
          totalPersonasMuertas: totalPersonasMuertas,
          data: resultado.data,
        };

        setFinalData(armandoFinal);
      } else {
        setFinalData([]);
      }
    });
  }, [crudAction]);

  return (
    <>
      {agregarMural == true ? (
        <>
          {" "}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-end my-3">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  Agregar Murales
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row row-cols-md-4">
                    <div className="col text-center">
                      <h6>Espacios Creados</h6>
                      <strong> {finalData.totalEspacios}</strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Con Contrato Asignado</h6>
                        {finalData.totalEspaciosConContrato}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Sin Contrato Asignado</h6>
                        {finalData.totalEspacios -
                          finalData.totalEspaciosConContrato}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Fallecidos totales en espacio</h6>
                        {finalData.totalPersonasMuertas}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-4">
          {finalData.data.map((uniplex, index) => (
            <div key={index} className="col">
              <div className="card mb-3">
                <div className="card-header d-flex justify-content-between d-flex align-items-center">
                  <strong>{uniplex.noPosicion}</strong>
                  <strong>
                    {uniplex.contrato == null ? (
                      <>
                        <ErrorIcon /> Sin Contrato
                      </>
                    ) : (
                      <a
                        href={`/contrato/${uniplex.idcontrato}/false`}
                        target={"_self"}
                        rel="noreferrer"
                      >
                        <i>
                          {uniplex.contrato == null
                            ? "Sin Contrato"
                            : uniplex.contrato}
                        </i>
                      </a>
                    )}
                  </strong>
                  {asignarContratoMural == true ? (
                    <div>
                      <button
                        className="btn btn-sm btn-success"
                        onClick={() => {
                          setCrudAction({
                            action: "asignarContrato",
                            data: uniplex,
                          });
                        }}
                      >
                        <span>
                          <AddIcon /> Contrato
                        </span>
                      </button>
                      <br />
                      <button
                        className="btn btn-sm btn-outline-success mt-1"
                        onClick={() => {
                          setCrudAction({
                            action: "actualizarFecha",
                            data: uniplex,
                          });
                        }}
                      >
                        <span>
                        <CachedIcon /> Fecha
                        </span>
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush ">
                    <li className="list-group-item  w-100">
                    Asignación: {uniplex.fechaAsignacion == null ? 'Sin fecha' : uniplex.fechaAsignacion}
                      </li>
                    <li className="list-group-item  w-100">
                      {uniplex.persona == null ? (
                        <i>Sin Persona</i>
                      ) : (
                        uniplex.persona
                      )}{" "}
                      <br />
                      <hr />
                      <small>
                        Inhumación:{" "}
                        {uniplex.fechaIn == null ? (
                          <i>Sin Fecha Inhumación</i>
                        ) : (
                          uniplex.fechaIn
                        )}{" "}
                      </small>
                      <br />
                      <small>
                        Exhumación:{" "}
                        {uniplex.fechaEx == null ? (
                          <i>Sin Fecha Exhumación</i>
                        ) : (
                          uniplex.fechaEx
                        )}
                      </small>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  {asignarPersonaMural == true ? (
                    <>
                      {" "}
                      {uniplex.idcontrato == null ? (
                        <>
                          {" "}
                          <button
                            className="btn btn-sm btn-outline-success w-100"
                            onClick={() => {
                              setCrudAction({
                                action: "asignarContrato",
                                data: uniplex,
                              });
                            }}
                          >
                            <span>
                              <AddIcon /> Contrato
                            </span>
                          </button>{" "}
                        </>
                      ) : (
                        <>
                          {uniplex.persona == null ? (
                            <button
                              className="btn btn-sm btn-outline-success w-100"
                              onClick={() => {
                                setCrudAction({
                                  action: "asignFallecido",
                                  data: uniplex,
                                });
                              }}
                            >
                              Asignar
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-warning w-100"
                              onClick={() => {
                                setCrudAction({
                                  action: "asignFallecido",
                                  data: uniplex,
                                });
                              }}
                            >
                              Actualizar
                            </button>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {crudAction.action == "add" ? (
        <>
          <AddMuralesScreenComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}

      {crudAction.action == "asignFallecido" ? (
        <>
          <AsignarFallecidosAEspacioMuralesComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}
      {crudAction.action == "asignarContrato" ? (
        <>
          <AsignarContratoAEspacioMuralesComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}

      {crudAction.action == "actualizarFecha" ? (
        <>
          <AsignarFechaDeinicioDePagoaEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            idEspacio={5}
          />
        </>
      ) : null}
    </>
  );
};
