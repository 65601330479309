import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllDuplexByIdJardin = async (user, jardin) => {
  let url = API + "spaces/jardin/duplex/getDuplexByJardin/" + jardin;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const AgregarDuplexByIdJardin = async (user, values) => {
  let url = API + "spaces/jardin/duplex/nuevoDuplex/";


  
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const AsignarPersonaADuplexByIdEspacio = async (user, values) => {
  let url = API + "spaces/jardin/duplex/asignarPersonaDuplex/" + values.id;


  
  

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};


export const AsignarContratoADuplexByIdEspacio = async (user, values) => {

  let url = API + "spaces/jardin/duplex/asignarContratoDuplex/" + values.id;

  

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
