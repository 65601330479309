import React from "react";
import { AddCuerpoAEspacioComponent } from "../AddCuerpoAEspacioComponent/AddCuerpoAEspacioComponent";
import { EditCuerpoAEspacioComponent } from "../EditCuerpoAEspacioComponent/EditCuerpoAEspacioComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

export const LugaresDePanteonComponent = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  
  return (
    <>
      <div className="container-fluid">
        <div className="row row-cols-1">
          {props.finalData.espacios.map((espacio, index) => {
            
            return (
              <React.Fragment key={index}>
                <div className="col g-0 mt-2">
                  <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between">
                      <strong>
                        {espacio.espacio} {espacio.letra}
                        {espacio.consecutivo}
                      </strong>

                      <span>{espacio.ubicacion}</span>
                    </div>
                    <div className="card-body">
                      {espacio.personas.length === 0 ? (
                        <>
                          <i style={{ opacity: 0.5 }}>No hay Finados</i>
                          {espacio.idcontrato !== 0 ? (
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
                              <div
                                className="btn btn-sm btn-outline-success"
                                onClick={() => {
                                  setCrudAction({
                                    action: "agregarCuerpo",
                                    data: espacio,
                                  });
                                }}
                              >
                                + Agregar Finado
                              </div>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
                            <div
                              className="btn btn-sm btn-outline-success"
                              onClick={() => {
                                setCrudAction({
                                  action: "agregarCuerpo",
                                  data: espacio,
                                });
                              }}
                            >
                              + Agregar Cuerpo
                            </div>
                          </div>
                          <table className="table table-sm">
                            <caption>
                              <small>
                                Total de finados: {espacio.personas.length}
                              </small>
                              <small>
                                Total de finados: {espacio.personas.length}
                              </small>
                            </caption>
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Inhumación</th>
                                <th scope="col">Exhumación</th>
                                <th scope="col">Editar</th>
                                <th scope="col">Eliminar</th>
                              </tr>
                            </thead>
                            <tbody>
                              {espacio.personas.map((cuerpo, index) => (
                                <tr key={cuerpo.id + cuerpo.updatedAt}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{cuerpo.persona}</td>
                                  <td>
                                    {CambiarFormatoFechaHelper(
                                      cuerpo.fechaInhumacion
                                    )}
                                  </td>
                                  <td>
                                    {CambiarFormatoFechaHelper(
                                      cuerpo.fechaExhumacion
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      className="btn btn-sm btn-warning"
                                      onClick={() => {
                                        setCrudAction({
                                          action: "editarCuerpo",
                                          data: cuerpo,
                                        });
                                      }}
                                    >
                                      Editar
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="btn btn-sm btn-danger"
                                      onClick={() => {
                                        setCrudAction({
                                          action: "eliminarCuerpo",
                                          data: cuerpo,
                                        });
                                      }}
                                    >
                                      Eliminar
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {crudAction.action == "agregarCuerpo" ? (
        <AddCuerpoAEspacioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}

      {crudAction.action == "editarCuerpo" ? (
        <EditCuerpoAEspacioComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      ) : null}
    </>
  );
};
