import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import {
  createNewOrder,
  editOrdenServicio,
} from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";

const validationSchema = yup.object({
  titular: yup.string("Ingresa Titular").required("Titular requerido"),
  noContrato: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
  fechaContrato: yup
    .string("Ingresa fecha contrato")
    .required("Fecha requerida"),
  domicilio: yup.string("Ingresa Domicilio").required("Domicilio requerido"),
  colonia: yup.string("Ingresa Colonia").required("Colonia requerida"),
  cp: yup
    .string("Ingresa Código Postal")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "5 dígitos para código postal")
    .max(5, "5 dígitos para código postal")
    .required("Código postal requerido"),
  hPoblacion: yup.string("Ingresa población").required("Población requerida"),
  entreCalle1: yup.string("Ingresa Calle").required("Calle requerida"),
  entreCalle2: yup.string("Ingresa Calle").required("Calle requerida"),
  telefono: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
});

export const EditarOrdenServicioDatosFinado = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [finalDataContratos, setFinalDataContratos] = React.useState([]);

  let arr = props.finalData.folio.split("");

  let numeroFolio = props.finalData.folio.slice(1);

  const formik = useFormik({
    initialValues: {
      letraFolio: arr[0],
      noContrato: props.finalData.noContrato,
      idcontrato: props.finalData.idcontrato,
      folio: numeroFolio,
      fecha: props.finalData.fecha,
      nombreFinado: props.finalData.nombreFinado,
      lugarNacimiento: props.finalData.lugarNacimiento,
      fechaNacimiento: props.finalData.fechaNacimiento,
      sexo: props.finalData.sexo,
      nacionalidad: props.finalData.nacionalidad,
      estadoCivil: props.finalData.estadoCivil,
      domicilio: props.finalData.domicilio,
      colonia: props.finalData.colonia,
      derechohabiente: props.finalData.derechohabiente,
      ocupacion: props.finalData.ocupacion,
      nombrePadre: props.finalData.nombrePadre,
      nombreMadre: props.finalData.nombreMadre,
      nombreConyugue: props.finalData.nombreConyugue,
      lugarDefuncion: props.finalData.lugarDefuncion,
      fechaDefuncion: props.finalData.fechaDefuncion,
      horaDefuncion: props.finalData.horaDefuncion,
      atencionMedica: props.finalData.atencionMedica,
      lugarVelacion: props.finalData.lugarVelacion,
      horaMisa: props.finalData.horaMisa,
      templo: props.finalData.templo,
      horaSepelio: props.finalData.horaSepelio,
      ubicacion: props.finalData.ubicacion,
      perpetuidad: props.finalData.perpetuidad,
      nomAexhum: props.finalData.nomAexhum,
      FDPAExhum: props.finalData.FDPAExhum,
      perpetuidadAExhum: props.finalData.perpetuidadAExhum,
      nombreSolicitante: props.finalData.nombreSolicitante,
      domicilioSolicitante: props.finalData.domicilioSolicitante,
      coloniaSolicitante: props.finalData.coloniaSolicitante,
      parentesco: props.finalData.parentesco,
      telefonoSolicitante: props.finalData.telefonoSolicitante,
      emergencia: props.finalData.emergencia,
      fechaLPago: props.finalData.fechaLPago,
      nombrePanteon: props.finalData.nombrePanteon,
      observaciones: props.finalData.observaciones,
      clasificacion: props.finalData.clasificacion,
      horaCremacion: props.finalData.horaCremacion,
      hornoCremacion: props.finalData.hornoCremacion,
      fechaCremacion: props.finalData.fechaCremacion,
      fechaExhumar: props.finalData.fechaExhumar,
      nombreExhumar: props.finalData.nombreExhumar,
      horaExhumar: props.finalData.horaExhumar,
      comentarioExhumar: props.finalData.comentarioExhumar,
    },

    onSubmit: (values) => {
      setIsDisabled(true);

      values.fechaLPago = DateTime.now().toISODate();

      values.folio = values.letraFolio.split("")[0] + values.folio;

      

      const regex = /^[A-Za-z]\d+$/;

      if (!regex.test(values.folio)) {
        toast.error("El folio no tiene el formato correcto");
        toast.error("La Letra debe ser seguida de un número");
        setIsDisabled(false);
        return;
      }

      

      editOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Se ha editado la orden de servicio.");
          window.location.reload();
        }

        setIsDisabled(false);
      });
    },
  });

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
          </div>

          {props.finalData.idcontrato == null ? (
            <div className="col-12 d-none">
              <Alert
                className="mb-3"
                severity="warning"
                onClick={() => {
                  toast.warning("Debes relacionar con un contrato");
                }}
              >
                No hay contrato Relacionado
              </Alert>
            </div>
          ) : (
            <div className="col-12">
              <Alert
                className="mb-3"
                severity="success"
                onClick={() => {
                  window.open(
                    `/contrato/${props.finalData.idcontrato}/false`,
                    "_self"
                  );
                }}
              >
                Identificador de contrato: {props.finalData.idcontrato}{" "}
                <strong>Pulsa para abrir el contrato</strong>
              </Alert>
            </div>
          )}

          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row">
              <div className="col mb-3 fw-bold h5">Datos del finado</div>
            </div>

            <div className="row row-cols-1 mb-2">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={finalDataContratos}
                getOptionLabel={(option) =>
                  `${option.serie} ${option.noContrato} -${option.titular}`
                }
                onInputChange={(event, option) => {
                  searchContracts(user, event.target.value).then(
                    (resultado) => {
                      if (resultado.status == 200) {
                        setFinalDataContratos(resultado.data);
                      } else {
                        setFinalDataContratos([]);
                      }
                    }
                  );
                }}
                onChange={(event, option) => {
                  formik.setFieldValue("noContrato", option.noContrato);
                  formik.setFieldValue("idcontrato", option.id);
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField {...params} label="Buscar Número de Contrato" />
                )}
              />
            </div>

            <div className="row row-cols-3">
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    shrink={true}
                  >
                    Letra Orden
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.letraFolio}
                    onChange={formik.handleChange}
                    disabled={true}
                    inputProps={{
                      name: "letraFolio",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"C"}>C</option>
                    <option value={"S"}>S</option>
                    <option value={"F"}>F</option>
                    <option value={"P"}>P</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="folio"
                  name="folio"
                  label="Folio"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.folio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.folio && Boolean(formik.errors.folio)}
                  helperText={formik.touched.folio && formik.errors.folio}
                  disabled={true}
                />
              </div>

              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fecha"
                  name="fecha"
                  label="Fecha Orden de Servicio"
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fecha}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.fecha && Boolean(formik.errors.fecha)}
                  helperText={formik.touched.fecha && formik.errors.fecha}
                  disabled={isDisabled}
                />
              </div>
            </div>
            <div className="row row-cols-3">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaDefuncion"
                  name="fechaDefuncion"
                  label="Fecha Defunción"
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaDefuncion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaDefuncion &&
                    Boolean(formik.errors.fechaDefuncion)
                  }
                  helperText={
                    formik.touched.fechaDefuncion &&
                    formik.errors.fechaDefuncion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="nombreFinado"
                  name="nombreFinado"
                  label="Nombre Finado"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.nombreFinado}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombreFinado &&
                    Boolean(formik.errors.nombreFinado)
                  }
                  helperText={
                    formik.touched.nombreFinado && formik.errors.nombreFinado
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="lugarNacimiento"
                  name="lugarNacimiento"
                  label="Lugar De Nacimiento"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.lugarNacimiento}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.lugarNacimiento &&
                    Boolean(formik.errors.lugarNacimiento)
                  }
                  helperText={
                    formik.touched.lugarNacimiento &&
                    formik.errors.lugarNacimiento
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaNacimiento"
                  name="fechaNacimiento"
                  label="Fecha de Nacimiento"
                  type={"date"}
                  className="mb-3"
                  value={formik.values.fechaNacimiento}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaNacimiento &&
                    Boolean(formik.errors.fechaNacimiento)
                  }
                  helperText={
                    formik.touched.fechaNacimiento &&
                    formik.errors.fechaNacimiento
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    shrink={true}
                  >
                    Sexo
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.sexo}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    defaultValue={"M"}
                    inputProps={{
                      name: "sexo",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"M"}>Masculino</option>
                    <option value={"F"}>Femenino</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="nacionalidad"
                  name="nacionalidad"
                  label="Nacionalidad"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.nacionalidad}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nacionalidad &&
                    Boolean(formik.errors.nacionalidad)
                  }
                  helperText={
                    formik.touched.nacionalidad && formik.errors.nacionalidad
                  }
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="estadoCivil"
                    shrink={true}
                  >
                    Estado Civil
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.estadoCivil}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    defaultValue={"M"}
                    inputProps={{
                      name: "estadoCivil",
                      id: "estadoCivil",
                    }}
                  >
                    <option value={"Soltero"}>Soltero</option>
                    <option value={"Casado"}>Casado</option>
                    <option value={"Divorciado"}>Divorciado</option>
                    <option value={"Separación En Proceso"}>
                      Separación el Proceso
                    </option>
                    <option value={"Viudo"}>Viudo</option>
                    <option value={"Concubinato"}>Concubinato</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="domicilio"
                  name="domicilio"
                  label="Domicilio"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.domicilio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.domicilio && Boolean(formik.errors.domicilio)
                  }
                  helperText={
                    formik.touched.domicilio && formik.errors.domicilio
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="colonia"
                  name="colonia"
                  label="Colonia"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.colonia}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.colonia && Boolean(formik.errors.colonia)
                  }
                  helperText={formik.touched.colonia && formik.errors.colonia}
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="derechohabiente"
                    shrink={true}
                  >
                    DerechoHabiente
                  </InputLabel>
                  <NativeSelect
                    value={formik.values.derechohabiente}
                    onChange={formik.handleChange}
                    disabled={isDisabled}
                    defaultValue={"M"}
                    inputProps={{
                      name: "derechohabiente",
                      id: "derechohabiente",
                    }}
                  >
                    <option value={"Si"}>Si</option>
                    <option value={"No"}>No</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="ocupacion"
                  name="ocupacion"
                  label="Ocupación"
                  type={"text"}
                  className="mb-3"
                  value={formik.values.ocupacion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.ocupacion && Boolean(formik.errors.ocupacion)
                  }
                  helperText={
                    formik.touched.ocupacion && formik.errors.ocupacion
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>

            {props.permisoParaEditar == true ? (
              <>
                <div className="row row-cols-1">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn w-100 btn-sm btn-success"
                    >
                      Editar orden de Servicio
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};
