import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { changePasswordUser } from "../../../API/ApiUsers";

const validationSchema = yup.object({
  password: yup
    .string("Ingresa contraseña")
    .min(8, "La contraseña deberá de tener mínimo 8 caracteres")
    .required("Contraseña Requerida"),
});

export const EditUserComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      email: props.crudAction.data.email,
      nombre: props.crudAction.data.nombre,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      changePasswordUser(props.crudAction.data.id, user, values).then(
        (resultado) => {
          if (resultado.status == 200) {
            toast.success(
              "Se ha actualizado la contraseña, informa al usuario"
            );
            props.setCrudAction({ action: null, data: null });
          } else {
            setIsDisabled(false);
            toast.info("Ha ocurrido un error");
          }
        }
      );
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-4 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>Actualizar contraseña de {props.nombre}</h6>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <TextField
                size="small"
                fullWidth
                id="nombre"
                name="nombre"
                label="Nombre Completo"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                variant="standard"
                className="mb-3"
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                disabled={true}
              />
              <TextField
                size="small"
                fullWidth
                id="email"
                name="email"
                label="Correo"
                variant="standard"
                className="mb-3"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                disabled={true}
              />

              <TextField
                size="small"
                fullWidth
                id="password"
                name="password"
                type="password"
                label="Contraseña"
                variant="standard"
                className="mb-3"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                disabled={isDisabled}
              />

              <button
                className="my-4 btn btn-warning w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Actualizar Contraseña
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
