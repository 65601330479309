import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { AuthContext } from "../../auth/authContext";

export const PerfilEmpleadoScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  let { numerocontrato } = useParams();




  return (
    <>
     
    </>
  );
};
