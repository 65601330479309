import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { getAllUniplexByIdJardin } from "../../API/ApiUniplex";
import { AuthContext } from "../../auth/authContext";
import { AddUniplexScreenComponent } from "../../Components/AddUniplexScreenComponent/AddUniplexScreenComponent";
import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import { AsignarFallecidosAEspacioUniplexComponent } from "../../Components/AsignarFallecidosAEspacioUniplexComponent/AsignarFallecidosAEspacioUniplexComponent";
import { AsignarContratoAEspacioUniplexComponent } from "../../Components/AsignarContratoAEspacioUniplexComponent/AsignarContratoAEspacioUniplexComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import CachedIcon from "@mui/icons-material/Cached";
import { AsignarFechaDeinicioDePagoaEspacioComponent } from "../../Components/AsignarFechaDeinicioDePagoaEspacioComponent/AsignarFechaDeinicioDePagoaEspacioComponent";
const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const UniplexScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);

  let { jardin } = useParams();

  jardin = JSON.parse(jardin);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [asignarPersonaUniplex, setAsignarPersonaUniplex] =
    React.useState(false);
  const [agregarUniplex, setAgregarUniplex] = React.useState(false);
  const [asignarContratoUniplex, setAsignarContratoUniplex] =
    React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "3b49f623-a5ee-4918-8a55-92b8a9a052e5" &&
              permiso.allowed == 1
            ) {
              setAsignarPersonaUniplex(true);
            }
            if (
              permiso.guidFuncion == "a59d2db8-c5f4-444f-84b3-75ade2378a2b" &&
              permiso.allowed == 1
            ) {
              setAgregarUniplex(true);
            }
            if (
              permiso.guidFuncion == "a7deac17-a9ab-4bb7-a90c-0ecbd3d3a3d4" &&
              permiso.allowed == 1
            ) {
              setAsignarContratoUniplex(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [totalesEspacios, setTotalesEspacios] = React.useState({
    espaciosTotales: 0,
    espaciosConContratoTotales: 0,
    personasEspaciostotales: 0,
  });

  function groupBy(arr, prop) {
    const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
    arr.forEach((obj) => map.get(obj[prop]).push(obj));
    return Array.from(map.values());
  }

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  React.useEffect(() => {
    getAllUniplexByIdJardin(user, jardin.id).then((resultado) => {
      if (resultado.status == 200) {
        
        setFinalData(groupBy(resultado.data, "noPosicion"));

        var uniqueArray = removeDuplicates(resultado.data, "noPosicion");

        let espaciosTotales = uniqueArray.length;
        let espaciosConContratoTotales = 0;
        let personasEspaciostotales = 0;

        for (const espacio of uniqueArray) {
          if (
            espacio.idcontrato == 0 ||
            espacio.idcontrato == null ||
            espacio.idcontrato == " " ||
            espacio.contrato == null
          ) {
          } else {
            espaciosConContratoTotales = espaciosConContratoTotales + 1;
          }
        }

        for (const espaciosCompletos of resultado.data) {
          if (
            espaciosCompletos.persona == 0 ||
            espaciosCompletos.persona == null ||
            espaciosCompletos.persona == " "
          ) {
          } else {
            personasEspaciostotales = personasEspaciostotales + 1;
          }
        }

        setTotalesEspacios({
          espaciosTotales: espaciosTotales,
          espaciosConContratoTotales: espaciosConContratoTotales,
          personasEspaciostotales: personasEspaciostotales,
        });

        
      } else {
        setFinalData([]);
      }
    });
  }, [crudAction]);

  return (
    <>
      {agregarUniplex == true ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-end my-2">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  Agregar Uniplex
                </button>
              </div>
            </div>
          </div>{" "}
        </>
      ) : null}

      <div className="container-fluid mt-3 mb-3">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row row-cols-md-4">
                    <div className="col text-center">
                      <h6>Espacios Creados</h6>
                      <strong> {totalesEspacios.espaciosTotales}</strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Con Contrato asignado</h6>
                        {totalesEspacios.espaciosConContratoTotales}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Espacios Sin Contrato Asignado</h6>
                        {totalesEspacios.espaciosTotales -
                          totalesEspacios.espaciosConContratoTotales}
                      </strong>
                    </div>
                    <div className="col text-center">
                      <strong>
                        <h6>Fallecidos en espacio</h6>
                        {totalesEspacios.personasEspaciostotales}
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row row-cols-md-4">
          {finalData.map((uniplex, index) => (
            <div className="col" key={index}>
              <div className="card">
                <div className="card mb-3">
                  {uniplex.map((uniplex, index) => (
                    <div key={index} className="col">
                      <div className="card-header d-flex justify-content-between">
                        <strong>{uniplex.noPosicion}</strong>
                        <strong>
                          {uniplex.contrato == null ? (
                            <>
                              <ErrorIcon /> Sin Contrato
                            </>
                          ) : (
                            <a
                              href={`/contrato/${uniplex.idcontrato}/false`}
                              target={"_self"}
                              rel="noreferrer"
                            >
                              <i>
                                {uniplex.contrato == null
                                  ? "Sin Contrato"
                                  : uniplex.contrato}
                              </i>
                            </a>
                          )}
                        </strong>
                        {asignarContratoUniplex == true ? (
                          <div>
                            <button
                              className="btn btn-sm btn-success"
                              onClick={() => {
                                setCrudAction({
                                  action: "asignarContrato",
                                  data: uniplex,
                                });
                              }}
                            >
                              <span>
                                <AddIcon /> Contrato
                              </span>
                            </button>
                            <br />
                            <button
                              className="mt-2 btn btn-sm btn-outline-success"
                              onClick={() => {
                                setCrudAction({
                                  action: "actualizarFecha",
                                  data: uniplex,
                                });
                              }}
                            >
                              <span>
                                <CachedIcon /> Fecha
                              </span>
                            </button>
                          </div>
                        ) : null}
                      </div>

                      <ul className="list-group list-group-flush ">
                        <li className="list-group-item d-flex justify-content-between d-flex align-items-center">
                          <span>
                            {uniplex.persona == null ? " - " : uniplex.persona}{" "}
                            <br />
                            <small>Asignación: {uniplex.fechaAsignacion}</small>
                            <br />
                            <small>Inhumación: {uniplex.fechaIn}</small>
                            <br />
                            <small>Exhumación: {uniplex.fechaEx}</small>
                            <br />
                          </span>
                          {uniplex.idcontrato == null ? (
                            <></>
                          ) : (
                            <>
                              {asignarPersonaUniplex == true ? (
                                <>
                                  {uniplex.persona == null ? (
                                    <button
                                      className="btn btn-sm btn-outline-success"
                                      onClick={() => {
                                        setCrudAction({
                                          action: "asignFallecido",
                                          data: uniplex,
                                        });
                                      }}
                                    >
                                      Asignar
                                    </button>
                                  ) : (
                                    <button
                                      className="btn btn-sm btn-outline-warning"
                                      onClick={() => {
                                        setCrudAction({
                                          action: "asignFallecido",
                                          data: uniplex,
                                        });
                                      }}
                                    >
                                      Actualizar
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </>
                          )}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {crudAction.action == "add" ? (
        <>
          <AddUniplexScreenComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}

      {crudAction.action == "asignFallecido" ? (
        <>
          <AsignarFallecidosAEspacioUniplexComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}
      {crudAction.action == "asignarContrato" ? (
        <>
          <AsignarContratoAEspacioUniplexComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            jardin={jardin}
          />
        </>
      ) : null}
      {crudAction.action == "actualizarFecha" ? (
        <>
          <AsignarFechaDeinicioDePagoaEspacioComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            idEspacio={3}
          />
        </>
      ) : null}
    </>
  );
};
