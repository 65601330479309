import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getObtenerDocumentos } from "../../API/ApiDocumentos";
import DataTable from "react-data-table-component";
import { DocumentosCrudComponent } from "../../Components/DocumentosCrudComponent/DocumentosCrudComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
const guidVista = "0cab403e-bda3-481a-8c0a-fe2ee96bd281";

const CatalogoDeDocumentosScreenComponent = () => {
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Obligatorio",
      selector: (row) => (row.obligatorio == true ? "Si" : "No"),
      sortable: true,
    },
    {
      name: "Editar",
      selector: (row) => (
        <>
          {permisoEditar == true ? (
            <>
              <button
                type="button"
                class="btn btn-sm btn-warning"
                onClick={() => {
                  setCrudAction({ action: "update", data: row });
                }}
              >
                Editar
              </button>
            </>
          ) : null}
        </>
      ),
      sortable: true,
    },
    {
      name: "Eliminar",
      selector: (row) => (
        <>
          {permisoEliminar == true ? (
            <>
              <button
                type="button"
                class="btn btn-sm btn-danger"
                onClick={() => {
                  setCrudAction({ action: "delete", data: row });
                }}
              >
                Eliminar
              </button>
            </>
          ) : null}
        </>
      ),
      sortable: true,
    },
  ];

  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoVer, setPermisoVer] = React.useState(false);
  const [permisoAgregar, setPermisoAgregar] = React.useState(false);
  const [permisoEditar, setPermisoEditar] = React.useState(false);
  const [permisoEliminar, setPermisoEliminar] = React.useState(false);

  React.useEffect(() => {
    //Hacemos la llamada (Con la nueva ruta de API)
    getObtenerDocumentos(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, [crudAction]);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          if (
            resultado.data.find(
              (dataBuscar) =>
                dataBuscar.guidFuncion == "002e6117-8dfd-47e6-87c9-1bdf1f4ef6a2"
            ).allowed == 1
          ) {
            setPermisoVer(true);
          }

          if (
            resultado.data.find(
              (dataBuscar) =>
                dataBuscar.guidFuncion == "51021113-eda0-426b-be43-282c347493d3"
            ).allowed == 1
          ) {
            setPermisoAgregar(true);
          }

          if (
            resultado.data.find(
              (dataBuscar) =>
                dataBuscar.guidFuncion == "24c5e355-f0fd-4b2d-a71a-ac8749fd089b"
            ).allowed == 1
          ) {
            setPermisoEditar(true);
          }

          if (
            resultado.data.find(
              (dataBuscar) =>
                dataBuscar.guidFuncion == "e6b329b7-671b-4439-a123-7d37bd4dbeac"
            ).allowed == 1
          ) {
            setPermisoEliminar(true);
          }
        } else {
          setCheckPermissions([]);
        }
      }
    );
  }, []);

  if (permisoVer == false) {
    return <SinPermisoComponent />;
  } else {
    return (
      <>
        <div className="container-fluid">
          <div className=" row">
            <div
              className="col-12 text-right py-3"
              style={{ textAlign: "right" }}
            >
              {permisoAgregar == true ? (
                <button
                  type="button"
                  class="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({ action: "create", data: null });
                  }}
                >
                  Crear Tipo Documento
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <DataTable
          columns={columns}
          data={finalData}
          fixedHeader={true}
          fixedHeaderScrollHeight={"50vh"}
        />

        {crudAction.action === "create" ||
        crudAction.action === "update" ||
        crudAction.action === "delete" ? (
          <>
            <DocumentosCrudComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Documento"
            />
          </>
        ) : null}
      </>
    );
  }
};

export default CatalogoDeDocumentosScreenComponent;
