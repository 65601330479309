import React from "react";
import {
  Document,
  View,
  Page,
  Text,
  PDFDownloadLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import logoVitanova from "../../Images/LogotipoNegro.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "30%",
    height: "auto",
    textAlign: "center",
    alignSelf: "center",
  },
  titulo: {
    fontSize: 12,
    marginTop: 30,
    textAlign: "center",
  },
  tituloNombre: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
  firmaConformidad: {
    fontSize: 10,
    marginTop: 200,
    textAlign: "center",
  },
  textoFirmaConformidad: {
    fontSize: 10,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 7,
    fontWeight: "bold",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    textAlign: "center",
    width: "9%",
  },
  tableCell: {
    margin: 5,
    fontSize: 7,
    textAlign: "center",
    width: "9%",
  },
});

export const ImprimirPdfPrevisionMonedasComponent = (props) => {
  const generatePDF = () => (
    <>
      <Document orientation="landscape">
        <Page size="A4" style={styles.page} orientation="landscape">
          <View style={styles.section}>
            <Image style={styles.image} src={logoVitanova} />
            <Text style={styles.titulo}>
              Listado de Monedas del día: {props.fechaInicio} al{" "}
              {props.fechaFin}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeader}>Vendedor</Text>
              <Text style={styles.tableCellHeader}>Total</Text>
              <Text style={styles.tableCellHeader}>$500.00</Text>
              <Text style={styles.tableCellHeader}>$200.00</Text>
              <Text style={styles.tableCellHeader}>$100.00</Text>
              <Text style={styles.tableCellHeader}>$50.00</Text>
              <Text style={styles.tableCellHeader}>$20.00</Text>
              <Text style={styles.tableCellHeader}>$10.00</Text>
              <Text style={styles.tableCellHeader}>$5.00</Text>
              <Text style={styles.tableCellHeader}>$2.00</Text>
              <Text style={styles.tableCellHeader}>$1.00</Text>
              <Text style={styles.tableCellHeader}>$.50</Text>
            </View>

            {props.data.map((recibo, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{recibo.vendedor}</Text>
                <Text style={styles.tableCell}>
                  {" "}
                  $ {parseFloat(recibo.porPagar).toFixed(2)}
                </Text>
                <Text style={styles.tableCell}>{recibo.quinientos}</Text>
                <Text style={styles.tableCell}>{recibo.doscientos}</Text>
                <Text style={styles.tableCell}>{recibo.cien}</Text>
                <Text style={styles.tableCell}>{recibo.cincuenta}</Text>
                <Text style={styles.tableCell}>{recibo.veinte}</Text>
                <Text style={styles.tableCell}>{recibo.diez}</Text>
                <Text style={styles.tableCell}>{recibo.cinco}</Text>
                <Text style={styles.tableCell}>{recibo.dos}</Text>
                <Text style={styles.tableCell}>{recibo.uno}</Text>
                <Text style={styles.tableCell}>{recibo.centavos}</Text>
              </View>
            ))}

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{"Total"}</Text>
              <Text style={styles.tableCell}>
                ${" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + parseFloat(currentObject.porPagar),
                  0
                )}
              </Text>
              <Text style={styles.tableCell}>
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.quinientos,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.doscientos,
                  0
                )}
              </Text>
              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.cien,
                  0
                )}
              </Text>
              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.cincuenta,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.veinte,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.diez,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.cinco,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.dos,
                  0
                )}
              </Text>

              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.uno,
                  0
                )}
              </Text>
              <Text style={styles.tableCell}>
                {" "}
                {props.data.reduce(
                  (accumulator, currentObject) =>
                    accumulator + currentObject.centavos,
                  0
                )}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );

  return (
    <div>
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`Comisiones_OS_${props.fechaInicio}_${props.fechaFin}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <div className="btn btn-sm btn-danger mt-3">
              Descargar Denominación Monedas
            </div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};
