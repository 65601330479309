import { LinearProgress, TextField } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";
import {
  postCargarDocumentosContrato,
  postCargarDocumentosOrdenDeServicio,
} from "../../API/ApiDocumentosContrato";
import { AuthContext } from "../../auth/authContext";
import {
  checkPermisosAEditarPorUsuario,
  checkPermisosPorUsuarioPorGuidPantalla,
} from "../../API/ApiPermisos";

const vistaOrdenes = "d5376ba2-cefd-4edd-8402-362d5bd4c75d";
const vistaContratos = "6ac593c2-4810-4081-bb29-e80830750a85";


const AgregarDocumentoAContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] =
    React.useState("");
  const [archivoSeleccionado, setArchivoSeleccionado] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  const [permisoSubirDocumentoOrden, setPermisoSubirDocumentoOrden] =
    React.useState(0);

  const [permisoSubirDocumentoContrato, setPermisoSubirDocumentoContrato] =
    React.useState(0);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setArchivoSeleccionado(file);
    }
  };

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, vistaOrdenes).then(
      (resultado) => {
        for (const permiso of resultado.data) {
          if (permiso.guidFuncion == "a9eae2ac-7b73-40fb-8116-1fa393f17d50") {
            
            setPermisoSubirDocumentoOrden(permiso.allowed);
          }
        }
      }
    );

    checkPermisosPorUsuarioPorGuidPantalla(user, vistaContratos).then(
      (resultado) => {
        for (const permiso of resultado.data) {
          if (permiso.guidFuncion == "a003c130-9bfe-485f-b41c-59d984f880fe") {
            setPermisoSubirDocumentoContrato(permiso.allowed);
          }
        }
      }
    );
  }, []);

  if (
    (props.tipo == "Orden de Servicio" && permisoSubirDocumentoOrden == 1) ||
    (props.tipo == "Contrato" && permisoSubirDocumentoContrato == 1)
  ) {
    return (
      <>
        {loading == true ? <LinearProgress /> : null}
        <div className="card">
          <div className="card-body">
            <h5 className="m-0 p-0 mb-4">Agregar Documento - {props.tipo}</h5>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-4">
                  <TextField
                    id="filled-select-currency-native"
                    select
                    fullWidth
                    label="Tipo documento a subir"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{
                      native: true,
                    }}
                    value={tipoDocumentoSeleccionado}
                    onChange={(e) => {
                      setTipoDocumentoSeleccionado(e.target.value);
                    }}
                    helperText="Selecciona el documento"
                    variant="filled"
                  >
                    <option value={""}>Selecciona un documento</option>
                    {props.finalTiposDocumentos.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.nombre}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className="col-sm-6">
                  {" "}
                  <input
                    type="file"
                    style={{ width: "100%" }}
                    className="ms-4"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="col-sm-2">
                  <div
                    className="btn btn-success w-100"
                    onClick={() => {
                      if (!archivoSeleccionado) {
                        toast.error(
                          "Por favor, selecciona un archivo antes de subir."
                        );
                        return;
                      }

                      if (tipoDocumentoSeleccionado == "") {
                        toast.error(
                          "Selecciona un tipo de documento para poder subir un documento"
                        );
                        return;
                      }
                      setLoading(true);

                      const formData = new FormData();
                      formData.append(
                        "idcDocumento",
                        tipoDocumentoSeleccionado
                      );

                      formData.append("archivo", archivoSeleccionado);

                      if (props.tipo == "Orden de Servicio") {
                        formData.append("idorden", props.contrato);

                        postCargarDocumentosOrdenDeServicio(
                          user,
                          formData
                        ).then((resultado) => {
                          if (resultado.status == 200) {
                            setLoading(false);
                            toast.success("Documento cargado correctamente");
                            props.setContador(props.contador + 1);
                            setArchivoSeleccionado(null);
                            setTipoDocumentoSeleccionado("");
                          } else {
                            setLoading(false);
                            toast.error(
                              "Ocurrió un error al subir el documento."
                            );
                          }
                        });
                      } else if (props.tipo == "Contrato") {
                        formData.append("idcontrato", props.contrato);

                        postCargarDocumentosContrato(user, formData).then(
                          (resultado) => {
                            if (resultado.status == 200) {
                              setLoading(false);
                              toast.success("Documento cargado correctamente");
                              props.setContador(props.contador + 1);
                              setArchivoSeleccionado(null);
                              setTipoDocumentoSeleccionado("");
                            } else {
                              setLoading(false);
                              toast.error(
                                "Ocurrió un error al subir el documento."
                              );
                            }
                          }
                        );
                      }

                      
                    }}
                  >
                    Subir Documento
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default AgregarDocumentoAContratoComponent;
