import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";

const validationSchema = yup.object({
  idservicio: yup.string("Ingresa Servicio").required("Servicio requerido"),
  folio: yup.string("Ingresa Folio").required("Folio requerido"),
  precio: yup.string("Ingresa precio").required("Precio requerido"),
});

export const AddServicioAOrdenDeServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    getServiciosOrdenesServicio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataServicios(resultado.data);
      }
    });
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      idservicio: "",
      folio: props.folio,
      precio: "",
      comentarios: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      agregarServicioAOrdenDeServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Servicio agregado a la orden exitosamente");
          props.setCrudAction({ action: null, data: null });
        } else {
          toast.error("Ha habido un error al agregar la orden de servicio");
          setIsDisabled(false);
        }
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar servicio a orden</h6>
                  <small className="text-muted">
                    Ingresa los datos para agregar el servicio
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel variant="standard" htmlFor="idservicio" shrink>
                      Servicio
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.idservicio}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      disabled={isDisabled}
                      className="mb-2"
                      inputProps={{
                        name: "idservicio",
                        id: "idservicio",
                      }}
                    >
                      <option value={""}>Ninguno</option>
                      {finalDataServicios.map((servicio, index) => (
                        <option key={index} value={servicio.id}>
                          {servicio.nombreServicio}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="folio"
                    name="folio"
                    label="Folio"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.folio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.folio && Boolean(formik.errors.folio)}
                    helperText={formik.touched.folio && formik.errors.folio}
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="precio"
                    name="precio"
                    label="Precio"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.precio}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="standard"
                    error={
                      formik.touched.precio && Boolean(formik.errors.precio)
                    }
                    helperText={formik.touched.precio && formik.errors.precio}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="comentarios"
                    name="comentarios"
                    label="Comentarios"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.comentarios}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.comentarios &&
                      Boolean(formik.errors.comentarios)
                    }
                    helperText={
                      formik.touched.comentarios && formik.errors.comentarios
                    }
                  />
                </div>

                <div className="col">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success"
                    disabled={isDisabled}
                  >
                    Agregar Servicio a Orden
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
