import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllMausoleoByIdJardin = async (user, jardin) => {
  let url = API + "spaces/jardin/mausoleo/getMausoleoByJardin/" + jardin;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const AgregarLugaresAMausoleoByIdJardin = async (user, values) => {
  let url = API + "spaces/jardin/mausoleo/nuevoMausoleo/";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const AgregarPersonaAEspacioAMausoleo = async (user, values) => {
  let url = API + "spaces/jardin/mausoleo/asignarUrnaMausoleo/";

  
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
export const EditarLugarMausoleo = async (user, values) => {
  let url = API + "spaces/jardin/mausoleo/setFechaSalidaUrna/" + values.id;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const AsignarContratoAMausoleoByIdEspacio = async (user, values) => {
  let url = API + "spaces/jardin/mausoleo/asignarContratoMausoleo/" + values.id;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      

      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
