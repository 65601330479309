import React from "react";
import styles from "./ImprimirPantallaComponent.module.css";
import PrintIcon from "@mui/icons-material/Print";
export const ImprimirPantallaComponent = () => {
  return (
    <div
      className={styles.imprimirComponent}
      onClick={() => {
        window.print();
      }}
    >
      <PrintIcon />
    </div>
  );
};
