import React from "react";
import Skeleton from "@mui/material/Skeleton";
export const LoadingSkeletonComponent = () => {
  return (
    <div className="container-fluid">
      <div className="row row-cols-md-4">
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
        <div className="col mb-3">
          <Skeleton variant="rectangular" width={"100%"} height={"20vh"}/>
        </div>
      </div>
    </div>
  );
};
