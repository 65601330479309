//1.- Agregar campo de mes y campo de año.
//2.- La API en la que mande esos 2 datos y se regrese el total de la operación.

import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import { reporteResumenSerie } from "../../API/ApiReportes";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { paginationItemClasses } from "@mui/material";
import Box from "@mui/material/Box";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";

const columns = [
  { field: "serie", headerName: "Serie", width: 90 },
  { field: "noContrato", headerName: "Contrato", width: 90 },
  {
    field: "fechaContrato",
    headerName: "Fecha",
    width: 90,
    valueGetter: (params) =>
      CambiarFormatoFechaHelper(params.row.fechaContrato),
  },
  {
    field: "importe",
    headerName: "Importe",
    width: 90,
    valueGetter: (params) =>
      `$ ${parseFloat(params.row.importe)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
  },
  {
    field: "saldo",
    headerName: "Saldo",
    width: 90,
    valueGetter: (params) =>
      `$ ${parseFloat(params.row.saldo)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
  },
  { field: "plazo", headerName: "Plazo", width: 90 },
  { field: "tipoServicio", headerName: "Tipo Servicio", width: 90 },
  {
    field: "modoDePago",
    headerName: "Modo Pago",
    width: 90,
    valueGetter: (params) => params.row.modoDePago,
  },
  { field: "serieDeCobranza", headerName: "Gestor", width: 90 },
  { field: "status", headerName: "Status", width: 90 },

  {
    field: "fechaPago",
    headerName: "Último Abono",
    width: 90,
    valueGetter: (params) => CambiarFormatoFechaHelper(params.row.fechaPago),
  },
  {
    field: "mensualidad",
    headerName: "Mensualidad",
    width: 90,
  },
];

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

export const ReporteResumenSerieScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState(null);
  const [reporteMes, setReporteMes] = React.useState(null);
  const [reporteAnio, setReporteAnio] = React.useState(null);
  const [reporteFechaInicial, setReporteFechaInicial] = React.useState("");
  const [reporteFechaFin, setReporteFechaFin] = React.useState("");
  const [statusContratos, setStatusContratos] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);

  const [totalCartera, setTotalCartera] = React.useState(0);
  const [totalSaldoEnTabla, setTotalSaldoEnTabla] = React.useState(0);

  const [totalPromedioMensualidad, setTotalPromedioMensualidad] =
    React.useState(0.0);

  //Totales por tipo
  const [totalPanteonesFinal, setTotalPanteonesFinal] = React.useState(0.0);
  const [totalServiciosFinal, setTotalServiciosFinal] = React.useState(0.0);
  const [totalMausoleosFinal, setTotalMausoleosFinal] = React.useState(0.0);
  const [totalNoIdentificadoFinal, setTotalNoIdentificadoFinal] =
    React.useState(0.0);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8889a307-deb0-421b-97ab-97f548c74e3d" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "6f254caf-0885-432e-9e99-95d0c93dc382" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    var listo = false;
    var total = 0;
    var saldo = 0;
    var promedioMensual = 0;

    //Totalizando
    var totalPanteones = 0;
    var totalServicio = 0;
    var totalMausoleo = 0;
    var totalNoIdentificado = 0;

    var promedioMensualPrueba = 0.0;

    for (const transaccion of finalData) {
      total = total + parseFloat(transaccion.importe);
      saldo = saldo + parseFloat(transaccion.saldo);

      promedioMensual = promedioMensual + parseFloat(transaccion.mensualidad);

      if (transaccion.mensualidad == null) {
        promedioMensual = promedioMensual + 0;
      } else {
        promedioMensual = promedioMensual + parseFloat(transaccion.mensualidad);
      }

      if (transaccion.tipoServicio == "PANTEON") {
        totalPanteones = totalPanteones + parseFloat(transaccion.saldo);
      } else if (transaccion.tipoServicio == "MAUSOLEO") {
        totalMausoleo = totalMausoleo + parseFloat(transaccion.saldo);
      } else if (transaccion.tipoServicio == "SERVICIO") {
        totalServicio = totalServicio + parseFloat(transaccion.saldo);
      } else {
        totalNoIdentificado = totalNoIdentificado + transaccion.saldo;
      }
    }

    setTotalPanteonesFinal(totalPanteones);
    setTotalServiciosFinal(totalServicio);
    setTotalMausoleosFinal(totalMausoleo);
    setTotalNoIdentificadoFinal(totalNoIdentificado);

    setTotalPromedioMensualidad(promedioMensual);
    setTotalCartera(total);
    setTotalSaldoEnTabla(saldo);

    return () => {
      listo = true;
    };
  }, [finalData]);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-4 d-flex align-items-center mb-3">
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="Serie" shrink>
                    Serie
                  </InputLabel>
                  <NativeSelect
                    value={reporteSerie}
                    defaultValue={""}
                    inputProps={{
                      name: "Serie",
                      id: "Serie",
                    }}
                    onChange={(e) => {
                      setReporteSerie(e.target.value);
                    }}
                  >
                    <option value={"null"}>Mostrar todas las series</option>
                    <option value={"A"}>A</option>
                    <option value={"AA"}>AA</option>
                    <option value={"B"}>B</option>chc
                    <option value={"BB"}>BB</option>
                    <option value={"D"}>D</option>
                    <option value={"E"}>E</option>
                    <option value={"F"}>F</option>
                    <option value={"G"}>G</option>
                    <option value={"H"}>H</option>
                    <option value={"I"}>I</option>
                    <option value={"J"}>J</option>
                    <option value={"P"}>P</option>
                    <option value={"MA"}>MA</option>
                    <option value={"S"}>S</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="Serie" shrink>
                    Meses
                  </InputLabel>
                  <NativeSelect
                    value={reporteMes}
                    defaultValue={""}
                    inputProps={{
                      name: "reporteMes",
                      id: "reporteMes",
                    }}
                    onChange={(e) => {
                      setReporteMes(e.target.value);
                    }}
                  >
                    <option value={"null"}>Todos los meses</option>
                    <option value={"1"}>ENERO</option>
                    <option value={"2"}>FEBRERO</option>
                    <option value={"3"}>MARZO</option>
                    <option value={"4"}>ABRIL</option>chc
                    <option value={"5"}>MAYO</option>
                    <option value={"6"}>JUNIO</option>
                    <option value={"7"}>JULIO</option>
                    <option value={"8"}>AGOSTO</option>
                    <option value={"9"}>SEPTIEMBRE</option>
                    <option value={"10"}>OCTUBRE</option>
                    <option value={"11"}>NOVIEMBRE</option>
                    <option value={"12"}>DICIEMBRE</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="Serie" shrink>
                    Año
                  </InputLabel>
                  <NativeSelect
                    value={reporteAnio}
                    defaultValue={""}
                    inputProps={{
                      name: "reporteAnio",
                      id: "reporteAnio",
                    }}
                    onChange={(e) => {
                      setReporteAnio(e.target.value);
                    }}
                  >
                    <option value={"null"}>Todos los años</option>
                    <option value={"2024"}>2024</option>
                    <option value={"2023"}>2023</option>
                    <option value={"2022"}>2022</option>
                    <option value={"2021"}>2021</option>
                    <option value={"2020"}>2020</option>
                    <option value={"2019"}>2019</option>
                    <option value={"2018"}>2018</option>
                    <option value={"2017"}>2017</option>
                    <option value={"2016"}>2016</option>
                    <option value={"2015"}>2015</option>
                    <option value={"2014"}>2014</option>
                    <option value={"2013"}>2013</option>
                    <option value={"2012"}>2012</option>
                    <option value={"2011"}>2011</option>
                    <option value={"2010"}>2010</option>
                    <option value={"2009"}>2009</option>
                    <option value={"2008"}>2008</option>
                    <option value={"2007"}>2007</option>
                    <option value={"2006"}>2006</option>
                    <option value={"2005"}>2005</option>
                    <option value={"2004"}>2004</option>
                    <option value={"2003"}>2003</option>
                    <option value={"2002"}>2002</option>
                    <option value={"2001"}>2001</option>
                    <option value={"2000"}>2000</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="col mt-2">
                <button
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    if (reporteAnio == null || reporteMes == null) {
                      alert("Debes Seleccionar Año y Mes");
                    } else {
                      setLoading(true);
                      reporteResumenSerie(
                        user,
                        reporteSerie,
                        reporteMes,
                        reporteAnio
                      ).then((resultado) => {
                        if (resultado.status == 200) {
                          setFinalData(resultado.data);
                        }
                        //Aqui va
                        setLoading(false);
                      });
                    }
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid mb-4">
            <div className="row row-cols-1">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-4">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <strong>Serie</strong>
                                </th>
                                <td>{reporteSerie}</td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Cartera</strong>
                                </th>
                                <td>
                                  {"$ "}
                                  {parseFloat(totalCartera)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Saldo</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalSaldoEnTabla)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <strong>Promedio Mensual</strong>
                                </th>
                                <td>
                                  {"$ "}
                                  {parseFloat(totalPromedioMensualidad)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Promedio Quincenal</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalPromedioMensualidad / 2)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Servicio</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalServiciosFinal)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Panteón</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalPanteonesFinal)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>Mausoleo</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalMausoleosFinal)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">
                                  <strong>No Identificados</strong>
                                </th>
                                <td>
                                  {"$ "}{" "}
                                  {parseFloat(totalNoIdentificadoFinal)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-4">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th scope="row">
                                  <strong>Total de Cuentas</strong>
                                </th>
                                <td>{finalData.length + 1}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1 right-text">
              <div className="col" style={{ textAlign: "right" }}>
                <ButtonDownloadToXlsComponent
                  data={finalData}
                  fileName="Reporte de cartera"
                />
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Serie</TableCell>
                      <TableCell align="center">Contrato</TableCell>
                      <TableCell align="center">Fecha de Contrato</TableCell>
                      <TableCell align="center">Importe</TableCell>
                      <TableCell align="center">Saldo</TableCell>
                      <TableCell align="center">Plazo</TableCell>
                      <TableCell align="center">Tipo Servicio</TableCell>
                      <TableCell align="center">Modo de Pago</TableCell>
                      <TableCell align="center">Serie Gestor</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Último Abono</TableCell>
                      <TableCell align="center">Mensualidad</TableCell>
                      <TableCell align="center">Monto Recuperado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {finalData.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{row.serie}</TableCell>
                        <TableCell align="center">
                          <a href={`/contrato/${row.id}/false`}>
                            {row.noContrato}
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          {CambiarFormatoFechaHelper(row.fechaContrato)}
                        </TableCell>
                        <TableCell align="center">
                          {"$ "}{" "}
                          {parseFloat(row.importe)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>
                        <TableCell align="center">
                          {"$ "}{" "}
                          {parseFloat(row.saldo)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>

                        <TableCell align="center">
                          {parseFloat(row.plazo)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>

                        <TableCell align="center">
                          {" "}
                          {row.tipoServicio}
                        </TableCell>
                        <TableCell align="center">
                          {row.modoDePago == 1 ? <>Semanal</> : null}
                          {row.modoDePago == 2 ? <>Catorcenal</> : null}
                          {row.modoDePago == 3 ? <>Quincenal</> : null}
                          {row.modoDePago == 4 ? <>Mensual</> : null}

                          {row.modoDePago == null ? <>No Especificado</> : null}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          {row.serieDeCobranza}
                        </TableCell>

                        <TableCell align="center">
                          {row.status == 1 ? <>Activo</> : null}
                          {row.status == 2 ? <>Cancelado</> : null}
                          {row.status == 3 ? <>Inactivo</> : null}
                          {row.status == 4 ? <>Otorgado</> : null}
                          {row.status == 5 ? <>Pagado</> : null}
                          {row.status == 6 ? <>Restructurado</> : null}
                        </TableCell>

                        <TableCell align="center">
                          {" "}
                          {row.fechaPago == null ? (
                            <>
                              <strong style={{ color: "red" }}>
                                {" "}
                                Sin Pagos
                              </strong>
                            </>
                          ) : (
                            CambiarFormatoFechaHelper(row.fechaPago)
                          )}
                        </TableCell>

                        <TableCell align="center">
                          {"$ "}{" "}
                          {parseFloat(row.mensualidad)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ background: row.pintar }}
                        >
                          {"$ "}{" "}
                          {parseFloat(row.recuperado)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>

          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={finalData}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[10, 50, 100]}
              disableSelectionOnClick
              autoHeight={true}
            />
          </Box>

          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
