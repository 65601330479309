import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchContracts } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";

export const BuscadorDeContratosComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [numeroContrato, setNumeroContrato] = React.useState(0);

  const [selectedValue, SetSelectedValue] = React.useState("");

  const [numeroContratoBoton, setNumeroContratoBoton] = React.useState(0);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={finalData}
        getOptionLabel={(option) =>
          `${option.serie} ${option.noContrato} -${option.titular}`
        }
        onInputChange={(event, option) => {
          searchContracts(user, event.target.value).then((resultado) => {
            if (resultado.status == 200) {
              setFinalData(resultado.data);
            } else {
              setFinalData([]);
            }
          });
        }}
        onChange={(event, option) => {
          window.open("/contrato/" + option.id + "/false", "_self");
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Número de Contrato" />
        )}
      />

      <br />
      <button
        className="btn btn-sm btn-success"
        onClick={() => {
          window.open("/contrato/" + numeroContratoBoton + "/false", "_self");
        }}
      >
        Buscar
      </button>
      <br />
    </>
  );
};
