import React from "react";
import { AddPaymentMantenimientoSegundaVersion } from "../../Components/AddPaymentMantenimientoSegundaVersion/AddPaymentMantenimientoSegundaVersion";
import { AddPaymentMantenimientoTerceraVersion } from "../../Components/AddPaymentMantenimientoTerceraVersion/AddPaymentMantenimientoTerceraVersion";
import { AddPaymentsMantenimientoComponent } from "../../Components/AddPaymentsMantenimientoComponent/AddPaymentsMantenimientoComponent";
import { PreciosDeMantenimientoComponent } from "../../Components/PreciosDeMantenimientoComponent/PreciosDeMantenimientoComponent";

export const MantenimientoScreenComponent = (props) => {
  const [catalogoPreciosFinalData, setCatalogoPreciosFinalData] =
    React.useState([]);

  return (
    <React.Fragment>
      {props.precios == true ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9 d-none"></div>
            <div className="col-sm-12">
              <PreciosDeMantenimientoComponent
                catalogoPreciosFinalData={catalogoPreciosFinalData}
                setCatalogoPreciosFinalData={setCatalogoPreciosFinalData}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h5>Agregar pago de mantenimiento</h5>
                  </div>
                </div>
              </div>
              <AddPaymentMantenimientoTerceraVersion
                catalogoPreciosFinalData={catalogoPreciosFinalData}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
