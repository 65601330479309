import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { newContract, newContractRestructura } from "../../API/ApiContratos";
import { BuscadorDeContratosParaReporteComponent } from "../BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";

const validationSchema = yup.object({
  titular: yup.string("Ingresa Titular").required("Titular requerido"),
  noContrato: yup
    .string("Ingresa número de contrato")
    .required("Número de contrato requerido"),
  fechaContrato: yup
    .string("Ingresa fecha contrato")
    .required("Fecha requerida"),
  domicilio: yup.string("Ingresa Domicilio").required("Domicilio requerido"),
  colonia: yup.string("Ingresa Colonia").required("Colonia requerida"),
  cp: yup
    .string("Ingresa Código Postal")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "5 dígitos para código postal")
    .max(5, "5 dígitos para código postal")
    .required("Código postal requerido"),
  hPoblacion: yup.string("Ingresa población").required("Población requerida"),
  entreCalle1: yup.string("Ingresa Calle").required("Calle requerida"),
  entreCalle2: yup.string("Ingresa Calle").required("Calle requerida"),
  telefono: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
});

export const ContratosRestructuraEtapaUnoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState(0);

  const [datosdeContrato, setDatosDeContrato] = React.useState({
    etapaUno: [],
    etapaDos: [],
  });

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      titular: "",
      noContrato: "",
      idContrato: "",
      fechaContrato: "",
      serie: "C",
      observaciones: "",
      idestado: "",
      idciudad: "",
      domicilio: "",
      entreCalle1: "",
      entreCalle2: "",
      colonia: "",
      cp: "",
      telefono: "",
      hPoblacion: "",
      coloniaTrabajo: "",
      telefonoTrabajo: "",
      domicilioTrabajo: "",
      formaPago: "",
      serieDeCobranza: "A",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      setIsDisabled(true);

      if (
        typeof values.idContratoRestructura === "undefined" ||
        values.idciudad == "" ||
        values.idestado == ""
      ) {
        //myVariable is undefined
 
        toast.error(
          "No hay un contrato seleccionado, por favor buscarlo y presionar enter"
        );

        setIsDisabled(false);
      } else {
        newContractRestructura(user, values).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Hemos agregado el contrato.");
            window.open(
              "/contrato/" + resultado.data.id + "/true",
              "_self"
            );

            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error(resultado.message);
            setIsDisabled(false);
          }
        });
      }
    },
  });

  React.useEffect(() => {
    var datosdecontratoss = datosdeContrato;

    for (const dato of datosdeContrato.etapaUno) {


      getMunicipios(user, dato.idestado).then(
        (resultado) => {
          if ((resultado.status = 200)) {
            setMunicipios(resultado.data);
          } else {
            setMunicipios([]);
          }
        }
      );


      formik.setFieldValue("titular", dato.titular);
      formik.setFieldValue("idContratoRestructura", dato.id);
      formik.setFieldValue("fechaContrato", dato.fechaContrato);
      formik.setFieldValue("serie", dato.serie);
      formik.setFieldValue("observaciones", dato.observaciones);
      formik.setFieldValue("idestado", dato.idestado);
      formik.setFieldValue("idciudad", dato.idciudad);
      formik.setFieldValue("domicilio", dato.domicilio);
      formik.setFieldValue("entreCalle1", dato.entreCalle1);
      formik.setFieldValue("entreCalle2", dato.entreCalle2);
      formik.setFieldValue("colonia", dato.colonia);
      formik.setFieldValue("cp", dato.cp);
      formik.setFieldValue("telefono", dato.telefono);
      formik.setFieldValue("hPoblacion", dato.hPoblacion);
      formik.setFieldValue("coloniaTrabajo", dato.coloniaTrabajo);
      formik.setFieldValue("formaPago", dato.formaPago);
      formik.setFieldValue("serieDeCobranza", dato.serieDeCobranza);
      formik.setFieldValue("idContratoPasado", dato.id);
    }
  }, [datosdeContrato]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-9 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>Agregar {props.nombre}</h6>
                <small className="text-muted">
                  Ingresa los datos de encabezado del expediente, <br />
                  una vez terminado se procederá con las 2 etapas siguientes.
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">Datos principales de contrato.</div>
                </div>
                <div className="row row-cols-md-1 mb-3">
                  <div className="col">
                    <BuscadorDeContratosParaReporteComponent
                      numeroContratoEncontrado={numeroContratoEncontrado}
                      setNumeroContratoEncontrado={setNumeroContratoEncontrado}
                      datosdeContrato={datosdeContrato}
                      setDatosDeContrato={setDatosDeContrato}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-1">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="titular"
                      name="titular"
                      label="Nombre Titular"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.titular}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.titular && Boolean(formik.errors.titular)
                      }
                      helperText={
                        formik.touched.titular && formik.errors.titular
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-4">
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Serie
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.serie}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"C"}
                        inputProps={{
                          name: "serie",
                          id: "serie",
                        }}
                      >
                        <option value={"C"}>C</option>
                        <option value={"SM"}>SM</option>
                        <option value={"BB"}>BB</option>
                        <option value={"S"}>S</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="noContrato"
                      name="noContrato"
                      label="Número de contrato nuevo"
                      type={"number"}
                      min="1"
                      className="mb-3"
                      value={formik.values.noContrato}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.noContrato &&
                        Boolean(formik.errors.noContrato)
                      }
                      helperText={
                        formik.touched.noContrato && formik.errors.noContrato
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="fechaContrato"
                      name="fechaContrato"
                      label="fechaContrato"
                      type="date"
                      className="mb-3"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.fecha}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.fechaContrato &&
                        Boolean(formik.errors.fechaContrato)
                      }
                      helperText={
                        formik.touched.fechaContrato &&
                        formik.errors.fechaContrato
                      }
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink
                      >
                        Serie de Cobranza
                      </InputLabel>
                      <NativeSelect
                        shrink
                        value={formik.values.serieDeCobranza}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"AA"}
                        inputProps={{
                          name: "serieDeCobranza",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"A"}>A</option>
                        <option value={"AA"}>AA</option>
                        <option value={"B"}>B</option>
                        <option value={"BB"}>BB</option>
                        <option value={"D"}>D</option>
                        <option value={"E"}>E</option>
                        <option value={"F"}>F</option>
                        <option value={"G"}>G</option>
                        <option value={"H"}>H</option>
                        <option value={"I"}>I</option>
                        <option value={"J"}>J</option>
                        <option value={"MA"}>MA</option>
                        <option value={"SM"}>SM</option>
                        <option value={"S"}>S</option>
                        <option value={null}>Sin Información</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="container-fluid g-0">
                <div className="row row-cols-md-6">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="domicilio"
                      name="domicilio"
                      label="Domicilio"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.domicilio}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.domicilio &&
                        Boolean(formik.errors.domicilio)
                      }
                      helperText={
                        formik.touched.domicilio && formik.errors.domicilio
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink
                      >
                        Estado
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idestado}
                        disabled={isDisabled}
                        inputProps={{
                          name: "idestado",
                          id: "idestado",
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                          if(e.target.value != "")
                          {
                            getMunicipios(user, e.target.value).then(
                              (resultado) => {
                                if ((resultado.status = 200)) {
                                  setMunicipios(resultado.data);
                                } else {
                                  setMunicipios([]);
                                }
                              }
                            );
                          }
                          else{
                            setMunicipios([]);
                          }
                         
                        }}
                      >
                        <option aria-label="Seleccionar Estado" value="">
                          Seleccionar Estado
                        </option>

                        {estados.map((estado, index) => (
                          <option key={index} value={estado.idestado}>
                            {estado.nombreEstado}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink
                      >
                        Municipio
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idciudad}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                       
                        inputProps={{
                          name: "idciudad",
                          id: "idciudad",
                        }}
                      >
                        <option aria-label="Seleccionar Ciudad" value="">
                          Seleccionar Ciudad
                        </option>

                        {municipios.map((ciudad, index) => (
                          <option key={index} value={ciudad.idciudad}>
                            {ciudad.nombreCiudad}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="colonia"
                      name="colonia"
                      label="Colonia"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.colonia}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.colonia && Boolean(formik.errors.colonia)
                      }
                      helperText={
                        formik.touched.colonia && formik.errors.colonia
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="cp"
                      name="cp"
                      label="Código Postal"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.cp}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.cp && Boolean(formik.errors.cp)}
                      helperText={formik.touched.cp && formik.errors.cp}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="hPoblacion"
                      name="hPoblacion"
                      label="Población"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.hPoblacion}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.hPoblacion &&
                        Boolean(formik.errors.hPoblacion)
                      }
                      helperText={
                        formik.touched.hPoblacion && formik.errors.hPoblacion
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="row row-cols-md-3">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="entreCalle1"
                      name="entreCalle1"
                      label="Entre Calle"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.entreCalle1}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.entreCalle1 &&
                        Boolean(formik.errors.entreCalle1)
                      }
                      helperText={
                        formik.touched.entreCalle1 && formik.errors.entreCalle1
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="entreCalle2"
                      name="entreCalle2"
                      label="Y Calle"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.entreCalle2}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.entreCalle2 &&
                        Boolean(formik.errors.entreCalle2)
                      }
                      helperText={
                        formik.touched.entreCalle2 && formik.errors.entreCalle2
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="telefono"
                      name="telefono"
                      label="Teléfono"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.telefono &&
                        Boolean(formik.errors.telefono)
                      }
                      helperText={
                        formik.touched.telefono && formik.errors.telefono
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>

                <div className="row row-cols-md-3">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="domicilioTrabajo"
                      name="domicilioTrabajo"
                      label="Domicilio Trabajo"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.domicilioTrabajo}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.domicilioTrabajo &&
                        Boolean(formik.errors.domicilioTrabajo)
                      }
                      helperText={
                        formik.touched.domicilioTrabajo &&
                        formik.errors.domicilioTrabajo
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="coloniaTrabajo"
                      name="coloniaTrabajo"
                      label="Colonia Trabajo"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.coloniaTrabajo}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.coloniaTrabajo &&
                        Boolean(formik.errors.coloniaTrabajo)
                      }
                      helperText={
                        formik.touched.coloniaTrabajo &&
                        formik.errors.coloniaTrabajo
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="telefonoTrabajo"
                      name="telefonoTrabajo"
                      label="Teléfono Trabajo"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.telefonoTrabajo}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.telefonoTrabajo &&
                        Boolean(formik.errors.telefonoTrabajo)
                      }
                      helperText={
                        formik.touched.telefonoTrabajo &&
                        formik.errors.telefonoTrabajo
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="row row-cols-md-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="observaciones"
                    name="observaciones"
                    label="Observaciones"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.observaciones}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.observaciones &&
                      Boolean(formik.errors.observaciones)
                    }
                    helperText={
                      formik.touched.observaciones &&
                      formik.errors.observaciones
                    }
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <button
                className="my-4 btn btn-primary w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Siguiente
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
