import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteCobranzaSerie,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import MultiselectSeriesComponent from "../../Components/MultiselectSeriesComponent/MultiselectSeriesComponent";
import { toast } from "react-toastify";
import ReactToPrint from "react-to-print";
import imageVitanova from "../../Images/LogotipoNegro.png";

import imagenVitaNova from "../../Images/LogotipoNegro.png";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ListToPrintComponent = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [totalSalarios, setTotalSalarios] = React.useState(0.0);

  const [granTotalComisionMostrarDos, setGranTotalComisionMostrarDos] =
    React.useState(0.0);

  const [granTotalTotalMostrarDos, setGranTotalTotalMostrarDos] =
    React.useState(0.0);

  React.useEffect(() => {
    let granTotalComisionDos = 0.0;
    let granTotaltotalDos = 0.0;

    for (const serie of props.value) {
      granTotalComisionDos = granTotalComisionDos + serie.TotalComision;
      granTotaltotalDos = granTotaltotalDos + parseFloat(serie.TOTAL);
    }
    setGranTotalTotalMostrarDos(granTotaltotalDos);
    setGranTotalComisionMostrarDos(granTotalComisionDos);
  }, [props]);

  return (
    <div className="print-source " ref={ref}>
      <img
        alt="Vitanova Logo"
        className="mt-3"
        src={imageVitanova}
        width="150"
      />
      <br />

      <h3 className="mt-3">Reporte de cobranza por fechas (Serie Gestor)</h3>
      <h6 className="mb-4">
        <small>Reporte general</small>
      </h6>
      <h6 className="mb-4">
        <small>
          de: {CambiarFormatoFechaHelper(props.fechaInicio)} a:{" "}
          {CambiarFormatoFechaHelper(props.fechaFin)}
        </small>
      </h6>

      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <strong>Serie</strong>
          </div>
          <div className="col-3">
            <strong>Total</strong>
          </div>
          <div className="col-3">
            <strong>Porcentaje</strong>
          </div>
          <div className="col-3">
            <strong>Total Comisión</strong>
          </div>
        </div>
      </div>
      {props.value.map((empleado, index) => (
        <div className="container-fluid" key={index}>
          <div className="row">
            <div className="col-3">{empleado.SERIE}</div>
            <div className="col-3">
              {"$ "}{" "}
              {parseFloat(empleado.TOTAL)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div className="col-3">
              {"% "}{" "}
              {parseFloat(empleado.porcentaje)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
            <div className="col-3">
              {"$ "}{" "}
              {parseFloat(empleado.TotalComision)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </div>
          </div>
        </div>
      ))}
      <div className="container-fluid">
        <div className="row">
          <div className="col-3">
            <strong>Total:</strong>
          </div>
          <div className="col-3">
            {"$ "}{" "}
            {parseFloat(granTotalTotalMostrarDos)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
          <div className="col-3"></div>
          <div className="col-3">
            {"$ "}{" "}
            {parseFloat(granTotalComisionMostrarDos)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
});

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  return (
    <div className="print-source " ref={ref}>
      <img alt="Vitanova Logo" src={imageVitanova} width="150" />
      <br />
      <br />
      <br />
      <h3>Reporte de cobranza por fecha</h3>
      <br />
      <strong>
        {" "}
        de: {CambiarFormatoFechaHelper(props.fechaInicio)} a:{" "}
        {CambiarFormatoFechaHelper(props.fechaFin)}
      </strong>
      <br />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>Serie</td>
            <td>{props.value.SERIE}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>
              {" "}
              {"$ "}
              {parseFloat(props.value.TOTAL)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
          <tr>
            <td>Porcentaje</td>
            <td>
              {" "}
              {props.value.porcentaje} {" %"}
            </td>
          </tr>
          <tr>
            <td>Total Comisión</td>
            <td>
              {"$ "}
              {parseFloat(props.value.TotalComision)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <br />
      <br />
      __________________________________________________________________
      <br />
      Firma
      <br />
      <br />
      <small style={{ fontSize: "10px" }}>Impreso por: {user.username}</small>
    </div>
  );
});

const ComponentToPrintWrapper = (props) => {
  const componentRef = React.useRef();

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell align="center">{props.item.SERIE}</TableCell>
      <TableCell align="center">
        {"$ "}
        {parseFloat(props.item.TOTAL)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
      </TableCell>
      <TableCell align="center">
        {"% "}{" "}
        {parseFloat(props.item.porcentaje)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
      </TableCell>
      <TableCell align="center">
        {"$ "}{" "}
        {parseFloat(props.item.TotalComision)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
      </TableCell>
      <TableCell align="center">
        {" "}
        <ReactToPrint
          trigger={() => (
            <button type="primary" className="btn btn-sm btn-success">
              Imprimir Recibo
            </button>
          )}
          content={() => componentRef.current}
        />
        <ComponentToPrint
          ref={componentRef}
          value={props.item}
          fechaInicio={props.fechaInicio}
          fechaFin={props.fechaFin}
        />
      </TableCell>
    </TableRow>
  );
};

export const ReporteCobranzaRangoFechaScreenComponent = () => {
  const componentRefListado = React.useRef();

  const todayInicio = new Date();
  const todayFin = new Date();
  const numberOfDaysToAdd = 15;
  const dateInicio = todayInicio.setDate(
    todayInicio.getDate() - numberOfDaysToAdd
  );
  const dateFin = todayFin.setDate(todayFin.getDate());

  const defaultValueInicio = new Date(dateInicio).toISOString().split("T")[0]; // yyyy-mm-dd

  const defaultValueFin = new Date(dateFin).toISOString().split("T")[0]; // yyyy-mm-dd

  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicial, setReporteFechaInicial] =
    React.useState(defaultValueInicio);
  const [reporteFechaFin, setReporteFechaFin] = React.useState(defaultValueFin);
  const [finalData, setFinalData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [seriesSeleccionadas, setSeriesSeleccionadas] = React.useState([]);
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listoParaMandar, setListoParaMandar] = React.useState(true);

  React.useEffect(() => {
    let listo = false;

    if (seriesSeleccionadas.length == 0) {
      toast.info("debes seleccionar una serie como mínimo");
      setListoParaMandar(true);
    } else {
      setListoParaMandar(false);
    }

    return () => {
      listo = true;
    };
  }, [seriesSeleccionadas]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8f157d70-8d87-4d17-8341-afed9c53b833" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "8f157d70-8d87-4d17-8341-afed9c53b833" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      if (parseFloat(serie.TOTAL) >= 0 && parseFloat(serie.TOTAL) <= 69999) {
        serie.porcentaje = 5;
        serie.TotalComision = parseFloat(serie.TOTAL * 5) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (
        parseFloat(serie.TOTAL) >= 70000 &&
        parseFloat(serie.TOTAL) <= 99999
      ) {
        serie.porcentaje = 6;
        serie.TotalComision = parseFloat(serie.TOTAL * 6) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (parseFloat(serie.TOTAL) >= 10000) {
        serie.porcentaje = 7;
        serie.TotalComision = parseFloat(serie.TOTAL * 7) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    for (const serie of finalData) {
      if (parseFloat(serie.TOTAL) >= 0 && parseFloat(serie.TOTAL) <= 69999) {
        serie.porcentaje = 5;
        serie.TotalComision = parseFloat(serie.TOTAL * 5) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (
        parseFloat(serie.TOTAL) >= 70000 &&
        parseFloat(serie.TOTAL) <= 99999
      ) {
        serie.porcentaje = 6;
        serie.TotalComision = parseFloat(serie.TOTAL * 6) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
      if (parseFloat(serie.TOTAL) >= 100000) {
        serie.porcentaje = 7;
        serie.TotalComision = parseFloat(serie.TOTAL * 7) / 100;
        granTotalComision = granTotalComision + serie.TotalComision;
        granTotaltotal = granTotaltotal + parseFloat(serie.TOTAL);
      }
    }
    setGranTotalTotalMostrar(granTotaltotal);
    setGranTotalComisionMostrar(granTotalComision);
    return () => {
      listo = true;
    };
  }, [finalData]);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mb-2 d-none d-print-block">
            <div className="row">
              <div className="col-12">
                <img src={imagenVitaNova} alt="Logo Vita Nova" height={30} />
              </div>
            </div>
          </div>
          <div className="container-fluid mb-3 mt-2">
            <div className="row row-cols-md-3 d-flex align-items-center">
              <div className="col">
                <MultiselectSeriesComponent
                  setSeriesSeleccionadas={setSeriesSeleccionadas}
                  seriesSeleccionadas={seriesSeleccionadas}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Inicio"
                  type="date"
                  variant="standard"
                  value={reporteFechaInicial}
                  onChange={(e) => {
                    setReporteFechaInicial(e.target.value);
                  }}
                />
              </div>
              <div className="col">
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  id="outlined-basic"
                  label="Fecha Fin"
                  type="date"
                  variant="standard"
                  value={reporteFechaFin}
                  onChange={(e) => {
                    setReporteFechaFin(e.target.value);
                  }}
                />
              </div>
              <div className="col d-print-none">
                <button
                  disabled={listoParaMandar}
                  className="btn btn-sm btn-success w-100"
                  onClick={() => {
                    setLoading(true);
                    reporteCobranzaSerie(
                      user,
                      seriesSeleccionadas,
                      reporteFechaInicial,
                      reporteFechaFin
                    ).then((resultado) => {
                      if (resultado.status == 200) {
                        setFinalData(resultado.data);
                      }
                      setLoading(false);
                    });
                  }}
                >
                  {" "}
                  Buscar{" "}
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-sm btn-info my-3">
                      Imprimir Reporte General
                    </button>
                  )}
                  content={() => componentRefListado.current}
                />
                <ListToPrintComponent
                  ref={componentRefListado}
                  value={finalData}
                  fechaInicio={reporteFechaInicial}
                  fechaFin={reporteFechaFin}
                />
              </div>
              <div className="col-md-6">
                <ButtonDownloadToXlsComponent
                  data={finalData}
                  fileName="Reporte de cobranza por fechas - Serie Gestor"
                />
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row row-cols-1">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Serie</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell align="center">
                        Porcentaje de Comisión
                      </TableCell>
                      <TableCell align="center">Total Comisión</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>
                          {" "}
                          {"$ "}{" "}
                          {granTotalTotalMostrar
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <strong>
                          {"$ "}
                          {granTotalComisionMostrar
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </TableCell>
                    </TableRow>

                    {finalData.map((row, index) => (
                      <ComponentToPrintWrapper
                        key={index}
                        item={row}
                        fechaInicio={reporteFechaInicial}
                        fechaFin={reporteFechaFin}
                      />
                    ))}

                    <TableRow>
                      <TableCell align="center">
                        <strong>Total</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>
                          {" "}
                          {"$ "}{" "}
                          {granTotalTotalMostrar
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <strong>
                          {"$ "}
                          {granTotalComisionMostrar
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                        </strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
