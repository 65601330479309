import React from "react";
import styles from "./NavPrincipalComponent.module.css";
import imagenLogoNav from "../../Images/IsotipoBlanco.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AuthContext } from "../../auth/authContext";
import jwt_decode from "jwt-decode";
import { types } from "./../../types/types";
import { SearchInAllWebComponent } from "../SearchInAllWebComponent/SearchInAllWebComponent";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { ConfigurationContext } from "./../../configuration/configurationContext";
import UpdateIcon from "@mui/icons-material/Update";
import MenuIcon from "@mui/icons-material/Menu";
import { typesConfiguration } from "./../../types/configtypes";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as yup from "yup";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Backdrop, TextField } from "@mui/material";
import {
  getObtenerDatosDeUsuarioToken,
  putActualizarDatosEmailRecuperacion,
} from "../../API/ApiEmpleados";
import { toast } from "react-toastify";
import NotificacionesDeUsuarioComponent from "../NotificacionesDeUsuarioComponent/NotificacionesDeUsuarioComponent";

import Cookies from "js-cookie";

const validationSchema = yup.object({
  emailRecuperacion: yup
    .string("Ingresa Correo")
    .email("Formato de correo no válido")
    .required("Correo requerido"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Capitalize = function (string) {
  const lower = string.toLowerCase();

  return lower.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

const eliminarTodasLasCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var igualPos = cookie.indexOf("=");
    var nombre = igualPos > -1 ? cookie.substr(0, igualPos) : cookie;
    document.cookie =
      nombre + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
  }
};

export const NavPrincipalComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [datosUsuario, setDatosUsuario] = React.useState({});

  React.useEffect(() => {
    getObtenerDatosDeUsuarioToken(user).then((result) => {
      setDatosUsuario(result?.data?.emailRecuperacion);
      formik.setFieldValue(
        "emailRecuperacion",
        result?.data?.emailRecuperacion
      );
    });
  }, []);

  var decoded = jwt_decode(user.accessToken);

  const [loading, setLoading] = React.useState(false);

  const { configuration, dispatchConfiguration } =
    React.useContext(ConfigurationContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      emailRecuperacion: new Date().getFullYear().toString(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      putActualizarDatosEmailRecuperacion(user, values).then((result) => {
        setLoading(false);
        if (result.status == 200) {
          toast.success("Gracias por actualizar tu correo electrónico");
          setOpen(false);
        } else {
          toast.error("No fue posible actualizar el correo, intenta mas tarde");
        }
      });
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 5000 }}
        open={loading}
      >
        <div className=" text-center">
          {" "}
          <CircularProgress color="inherit" /> <br /> Procesando cambios, espera
          un poco...
        </div>
      </Backdrop>

      <div
        className={
          process.env.REACT_APP_CONNECTION_MODE == "Development" || process.env.REACT_APP_CONNECTION_MODE == "Staging"
            ? `${styles.fondoMenuDevelopment} d-print-none`
            : `${styles.fondoMenu} d-print-none`
        }
      >
        <div className="container-fluid my-1 d-print-none">
          <div className="row d-flex align-items-center">
            <div
              className="col-sm-6 d-flex flex-row mb-2 d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ cursor: "pointer", marginRight: 15 }}
                onClick={() => {
                  let tomandoValoresActuales = { ...configuration };
                  tomandoValoresActuales.menuAbierto =
                    !tomandoValoresActuales.menuAbierto;

                  dispatchConfiguration({
                    type: typesConfiguration.ingresarDatos,
                    payload: tomandoValoresActuales,
                  });
                }}
              >
                <MenuIcon />
              </div>
              <img
                src={
                  "https://www.funerariavitanova.com/assets/img/logos/isotipo.png"
                }
                alt="Prueba"
                width={120}
                onClick={() => {
                  window.location.href = "/";
                }}
              />
            </div>

            <div className="col-sm-6 d-flex justify-content-end text-end d-flex align-items-center">
              <SearchInAllWebComponent />
              <NotificacionesDeUsuarioComponent />
              <Popup
                trigger={
                  <span
                    className={`${styles.disableSelect}`}
                    style={{ cursor: "pointer", marginLeft: 10 }}
                  >
                    <strong>
                      <AccountBoxIcon fontSize="small" />{" "}
                      <small className="fw-normal">{decoded.nombre}</small>
                    </strong>
                    <KeyboardArrowDownIcon fontSize="small" />
                    <br></br>
                    <small className={`fw-light me-2 ${styles.username}`}>
                      {decoded.email}
                    </small>
                  </span>
                }
                position="bottom right"
              >
                <ul
                  className="list-group list-group-flush"
                  style={{ fontSize: "13px" }}
                >
                  {/*
                   */}
                  <li
                    className="list-group-item list-group-item-action ps-4"
                    onClick={handleOpen}
                  >
                    <UpdateIcon fontSize="inherit" /> Actualizar Email
                  </li>
                  <li
                    className="list-group-item list-group-item-action ps-4"
                    onClick={() => {
                      toast.info("Cerrando Sesión");

                      Cookies.set(process.env.REACT_APP_COOKIE_NAME, "", {
                        path: "/",
                        domain: process.env.REACT_APP_COOKIE_DOMAIN,
                        secure: false,
                        expires: new Date(),
                      });

                      function saludos() {
                        window.open(process.env.REACT_APP_LOGIN_URL, "_self");
                      }
                      setTimeout(saludos, 500);
                    }}
                  >
                    Cerrar Sesión
                  </li>
                </ul>
              </Popup>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Actualiza tu email
          </Typography>

          <Typography id="modal-modal-title" variant="caption" component="p">
            Te recomendamos mantener tu correo actualizado.
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                size="small"
                id="emailRecuperacion"
                name="emailRecuperacion"
                label="Email Recuperación"
                value={formik.values.emailRecuperacion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.emailRecuperacion &&
                  Boolean(formik.errors.emailRecuperacion)
                }
                helperText={
                  formik.touched.emailRecuperacion &&
                  formik.errors.emailRecuperacion
                }
              />

              <br />

              <button
                type="submit"
                className="btn btn-sm btn-success w-100 mt-3"
              >
                Actualizar Correo
              </button>
            </form>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
