import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { RepeatOneSharp } from "@mui/icons-material";
import { DateTime } from "luxon";

export const getReporteVentasAnio = async (user) => {
  let url = API + "reports/reporteVentaAno/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      var anios = [];
      var totales = [];
      var cantidad = [];

      for (const dato of response.data) {
        anios.push(
          dato.fechaContrato == null ? "Sin Fecha" : dato.fechaContrato
        );
      }
      for (const dato of response.data) {
        let contrato = parseFloat(dato.totalContratos);
        dato.totalContratos = parseFloat(contrato);
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        totales.push([dato.fechaContrato, contrato]);
      }
      for (const dato of response.data) {
        let cantidadContratos = parseFloat(dato.cantidadContratos);
        dato.cantidadContratos = parseFloat(cantidadContratos);

        cantidad.push(cantidadContratos);
      }

      var union = {
        anios: anios,
        totales: totales,
        cantidad: cantidad,
        datos: response.data,
      };

      response.data = union;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getReporteVentasCategoriaAnio = async (user, idcategoria) => {
  let url = API + "reports/reporteVentaStaus/?status=" + idcategoria;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const dato of response.data) {
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteResumenSerie = async (
  user,
  serie,
  reporteMes,
  reporteAnio,
  statusContratos
) => {
  if (serie == "Todas" || serie == "") {
    serie = null;
  }
  if (reporteMes == "") {
    reporteMes = null;
  }
  if (reporteAnio == "") {
    reporteAnio = null;
  }
  if (statusContratos == "Todos" || statusContratos == "") {
    statusContratos = null;
  }

  let url =
    API +
    `reports/reporteResumenSerieV3/?serie=${serie}&mes=${reporteMes}&anio=${reporteAnio}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      var total = 0;

      for (const dato of response.data) {
        dato.montoPago =
          dato.montoPago == null || dato.montoPago == ""
            ? 0
            : parseFloat(dato.montoPago).toFixed(2);
        dato.importe =
          dato.importe == null || dato.importe == ""
            ? 0
            : parseFloat(dato.importe).toFixed(2);
        dato.saldoEnTabla =
          dato.saldoEnTabla == null || dato.saldoEnTabla == ""
            ? 0
            : parseFloat(dato.saldoEnTabla).toFixed(2);
        dato.totalPagado =
          dato.totalPagado == null || dato.totalPagado == ""
            ? 0
            : parseFloat(dato.totalPagado).toFixed(2);

        if (dato.recuperado == null) {
          dato.recuperado = "0.00";
        }

        if (dato.mensualidad == null) {
          dato.mensualidad = "0.00";
        }

        /* dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");

        dato.importe =
          dato.importe == "" || dato.importe == null
            ? 0
            : parseFloat(dato.importe);
*/
        dato.totalImportes =
          total + dato.importe == null || dato.importe == null
            ? 0
            : parseFloat(dato.importe).toFixed(2);

        let mensualidad =
          dato.mensualidad == null ? 0 : parseFloat(dato.mensualidad);
        let recuperado =
          dato.recuperado == null ? 0 : parseFloat(dato.recuperado);
        let umbral = mensualidad / 2;
        if (recuperado < umbral) {
          dato.pintar = "red";
        }
        if (recuperado < mensualidad && recuperado > umbral) {
          dato.pintar = "yellow";
        }
        if (recuperado >= mensualidad) {
          dato.pintar = "green";
        }
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteCobranzaRangoFecha = async (
  user,

  fechaInicial,
  fechaFin
) => {
  if (fechaInicial == "") {
    fechaInicial = null;
  }
  if (fechaFin == "") {
    fechaFin = null;
  }

  let url =
    API +
    `reports/reporteGeneralCobranza/?fechaInicial=${fechaInicial}&fechaFin=${fechaFin}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const dato of response.data) {
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteCobranzaSerie = async (
  user,
  serie,
  fechaInicial,
  fechaFin
) => {
  if (fechaInicial == "") {
    fechaInicial = null;
  }
  if (fechaFin == "") {
    fechaFin = null;
  }

  let values = {
    fechaInicial: fechaInicial,
    fechaFin: fechaFin,
    serie: serie,
  };

  let url = API + `reports/reporteCobranzaSerie`;

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const dato of response.data) {
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteCuboComisionesOrdenes = async (user, data) => {
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }
  if (data.fechaInicio == "") {
    data.fechaInicio = null;
  }
  if (data.folio == "") {
    data.folio = null;
  }
  if (data.idempleado == "") {
    data.idempleado = null;
  }

  let url = API + `reports/reporteCuboComisionesOrdenes`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const dato of response.data) {
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteCuboComisionesContratos = async (user, data) => {
  if (data.noContrato == "") {
    data.noContrato = null;
  }
  if (data.idEmpleado == "") {
    data.idEmpleado = null;
  }
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteCuboComisionesContratos`;
  //let url = `http://192.168.86.36:3000/vitadev/services/reports/reporteCuboComisionesContratos`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const dato of response.data) {
        dato.totalContratos = parseFloat(dato.totalContratos)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,");
      }
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNomina = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteNomina`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      for (const empleado of response.data.empleados) {
        let totalapagar = 0.0;

        for (const comisionorden of empleado.comisionesOrdenes) {
          totalapagar = totalapagar + parseFloat(comisionorden.montoComision);
        }
        for (const comisionprevision of empleado.comisionesPrevision) {
          totalapagar =
            totalapagar + parseFloat(comisionprevision.comisionImporte);
        }

        empleado.totalPagar = totalapagar + parseFloat(empleado.salario);
      }

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteNominaSueldos = async (user, data) => {
  let url = API + `reports/reporteNominaSalario`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNominaComisionesPrevisionAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteNominaComisionesPrevision`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      

      //Cambios 17082023
      let valoresFinales = [];
      for (const comisions of response.data) {
        //Si el pagado es mayor al total de la comisión
        parseFloat(comisions.pagado) >= parseFloat(comisions.Comision) ||
        parseFloat(comisions.pagado) <= 0
          ? valoresFinales.push()
          : valoresFinales.push(comisions);
      }

      //Modificación de la regla
      for (const comision of valoresFinales) {
        let resultadoPrimerResta =
          parseFloat(comision.pagado) + parseFloat(comision.porPagar);

        if (parseFloat(resultadoPrimerResta) > parseFloat(comision.Comision)) {
          comision.porPagar =
            parseFloat(comision.Comision) - parseFloat(comision.pagado);
          comision.porPagar = comision.porPagar.toFixed(2);
        }
      }

      response.data = valoresFinales;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNominaComisionesOrdenServicioAPII = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteNominaComisionesOrdenesGeneral`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNominaComisionesPrevisionPorUsuarioAPI = async (
  user,
  data
) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }
  if (data.idempleado == "") {
    data.idempleado = null;

    toast.error("debes seleccionar un empleado");

    return [];
  }

  let url = API + `reports/reporteNominaComisionesPrevisionPersona`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNominaComisionesPrevisionPorUsuarioAPII = async (
  user,
  data
) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }
  if (data.idempleado == "") {
    data.idempleado = null;

    toast.error("debes seleccionar un empleado");

    return [];
  }

  let url = API + `reports/reporteNominaComisionesPrevisionPersona`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      //Cambios 17082023
      let valoresFinales = [];
      for (const comisions of response.data) {
        //Si el pagado es mayor al total de la comisión

        valoresFinales.push(comisions);

        parseFloat(comisions.pagado) >= parseFloat(comisions.Comision) ||
        parseFloat(comisions.pagado) <= 0
          ? valoresFinales.push()
          : valoresFinales.push(comisions);
      }

      //Modificación de la regla
      for (const comision of valoresFinales) {
        let resultadoPrimerResta =
          parseFloat(comision.pagado) + parseFloat(comision.porPagar);

        if (parseFloat(resultadoPrimerResta) > parseFloat(comision.Comision)) {
          comision.porPagar =
            parseFloat(comision.Comision) - parseFloat(comision.pagado);
          comision.porPagar = comision.porPagar.toFixed(2);
        }
      }

      

      response.data = valoresFinales;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const reporteNominaComisionesOrdenesServicioPorUsuarioAPII = async (
  user,
  data
) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }
  if (data.idempleado == "") {
    data.idempleado = null;

    toast.error("debes seleccionar un empleado");

    return [];
  }

  let url = API + `reports/reporteNominaComisionesOrdenesPersona`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteNominaComisionesOrdenServicioAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteNominaComisionesOrdenes`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteVentasPrevisionPorFechaAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/ventasPrevisionPorFecha`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteVentasMantenimientoPorFechaAPI = async (user, data) => {
  if (data.fechaInicio == "") {
    data.fechaInicio = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url =
    API +
    `reports/reportePagosMantto/?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reportePagosOrdenesDeServicioAPI = async (user, data) => {
  if (data.fechaInicio == "") {
    data.fechaInicio = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url =
    API +
    `reports/reporteOrdenesSoloPagos/?fechaInicial=${data.fechaInicio}&fechaFinal=${data.fechaFin}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteVentasOrdenesServicioPorFechaAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/ventasOrdenesPorFecha`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reportePagosOrdenesServicioPorFechaAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFinal == "") {
    data.fechaFinal = null;
  }

  let url =
    API +
    `reports/reporteOrdenesPagos/?fechaInicial=${data.fechaInicial}&fechaFinal=${data.fechaFinal}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      

      for (const orden of response.data) {
        let totalServicios = 0;

        let totalAbonoIvaTotal = 0;
        let totalConAbonoIvaTotal = 0;
        let totalSinAbonoIvaTotal = 0;

        for (const servicio of orden.serviciosContratados) {
          totalServicios = totalServicios + parseFloat(servicio.precio);
        }

        for (const pago of orden.pagosOrden) {
          
          totalAbonoIvaTotal = totalAbonoIvaTotal + parseFloat(pago.abonoIva);
          totalConAbonoIvaTotal =
            totalConAbonoIvaTotal + parseFloat(pago.totalConAbonoIva);
          totalSinAbonoIvaTotal =
            totalSinAbonoIvaTotal + parseFloat(pago.totalSinAbonoIva);
        }

        orden.totalAbonoIVA = totalAbonoIvaTotal;
        orden.totalConAbonoIvaTotal = totalConAbonoIvaTotal;
        orden.totalSinAbonoIvaTotal = totalSinAbonoIvaTotal;
        orden.totalServicios = totalServicios;
      }

      

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteNominaOrdenServicioCreditoContadoAPI = async (
  user,
  data
) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/reporteOrdenesServicio`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      var listadoFinal = [];

      for (const ordenServicio of response.data) {
        if (parseFloat(ordenServicio.saldoOrden) <= 0) {
        } else {
          listadoFinal.push(ordenServicio);
        }
      }

      response.data = listadoFinal;

      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteConcentradoComisionesPorFechaAPI = async (user, data) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/concentradoComisiones`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const reporteConcentradoMonedasComisionesPorFechaAPI = async (
  user,
  data
) => {
  if (data.fechaInicial == "") {
    data.fechaInicial = null;
  }
  if (data.fechaFin == "") {
    data.fechaFin = null;
  }

  let url = API + `reports/denominacionesComision`;

  try {
    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const obtenerReportePorExhumar = async (user, fechaInicio, fechaFin) => {
  let url =
    API +
    `spaces/espacios/reporteExhumar/?fechaInicial=${fechaInicio}&fechaFinal=${fechaFin}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
