import React from "react";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import { JsonToExcel } from "react-json-to-excel";

export const ButtonDownloadToXlsComponent = (props) => {
  return (
    <React.Fragment>
      <JsonToExcel
        title="Descargar .XLS"
        data={props.data}
        fileName={props.fileName}
        btnClassName="btn btn-sm btn-outline-success mb-2 p-2 small textoBotonXls"
      />
      <br />
    </React.Fragment>
  );
};
