import React from "react";
import { getDetailContract } from "../../API/ApiContratos";
import { getDetalleOrdenesDeServicio } from "../../API/ApiOrdenesServicio";
import {
  getPagosPorOrdenServicio,
  nuevoPagoOrdenServicio,
} from "../../API/ApiPagosOrdenes";
import { AuthContext } from "../../auth/authContext";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import imagenVita from "../../Images/logoVitanova.jpg";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
export const EstadoDeCuentaOrdenesDeServicioScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState();
  const [finalData, setFinalData] = React.useState({
    etapaUno: [],
    etapaDos: [],
    espacios: [],
    comisiones: [],
    pagos: [],
  });

  const [totalPagosFinal, setTotalPagosFinal] = React.useState(0);

  const [finalDataPagos, setFinalDataPagos] = React.useState([]);

  const [finalTotalIVA, setTotalFinalIVA] = React.useState(0);
  const [finalTotalMonto, setTotalFinalMonto] = React.useState(0);
  const [finalTotalPagado, setTotalFinalPagado] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let listo = false;
    setLoading(true);
    getDetalleOrdenesDeServicio(user, numeroOrdenServicio).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);

        getPagosPorOrdenServicio(user, numeroOrdenServicio).then(
          (resultado) => {
            let totalIVA = 0;
            let totalMonto = 0;
            let totalPagado = 0;
            if (resultado.status == 200) {
              setFinalDataPagos(resultado.data);

              for (const pago of resultado.data) {
                totalIVA = totalIVA + parseFloat(pago.abonoIva);
                totalMonto = totalMonto + parseFloat(pago.montoPagado);
                totalPagado = totalPagado + parseFloat(pago.totalPagado);
              }
            }

            setTotalFinalIVA(totalIVA);
            setTotalFinalMonto(totalMonto);
            setTotalFinalPagado(totalPagado);
          }
        );
      }

      setLoading(false);
    });

    return () => {
      listo = true;
    };
  }, [numeroOrdenServicio]);

  return (
    <>
      {loading == true ? (
        <LoadingComponent />
      ) : (
        <>
          <div className="container-fluid d-print-none">
            <div className="row">
              <div className="col-12 mt-3">
                <BuscadorDeOrdenesDeServicioHookComponent
                  numeroOrdenServicio={numeroOrdenServicio}
                  setNumeroOrdenServicio={setNumeroOrdenServicio}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid d-print-none">
            <div className="row text-end">
              <div className="col-sm-12 mt-5">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    window.print();
                  }}
                >
                  Imprimir
                </button>
              </div>
            </div>
          </div>

          <div className="container-fluid d-print-grid">
            <div className="row">
              {/* Encabezado */}
              <div className="col-12 mt-3">
                <div className="container-fluid d-print-grid">
                  <div className="row">
                    <div className="col-sm-2 d-print-grid small">
                      <img
                        alt="logoVitanova"
                        className="mx-auto"
                        src={imagenVita}
                        width={100}
                      />
                    </div>
                    <div className="col-sm-10 text-end d-print-grid small">
                      <strong>Número Orden de Servicio:</strong>{" "}
                      {finalData.folio} <br />
                      <strong>Nombre Finado:</strong> {finalData.nombreFinado}{" "}
                      <br />
                      <strong>Fecha Servicio:</strong>{" "}
                      {CambiarFormatoFechaHelper(finalData.fecha)} <br />
                      <strong>Nombre Solicitante:</strong>{" "}
                      <strong>Fecha Defunción:</strong>{" "}
                      {CambiarFormatoFechaHelper(finalData.fechaDefuncion)}{" "}
                      <br />
                      <strong>Nombre Solicitante:</strong>{" "}
                      {finalData.nombreSolicitante} <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              {/* Pagos */}
              <div className="col-12">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th scope="col">No. Pago</th>
                      <th scope="col">Fecha Pago</th>
                      <th scope="col">Numero de Recibo</th>
                      <th scope="col">Monto Pagado</th>
                      <th scope="col">Abono IVA</th>
                      <th scope="col">A Pagar</th>
                      <th scope="col">Recibió</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <strong>Total: </strong>
                      </th>
                      <td></td>
                      <td></td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalMonto)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalIVA)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalPagado)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td></td>
                    </tr>

                    {finalDataPagos.map((pago, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{index + 1}</td>
                          <td>{CambiarFormatoFechaHelper(pago.fechaPago)}</td>
                          <td>{pago.noRecibo}</td>
                          <td>
                            {"$ "}{" "}
                            {parseFloat(pago.montoPagado)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            {"$ "}{" "}
                            {parseFloat(pago.abonoIva)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            {"$ "}{" "}
                            {parseFloat(pago.totalPagado)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>{pago.recibio}</td>
                        </tr>
                      </React.Fragment>
                    ))}
                    <tr>
                      <th>
                        <strong>Total: </strong>
                      </th>
                      <td></td>
                      <td></td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalMonto)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalIVA)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td>
                        {" "}
                        {"$ "}
                        {parseFloat(finalTotalPagado)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
