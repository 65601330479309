import React from "react";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import styles from "./RestorePasswordComponent.module.css";

import jwt_decode from "jwt-decode";
import { AuthContext } from "../../auth/authContext";
import { types } from "./../../types/types";

import vitaNovaLogo from "../../Images/logoVitanova.jpg";
import { apiRecoverPassword } from "../../API/ApiLoginUsers";
import { toast } from "react-toastify";
import { Backdrop } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const secretKey = "tribunal_de_justicia_administrativa_gto";

const validationSchema = yup.object({
  emailRecuperacion: yup
    .string("Ingresa correo electrónico")
    .email("ingresa un correo válido")
    .required("Correo requerido"),
});

export const RestorePasswordComponent = () => {
  const { dispatch } = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      emailRecuperacion: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      apiRecoverPassword(values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success(
            "Revisa tu correo electrónico, hemos enviado la url para restablecer la contraseña"
          );
        } else {
          toast.warning(
            "Ha ocurrido un problema al enviar el correo, informa al administrador"
          );
        }
        setLoading(false);
      });
    },
  });

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={styles.fondoLogin}></div>
      <div className="container animate__animated animate__fadeIn">
        <div className="row">
          <div className="col-12 col-sm-10 col-lg-5 mx-auto">
            <div className="card cardLogin" style={{ marginTop: "15vh" }}>
              <div className="card-body text-center">
                <img
                  src={vitaNovaLogo}
                  alt="Logotipo Inicio"
                  style={{ width: "200px", marginTop: "3vh" }}
                />
                <div className="my-4">
                  <h5>Recuperar Contraseña</h5>
                  <h6 className="fw-normal">
                    {" "}
                    Ingresa tu correo de recuperación{" "}
                  </h6>
                </div>
                <form onSubmit={formik.handleSubmit} className="px-5">
                  <TextField
                    margin="normal"
                    size="small"
                    fullWidth
                    id="emailRecuperacion"
                    name="emailRecuperacion"
                    label="Correo"
                    value={formik.values.emailRecuperacion}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.emailRecuperacion &&
                      Boolean(formik.errors.emailRecuperacion)
                    }
                    helperText={
                      formik.touched.emailRecuperacion &&
                      formik.errors.emailRecuperacion
                    }
                  />

                  <div className="small py-3" style={{ textAlign: "justify" }}>
                    <small className="text-muted">
                      Para recuperar tu contraseña te pedimos ingresar el correo
                      electrónico con el cuál fuiste dado de alta.
                    </small>
                  </div>

                  <button
                    className="my-4 btn btn-primary w-100 text-white"
                    type="submit"
                  >
                    Recuperar contraseña
                  </button>
                </form>
              </div>
            </div>
            <div className="d-flex justify-content-between px-1 pt-2 text-muted small">
              <small style={{ color: "white" }}>Vita Nova Funeraria</small>
              <small>
                <a
                  style={{ color: "white", textDecoration: "none" }}
                  href="/login"
                >
                  Iniciar Sesión
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
