import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getDocumentosDeContrato } from "../../API/ApiDocumentosContrato";
import AgregarDocumentoAContratoComponent from "../AgregarDocumentoAContratoComponent/AgregarDocumentoAContratoComponent";
import { getObtenerDocumentos } from "../../API/ApiDocumentos";
import TablaDocumentosDeContratoComponent from "../TablaDocumentosDeContratoComponent/TablaDocumentosDeContratoComponent";

const DocumentosDeContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [contador, setContador] = React.useState(0);
  const [finalTiposDocumentos, setFinalTiposDocumentos] = React.useState([]);
  const [finalDocumentos, setFinalDocumentos] = React.useState([]);

  React.useEffect(() => {
    getDocumentosDeContrato(user, props.contrato).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDocumentos(resultado.data);
      } else {
        setFinalDocumentos([]);
      }
    });

    getObtenerDocumentos(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalTiposDocumentos(resultado.data);
      } else {
        setFinalTiposDocumentos([]);
      }
    });
  }, [contador]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <AgregarDocumentoAContratoComponent
              tipo={"Contrato"}
              contrato={props.contrato}
              finalTiposDocumentos={finalTiposDocumentos}
              setContador={setContador}
              finalDocumentos={finalDocumentos}
              contador={contador}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <TablaDocumentosDeContratoComponent
              tipo={"Contrato"}
              contrato={props.contrato}
              finalTiposDocumentos={finalTiposDocumentos}
              setContador={setContador}
              finalDocumentos={finalDocumentos}
              contador={contador}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentosDeContratoComponent;
