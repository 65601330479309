import React from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import {
  reporteCobranzaRangoFecha,
  reporteConcentradoComisionesPorFechaAPI,
  reporteCuboComisionesContratos,
  reporteCuboComisionesOrdenes,
  reporteNomina,
  reporteNominaComisionesPrevisionAPI,
  reporteNominaComisionesPrevisionPorUsuarioAPI,
  reporteNominaComisionesPrevisionPorUsuarioAPII,
  reporteNominaSueldos,
  reporteResumenSerie,
} from "../../API/ApiReportes";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioHookComponent } from "../../Components/BuscadorDeOrdenesDeServicioHookComponent/BuscadorDeOrdenesDeServicioHookComponent";
import { getAllEmpleadosParaSelect } from "../../API/ApiEmpleados";
import { BuscadorDeContratosParaPagoComponent } from "../../Components/BuscadorDeContratosParaPagoComponent/BuscadorDeContratosParaPagoComponent";
import { BuscadorDeContratosParaReporteComponent } from "../../Components/BuscadorDeContratosParaReporteComponent/BuscadorDeContratosParaReporteComponent";
import { toast } from "react-toastify";
import imageVitanova from "../../Images/LogotipoNegro.png";

import ReactToPrint from "react-to-print";
import { ButtonDownloadToXlsComponent } from "../../Components/ButtonDownloadToXlsComponent/ButtonDownloadToXlsComponent";
import { ImprimirPdfComponentPrevisionGeneral } from "./../../Components/ImprimirPdfComponentPrevisionGeneral/ImprimirPdfComponentPrevisionGeneral";
import { ImprimirPdfComponentPrevisionDetalle } from "./../../Components/ImprimirPdfComponentPrevisionDetalle/ImprimirPdfComponentPrevisionDetalle";
import { reporteConcentradoMonedasComisionesPorFechaAPI } from "./../../API/ApiReportes";
import { ImprimirPdfPrevisionMonedasComponent } from "./../../Components/ImprimirPdfPrevisionMonedasComponent/ImprimirPdfPrevisionMonedasComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";

const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

const ListToPrintComponent = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalTotalComision, setFinalTotalComision] = React.useState(0.0);
  const [finalTotalPagado, setFinalTotalPagado] = React.useState(0.0);
  const [finalTotalPorPagar, setFinalTotalPorPagar] = React.useState(0.0);

  React.useEffect(() => {
    var totalComision = 0.0;
    var totalPagado = 0.0;
    var porPagar = 0.0;

    for (const salario of props.value) {
      totalComision = totalComision + parseFloat(salario.Comision);
      totalPagado = totalPagado + parseFloat(salario.pagado);
      porPagar = porPagar + parseFloat(salario.porPagar);
    }

    setFinalTotalComision(totalComision);
    setFinalTotalPagado(totalPagado);
    setFinalTotalPorPagar(porPagar);
  }, [props]);

  return (
    <div className="print-source " ref={ref}>
      <img
        alt="Vitanova Logo"
        className="mt-3"
        src={imageVitanova}
        width="150"
      />
      <br />
      <h3 className="mt-3">
        Reporte de Comisiones de venta (Contratos Previsión)
      </h3>
      <h6>
        De: {props.fechaInicio} a: {props.fechaFin}
      </h6>
      <br />

      <table className="table">
        <thead>
          <tr>
            <th scope="col">Contrato</th>
            <th scope="col">Fecha U Pago</th>
            <th scope="col">Vendedor</th>
            <th scope="col">Total Comisión</th>
            <th scope="col">Total comisión a pagar</th>
            <th scope="col">Por Pagar</th>
          </tr>
        </thead>
        <tbody>
          {props.value.map((empleado, index) => (
            <tr>
              <th scope="row">
                {" "}
                {empleado.serie} {empleado.noContrato}
              </th>
              <td>{empleado.fechaUpago}</td>
              <td>{empleado.vendedor}</td>
              <td>
                {"$ "}
                {empleado.Comision}
              </td>
              <td>
                {"$ "}
                {empleado.pagado}
              </td>
              <td>
                {"$ "}
                {empleado.porPagar}
              </td>
            </tr>
          ))}

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>
              {"$ "}
              {parseFloat(finalTotalComision)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
            <td>
              {"$ "}

              {parseFloat(finalTotalPagado)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
            <td>
              {"$ "}

              {parseFloat(finalTotalPorPagar)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
        </tbody>
      </table>

      <br />
    </div>
  );
});

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { user, dispatch } = React.useContext(AuthContext);

  var total = 0;

  return (
    <div className="print-source " ref={ref}>
      <img alt="Vitanova Logo" src={imageVitanova} width="150" />
      <br />
      <br />
      <h5>Reporte de Comisiones Venta (Contratos Previsión)</h5>
      <br />
      <br />
      <strong> Nombre Vendedor:</strong> {props.value.vendedor}
      <br />
      <strong> De: </strong> {props.fechaInicio} a: {props.fechaFin}
      <br />
      <br />
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Comisión</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row"></th>

            <td>
              Contrato: {props.value.serie} {props.value.noContrato}
            </td>
            <td>Fecha: {CambiarFormatoFechaHelper(props.value.fechaUpago)}</td>
            <td>
              Total Comisión: {"$ "}{" "}
              {parseFloat(props.value.totalComision)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
            <td>
              Total Pagado: {"$ "}{" "}
              {parseFloat(props.value.totalPagado)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
            <td>
              Por Pagar: {"$ "}{" "}
              {parseFloat(props.value.porPagar)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />
      <div className="text-center">
        _____________________________________________________________
        <br />
        Firma
      </div>
      <br />
      <br />
      <br />
      <small style={{ fontSize: "10px" }}>Impreso por: {user.username}</small>
    </div>
  );
});

const ComponentToPrintWrapper = (props) => {
  const componentRef = React.useRef();

  return (
    <>
      <tr>
        <td>
          {props.item.serie}
          {props.item.noContrato}
        </td>
        <td>{CambiarFormatoFechaHelper(props.item.ultimoPago)}</td>
        <td>{props.item.vendedor}</td>
        <td>{props.item.titular1}</td>
        <td>
          {" "}
          {"$ "}
          {props.item.Comision}
        </td>
        <td>
          {" "}
          {"$ "}
          {props.item.pagado}
        </td>
        <td>
          {" "}
          {"$ "}
          {props.item.porPagar}
        </td>

        <td>
          {" "}
          <ReactToPrint
            trigger={() => (
              <button type="primary" className="btn btn-sm btn-successa d-none">
                Imprimir Recibo
              </button>
            )}
            content={() => componentRef.current}
          />
          <ComponentToPrint
            ref={componentRef}
            value={props.item}
            fechaInicio={props.fechaInicio}
            fechaFin={props.fechaFin}
          />
        </td>
      </tr>
    </>
  );
};

export const ReporteDeNominaComisionesPrevisionScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [reporteSerie, setReporteSerie] = React.useState("");
  const [reporteFechaInicialUno, setReporteFechaInicialUno] =
    React.useState("");
  const [reporteFechaFinUno, setReporteFechaFinUno] = React.useState("");
  const [reporteFechaInicialDos, setReporteFechaInicialDos] =
    React.useState("");
  const [reporteFechaFinDos, setReporteFechaFinDos] = React.useState("");
  const [numeroOrdenServicio, setNumeroOrdenServicio] = React.useState("");
  const [finalDataUno, setFinalDataUno] = React.useState([]);
  const [finalDataDos, setFinalDataDos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoReporte, setPermisoReporte] = React.useState(false);
  const [idempleadoFound, setIdempleadoFound] = React.useState("");
  const [granTotalComisionMostrar, setGranTotalComisionMostrar] =
    React.useState(0.0);

  const [granTotalTotalMostrarUno, setGranTotalTotalMostrarUno] =
    React.useState(0.0);
  const [granTotalTotalMostrar, setGranTotalTotalMostrar] = React.useState(0.0);

  const [listadoEmpleados, setListadoEmpleados] = React.useState([]);

  const componentRefListado = React.useRef();

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "17227260-b09a-499a-831b-42ad3d183a41" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "17227260-b09a-499a-831b-42ad3d183a41" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    let listo = false;

    return () => {
      listo = true;
    };

    return () => {
      ready = true;
    };
  }, []);

  const [granTotalFinalComisionesDos, setGranTotalFinalComisionesDos] =
    React.useState(0.0);
  const [granTotalFinalPagadoDos, setGranTotalFinalPagadoDos] =
    React.useState(0.0);
  const [granTotalFinalPorPagarDos, setGranTotalFinalPorPagarDos] =
    React.useState(0.0);

  const [nombreEmpleadoSeleccionado, setNombreEmpleadoSeleccionado] =
    React.useState("");

  const [finalDataConsolidados, setFinalDataConsolidados] = React.useState([]);
  const [finalDataConsolidadosMonedas, setFinalDataConsolidadosMonedas] =
    React.useState([]);

  const [finalTotalComision, setFinalTotalComision] = React.useState(0.0);
  const [finalTotalPagado, setFinalTotalPagado] = React.useState(0.0);
  const [finalTotalPorPagar, setFinalTotalPorPagar] = React.useState(0.0);
  const [finalTotalAPagarEnPeriodo, setFinalTotalAPagarEnPeriodo] =
    React.useState(0.0);

  React.useEffect(() => {
    let listo = false;

    let granTotalComision = 0.0;
    let granTotaltotal = 0.0;

    var totalComision = 0.0;
    var totalPagado = 0.0;
    var porPagar = 0.0;

    for (const arregloPagos of finalDataUno) {
      totalComision = totalComision + parseFloat(arregloPagos.Comision);
      totalPagado = totalPagado + parseFloat(arregloPagos.pagado);
      porPagar = porPagar + parseFloat(arregloPagos.porPagar);
    }

    setFinalTotalComision(totalComision);
    setFinalTotalPagado(totalPagado);
    setFinalTotalPorPagar(porPagar);

    setGranTotalTotalMostrarUno(granTotaltotal);

    return () => {
      listo = true;
    };
  }, [finalDataUno]);

  React.useEffect(() => {
    let listo = false;

    let granTotalComisionDos = 0.0;
    let granTotaltotalPagadoDos = 0.0;
    let granTotalPorPagarDos = 0.0;
    let granTotalFinalTotalAPagarEnPeriodo = 0.0;

    for (const arregloPagos of finalDataDos) {
      granTotalComisionDos =
        granTotalComisionDos + parseFloat(arregloPagos.Comision);
      granTotaltotalPagadoDos =
        granTotaltotalPagadoDos + parseFloat(arregloPagos.pagado);
      granTotalPorPagarDos =
        granTotalPorPagarDos + parseFloat(arregloPagos.porPagar);
    }

    setFinalTotalAPagarEnPeriodo(granTotalFinalTotalAPagarEnPeriodo);
    setGranTotalFinalComisionesDos(granTotalComisionDos);
    setGranTotalFinalPagadoDos(granTotaltotalPagadoDos);
    setGranTotalFinalPorPagarDos(granTotalPorPagarDos);
    return () => {
      listo = true;
    };
  }, [finalDataDos]);

  React.useEffect(() => {
    let ready = false;

    getAllEmpleadosParaSelect(user).then((resultado) => {
      if (resultado.status == 200) {
        setListadoEmpleados(resultado.data);
      } else {
        setListadoEmpleados([]);
      }
    });

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    setFinalDataDos([]);
    setLoading(true);
    reporteNominaComisionesPrevisionPorUsuarioAPII(user, {
      fechaInicial: reporteFechaInicialDos,
      fechaFin: reporteFechaFinDos,
      idempleado: idempleadoFound,
    }).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataDos(resultado.data);
        setLoading(false);
      }
      setLoading(false);
    });
  }, [idempleadoFound]);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mt-4 px-2">
            <div className="row row-cols-1">
              <ul
                className="nav nav-pills mb-3 d-print-none "
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="false"
                  >
                    General
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Por Empleado
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="container-fluid mb-3 mt-2">
                    <div className="row row-cols-md-3 d-flex align-items-center">
                      <div className="col mb-2">
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          id="outlined-basic"
                          label="Fecha Inicio"
                          type="date"
                          variant="standard"
                          value={reporteFechaInicialUno}
                          onChange={(e) => {
                            setReporteFechaInicialUno(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col mb-2">
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          id="outlined-basic"
                          label="Fecha Fin"
                          type="date"
                          variant="standard"
                          value={reporteFechaFinUno}
                          onChange={(e) => {
                            setReporteFechaFinUno(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col mb-2 d-print-none">
                        <button
                          className="btn btn-sm btn-success w-100"
                          onClick={() => {
                            setLoading(true);
                            reporteNominaComisionesPrevisionAPI(user, {
                              fechaInicial: reporteFechaInicialUno,
                              fechaFin: reporteFechaFinUno,
                            }).then((resultado) => {
                              if (resultado.status == 200) {
                                setFinalDataUno(resultado.data);
                                setLoading(false);
                              }
                            });
                            setLoading(true);
                            reporteConcentradoComisionesPorFechaAPI(user, {
                              fechaInicial: reporteFechaInicialUno,
                              fechaFin: reporteFechaFinUno,
                            }).then((resultado) => {
                              if (resultado.status === 200) {
                                setFinalDataConsolidados(resultado.data);
                                setLoading(false);
                              }
                            });
                            reporteConcentradoMonedasComisionesPorFechaAPI(
                              user,
                              {
                                fechaInicial: reporteFechaInicialUno,
                                fechaFin: reporteFechaFinUno,
                              }
                            ).then((resultado) => {
                              if (resultado.status === 200) {
                                setFinalDataConsolidadosMonedas(resultado.data);
                                setLoading(false);
                              }
                            });
                          }}
                        >
                          {" "}
                          Buscar{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row row-cols-md-1">
                      <div className="col" style={{ textAlign: "right" }}>
                        <ButtonDownloadToXlsComponent
                          data={finalDataUno}
                          fileName="Reporte Comisiones de venta - contratos"
                        />

                        <ImprimirPdfComponentPrevisionGeneral
                          fechaInicio={reporteFechaInicialUno}
                          fechaFin={reporteFechaFinUno}
                          data={finalDataConsolidados}
                        />

                        <ImprimirPdfPrevisionMonedasComponent
                          fechaInicio={reporteFechaInicialUno}
                          fechaFin={reporteFechaFinUno}
                          data={finalDataConsolidadosMonedas}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <ReactToPrint
                          trigger={() => (
                            <button className="btn btn-sm btn-info my-3">
                              Imprimir Reporte General
                            </button>
                          )}
                          content={() => componentRefListado.current}
                        />
                        <ListToPrintComponent
                          ref={componentRefListado}
                          value={finalDataUno}
                          fechaInicio={reporteFechaInicialUno}
                          fechaFin={reporteFechaFinUno}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <table
                          className="table align-middle text-center"
                          style={{ textAlign: "center" }}
                        >
                          <thead style={{ textAlign: "center" }}>
                            <tr>
                              <th>Total: </th>
                              <th></th>
                              <th></th>
                              <th></th>

                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalComision)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalPagado)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalPorPagar)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                            </tr>

                            <tr
                              className="text-center"
                              style={{ textAlign: "center" }}
                            >
                              <th scope="col" className="text-center">
                                Contrato
                              </th>
                              <th scope="col" className="text-center">
                                Último Pago
                              </th>
                              <th scope="col" className="text-center">
                                Vendedor
                              </th>
                              <th scope="col" className="text-center">
                                Titular
                              </th>
                              <th scope="col" className="text-center">
                                Total Comisión
                              </th>
                              <th scope="col" className="text-center">
                                Pagado
                              </th>
                              <th scope="col" className="text-center">
                                Por pagar
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {finalDataUno.map((empleado, index) => (
                              <React.Fragment>
                                <ComponentToPrintWrapper
                                  key={index}
                                  item={empleado}
                                  fechaInicio={reporteFechaInicialUno}
                                  fechaFin={reporteFechaFinUno}
                                />
                              </React.Fragment>
                            ))}

                            <tr>
                              <th>Total: </th>
                              <th></th>
                              <th></th>
                              <th></th>

                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalComision)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalPagado)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                              <th className="">
                                {"$ "}{" "}
                                {parseFloat(finalTotalPorPagar)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="container-fluid mb-3 mt-2 d-print-none">
                    <div className="row row-cols-md-4 d-flex align-items-center">
                      <div className="col mb-2">
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          id="outlined-basic"
                          label="Fecha Inicio"
                          type="date"
                          variant="standard"
                          value={reporteFechaInicialDos}
                          onChange={(e) => {
                            setReporteFechaInicialDos(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col mb-2">
                        <TextField
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          id="outlined-basic"
                          label="Fecha Fin"
                          type="date"
                          variant="standard"
                          value={reporteFechaFinDos}
                          onChange={(e) => {
                            setReporteFechaFinDos(e.target.value);
                          }}
                        />
                      </div>

                      <div className="col mb-2">
                        <FormControl fullWidth>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                            shrink
                          >
                            Empleado
                          </InputLabel>
                          <NativeSelect
                            shrink
                            value={idempleadoFound}
                            onChange={(e) => {
                              setNombreEmpleadoSeleccionado(
                                e.target.options[e.target.selectedIndex].text
                              );

                              setIdempleadoFound(e.target.value);
                            }}
                            inputProps={{
                              name: "age",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value={""}>Todos</option>

                            {listadoEmpleados.map((empleado) => (
                              <option key={empleado.id} value={empleado.id}>
                                {empleado.nombreEmpleado}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      </div>
                      <div className="col mb-2 d-print-none">
                        <button
                          className="btn btn-sm btn-success w-100"
                          onClick={() => {
                            setFinalDataDos([]);
                            setLoading(true);
                            reporteNominaComisionesPrevisionPorUsuarioAPII(
                              user,
                              {
                                fechaInicial: reporteFechaInicialDos,
                                fechaFin: reporteFechaFinDos,
                                idempleado: idempleadoFound,
                              }
                            ).then((resultado) => {
                              if (resultado.status == 200) {
                                setFinalDataDos(resultado.data);
                                setLoading(false);
                              }
                              setLoading(false);
                            });
                          }}
                        >
                          {" "}
                          Buscar{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row row-cols-md-1">
                      <div className="col" style={{ textAlign: "right" }}>
                        <ButtonDownloadToXlsComponent
                          data={finalDataDos}
                          fileName="Reporte Comisiones de venta - contratos por empleado"
                        />

                        <ImprimirPdfComponentPrevisionDetalle
                          data={finalDataDos}
                          fechaInicio={reporteFechaInicialDos}
                          fechaFin={reporteFechaFinDos}
                          nombreEmpleadoSeleccionado={
                            nombreEmpleadoSeleccionado
                          }
                          finalTotalAPagarEnPeriodo={finalTotalAPagarEnPeriodo}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        <img
                          alt="Vitanova Logo"
                          src={imageVitanova}
                          width="150"
                          className="d-none d-print-block"
                        />
                        <br />
                        <br />
                        Comisiones del:{" "}
                        {CambiarFormatoFechaHelper(
                          reporteFechaInicialDos
                        )} al: {CambiarFormatoFechaHelper(reporteFechaFinDos)}
                        <br />
                        Nombre: {nombreEmpleadoSeleccionado}
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row g-0">
                      <div className="col-12">
                        <table className="table align-middle text-center">
                          <thead>
                            <tr>
                              <th scope="col">Contrato</th>
                              <th scope="col">Fecha Pago</th>
                              <th scope="col">Nombre Titular 1</th>
                              <th scope="col">Comisión</th>
                              <th scope="col">Pagado</th>
                              <th scope="col">Por pagar</th>

                              {/*
                              <th scope="col d-none">Total comisión a pagar</th>
                        <th scope="col d-none">A Pagar en el periodo</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Total: </th>
                              <th> </th>
                              <th> </th>
                              <th>
                                {/* parseFloat(granTotalFinalComisionesDos)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,") */}
                              </th>

                              <th>
                                {/* parseFloat(granTotalFinalPorPagarDos)
                                  .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,") */}
                              </th>

                              <th>
                                {"$ "}
                                {parseFloat(
                                  finalDataDos.reduce(
                                    (acumulador, producto) => {
                                      return (
                                        acumulador +
                                        parseFloat(producto.porPagar)
                                      );
                                    },
                                    0
                                  )
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                            </tr>

                            {finalDataDos.map((comision, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  <a href={`/contrato/${comision.id}/false`}>
                                    {comision.noContrato}
                                  </a>
                                </th>
                                <td>
                                  {CambiarFormatoFechaHelper(
                                    comision.ultimoPago
                                  )}
                                </td>
                                <td>{comision.titular1}</td>
                                <td>
                                  {/* ultimos cambios */}
                                  {"$ "}
                                  {parseFloat(comision.Comision)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td>
                                  {"$ "}
                                  {parseFloat(comision.pagado)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td>
                                  {"$ "}
                                  {parseFloat(comision.porPagar)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>

                                {/* <td>
                                  {"$ "}
                                  {parseFloat(comision.porPagar)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
                                <td>
                                  {"$ "}
                                  {parseFloat(comision.aPagarEnPeriodo)
                                    .toFixed(2)
                                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                                </td>
*/}
                              </tr>
                            ))}

                            <tr>
                              <th>Total: </th>
                              <th> </th>
                              <th> </th>
                              <th>
                                {/* parseFloat(granTotalFinalComisionesDos)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,") */}
                              </th>

                              <th>
                                {/* parseFloat(granTotalFinalPorPagarDos)
                                  .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, "$&,") */}
                              </th>

                              <th>
                                {"$ "}
                                {parseFloat(
                                  finalDataDos.reduce(
                                    (acumulador, producto) => {
                                      return (
                                        acumulador +
                                        parseFloat(producto.porPagar)
                                      );
                                    },
                                    0
                                  )
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loading == true ? <LoadingComponent /> : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
