import React from "react";
import { getAllPanteones } from "../../API/ApiPanteones";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AuthContext } from "../../auth/authContext";
import { AddPanteonComponent } from "../../Components/AddPanteonComponent/AddPanteonComponent";
import { LoadingSkeletonComponent } from "../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { LoadingComponent } from "./../../Components/LoadingComponent/LoadingComponent";

const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const PanteonesScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [agregarPagoOrdenServicio, setAgregarPagoOrdenServicio] =
    React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "3357c30a-49e7-4e3d-a60b-a31c7e5fb6b2" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "379cd4df-e3f2-4e57-b03e-287dbe7ba119" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    setLoading(true);
    getAllPanteones(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setLoading(false);
      }
    });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    getAllPanteones(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setLoading(false);
      }
    });
  }, [crudAction]);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <>
          {permisoParaAgregar == true ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-end">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => {
                      setCrudAction({
                        action: "add",
                        data: null,
                      });
                    }}
                  >
                    Agregar Nuevo Panteón
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {loading == true ? (
            <LoadingSkeletonComponent />
          ) : (
            <>
              <div className="container-fluid mt-3">
                <div className="row row-cols-1">
                  {finalData.map((panteon, index) => (
                    <div className="col mb-2" key={index}>
                      <div className="card">
                        <div className="card-body">
                          <strong> {panteon.nombre}</strong>
                          <br />
                          <small> {panteon.direccion}</small>
                          <br />
                          <small> Estado: {panteon.nombreEstado}</small>
                          <br />
                          <small> Municipio: {panteon.nombreCiudad}</small>
                          <br />
                        </div>
                        <div className="card-footer">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            {panteon.nombre === "MAUSOLEO" ? (
                              <>
                                <button
                                  className="btn btn-primary btn-success btn-sm"
                                  type="button"
                                  onClick={() => {
                                    window.open(
                                      "/jardines/panteon/" +
                                        encodeURI(JSON.stringify(panteon)),
                                      "_self"
                                    );
                                  }}
                                >
                                  Mausoleo
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn-primary btn-success btn-sm"
                                  type="button"
                                  onClick={() => {
                                    window.open(
                                      "/jardines/panteon/" +
                                        encodeURI(JSON.stringify(panteon)),
                                      "_self"
                                    );
                                  }}
                                >
                                  Ver
                                </button>
                              </>
                            )}

                            {panteon.nombre === "MAUSOLEO" ? null : (
                              <>
                                <button
                                  className="btn btn-primary btn-success btn-sm"
                                  type="button"
                                  onClick={() => {
                                    window.open(
                                      "/panteon/capillas/" +
                                        encodeURI(JSON.stringify(panteon)),
                                      "_self"
                                    );
                                  }}
                                >
                                  {panteon.nombre === "MAUSOLEO"
                                    ? "Mausoleo"
                                    : "Capillas"}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {crudAction.action == "add" ? (
            <AddPanteonComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
            />
          ) : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
