import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../auth/authContext";
import Alert from "@mui/material/Alert";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { TextField } from "@mui/material";
import {
  postCreateCatalogosContratos,
  putDeleteCatalogosContratosSerieCobranza,
  putEditCatalogosContratosSerieCobranza,
} from "../../API/ApiSeriesCobranza";
import { toast } from "react-toastify";

import styles from "./CrudAsociarSeriesCobradoresComponent.module.css";
import { postAgregarEliminarCobrador } from "../../API/ApiCobranza";

const validationSchema = yup.object({
  serie: yup.string("Serie").required("Requerido"),
});

const CrudAsociarSeriesCobradoresComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [isDisabled, setIsDisabled] = React.useState(false);

  console.log(props);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      serie:
        props.crudAction.action !== "Crear"
          ? props?.crudAction?.data?.serie
          : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      if (props.crudAction.action == "Crear") {
        postCreateCatalogosContratos(user, values).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie agregada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }
      if (props.crudAction.action == "Editar") {
        putEditCatalogosContratosSerieCobranza(
          user,
          props.crudAction.data.id,
          values
        ).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie actualizada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }
      if (props.crudAction.action == "Eliminar") {
        putDeleteCatalogosContratosSerieCobranza(
          user,
          props.crudAction.data.id,
          values
        ).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie eliminada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }

      /*  editarCapillaByIdPanteon(user, values).then((resultado) => {
        if (resultado.status == 200) {
          props.setCrudAction({ action: null, data: null });
          toast.success("Capilla Agregada correctamente");
        }
        setIsDisabled(false);
      }); */
    },
  });

  return (
    <div className="crudDivComponent col-md-3 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>{props.crudAction.action} Series de Cobranza</h6>
                <small className="text-muted">
                  Ingresa los datos para {props.crudAction.action.toLowerCase()}{" "}
                  series de cobranza de <br />
                  <strong>
                    {" "}
                    {props.crudAction.data.nombre}{" "}
                    {props.crudAction.data.apaterno}{" "}
                    {props.crudAction.data.amaterno}{" "}
                  </strong>
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>

                <LoadingComponent />
              </>
            ) : null}
            <hr />
          </div>
        </div>

        <div className="row row-cols-1">
          {props.finalDataSeries.map((serie) => {
            let seleccionado = false;

            let datoBuscar = props.crudAction.data.empleadoseriecobranzas.find(
              (valorBuscar) => valorBuscar.cseriecobranza.id == serie.id
            );

            if (typeof datoBuscar !== "undefined") {
              seleccionado = true;
            }

            return (
              <React.Fragment key={serie.id}>
                <div className="col">
                  <div
                    className={`card strong mb-3 ${styles.tablaAgregado} ${
                      seleccionado == true ? styles.tablaSiCoincide : null
                    }`}
                    onClick={() => {
                      postAgregarEliminarCobrador(user, {
                        idempleado: props.crudAction.data.id,
                        idserieCobranza: serie.id,
                      }).then((resultado) => {
                        if (resultado.status == 200) {
                          toast.success("Se ha actualizado correctamente");
                          props.setCrudAction({ action: null, data: null });
                        } else {
                          toast.error(
                            "No se ha podido actualizar, intenta nuevamente"
                          );
                          props.setCrudAction({ action: null, data: null });
                        }
                      });
                    }}
                  >
                    <div className={`card-body cursor-pointer`}>
                      {serie.serie}{" "}
                      {seleccionado == true ? <> - Asignado</> : null}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CrudAsociarSeriesCobradoresComponent;
