import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getDetailContract, searchContracts } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";

export const BuscadorDeContratosParaReporteComponentEstadoDeCuenta = (
  props
) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [numeroEncontrado, setNumeroEncontrado] = React.useState();

  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getDetailContract(user, numeroEncontrado).then((resultado) => {
      if (resultado.status == 200) {
        props.setDatosDeContrato(resultado.data);
      }
    });
  }, [numeroEncontrado]);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={finalData}
        getOptionLabel={(option) =>
          `${option.serie} ${option.noContrato} -${option.titular}`
        }
        onInputChange={(event, option) => {
          searchContracts(user, event.target.value).then((resultado) => {
            if (resultado.status == 200) {
              setFinalData(resultado.data);
            } else {
              setFinalData([]);
            }
          });
        }}
        onChange={(event, option) => {
          props.setNumeroContratoEncontrado(option.id);
          setNumeroEncontrado(option.id);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <TextField {...params} label="Buscar Número de Contrato" />
        )}
      />
    </>
  );
};
