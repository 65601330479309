import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getContratosPrevisionTop } from "../../API/ApiContratos";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DataTable from "react-data-table-component";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toast } from "react-toastify";
import { getOrdenesDeServicioTop } from "../../API/ApiOrdenesServicio";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";

const guidVista = "78a6fa05-0627-4009-910f-216d25babef9";

const TablaUltimasOrdenesComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [params, setParams] = React.useState({
    order: "DESC",
    limit: 50,
    offset: 0,
  });

  const [finalData, setFinalData] = React.useState([]);
  const [finalDataSumarizado, setFinalDataSumarizado] = React.useState([]);

  const [verNumeralia, setVerNumeralia] = React.useState(false);

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "e607055e-ea00-4f45-b733-5fa3ebd0f3ac" &&
              permiso.allowed == 1
            ) {
              setVerNumeralia(true);
            }
          }
        }
      }
    );
  }, []);

  const columns = [
    {
      name: "Folio",
      selector: (row) => row?.folio,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => CambiarFormatoFechaHelper(row?.fecha),
      sortable: true,
    },
    {
      name: "Monto",
      selector: (row) => row?.importeOrden,
      sortable: true,
    },
    {
      name: "Finado",
      selector: (row) => row?.nombreFinado,
      sortable: true,
    },
    {
      name: "Solicitante",
      selector: (row) => row?.nombreSolicitante,
      sortable: true,
    },

    {
      name: "Acciones",
      selector: (row) => {
        return (
          <>
            {" "}
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                window.open("/ordenservicio/" + row.folio + "/false", "_self");
              }}
              type="button"
            >
              Acceder a órden de servicio
            </button>
          </>
        );
      },
      sortable: true,
    },
  ];

  React.useEffect(() => {
    getOrdenesDeServicioTop(user, params).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data.rows);
        setFinalDataSumarizado(resultado.data);
      } else {
        setFinalData([]);
        setFinalDataSumarizado([]);
      }
    });
  }, [params]);

  return (
    <>
      {verNumeralia == true ? (
        <>
          <div className=" container-fluid mb-2">
            <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Total órdenes</h5>
                    <p className="card-text">{finalDataSumarizado.count}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Total Vendido</h5>
                    <p className="card-text">
                      {finalDataSumarizado.totalVendido}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12" style={{ textAlign: "right" }}>
            <button
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                if (params.offset == 0) {
                  toast.info(
                    "No puedes establecer esto porque estas en la primera página"
                  );
                  return;
                }

                setParams((prevParams) => {
                  return {
                    ...prevParams,
                    offset:
                      params.offset - params.limit == 1
                        ? 0
                        : params.offset - params.limit,
                  };
                });
              }}
            >
              {" "}
              <KeyboardArrowLeftIcon fontSize="inherit" />
            </button>
            <button
              className="btn btn-sm btn-outline-success"
              onClick={() => {
                setParams((prevParams) => {
                  return {
                    ...prevParams,
                    offset:
                      params.offset === 0
                        ? params.limit + 1
                        : params.limit + params.offset,
                  };
                });
              }}
            >
              {" "}
              <KeyboardArrowRightIcon fontSize="inherit" />
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <DataTable
              columns={columns}
              data={finalData}
              fixedHeader={true}
              fixedHeaderScrollHeight={"50vh"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TablaUltimasOrdenesComponent;
