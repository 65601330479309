import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import { AuthContext } from "../../auth/authContext";
import Alert from "@mui/material/Alert";
import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { TextField } from "@mui/material";
import {
  postCreateCatalogosContratos,
  putDeleteCatalogosContratosSerieCobranza,
  putEditCatalogosContratosSerieCobranza,
} from "../../API/ApiSeriesCobranza";
import { toast } from "react-toastify";
const validationSchema = yup.object({
  serie: yup.string("Serie").required("Requerido"),
  urlImagen: yup.string("Ingresa").required("Requerido"),
});

const CrudSeriesDeCobranzaComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [isDisabled, setIsDisabled] = React.useState(false);

  console.log(props);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      serie:
        props.crudAction.action !== "Crear"
          ? props?.crudAction?.data?.serie
          : "",
      urlImagen:
        props.crudAction.action !== "Crear"
          ? props?.crudAction?.data?.urlImagen
          : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      if (props.crudAction.action == "Crear") {
        postCreateCatalogosContratos(user, values).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie agregada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }
      if (props.crudAction.action == "Editar") {
        putEditCatalogosContratosSerieCobranza(
          user,
          props.crudAction.data.id,
          values
        ).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie actualizada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }
      if (props.crudAction.action == "Eliminar") {
        putDeleteCatalogosContratosSerieCobranza(
          user,
          props.crudAction.data.id,
          values
        ).then((resultado) => {
          if (resultado.status == 200) {
            toast.success("Serie eliminada correctamente");
            props.setCrudAction({ action: null, data: null });
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
      }

      /*  editarCapillaByIdPanteon(user, values).then((resultado) => {
        if (resultado.status == 200) {
          props.setCrudAction({ action: null, data: null });
          toast.success("Capilla Agregada correctamente");
        }
        setIsDisabled(false);
      }); */
    },
  });

  return (
    <div className="crudDivComponent col-md-3 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>{props.crudAction.action} Serie de Cobranza</h6>
                <small className="text-muted">
                  Ingresa los datos para {props.crudAction.action.toLowerCase()}{" "}
                  serie de cobranza
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>

                <LoadingComponent />
              </>
            ) : null}
            <hr />
          </div>
        </div>

        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row row-cols-1">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="serie"
                  name="serie"
                  label="Serie"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.serie}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.serie && Boolean(formik.errors.serie)}
                  helperText={formik.touched.serie && formik.errors.serie}
                  disabled={
                    props.crudAction.action === "Eliminar" ? true : isDisabled
                  }
                />

                <TextField
                  size="small"
                  fullWidth
                  id="urlImagen"
                  name="urlImagen"
                  label="urlImagen"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.urlImagen}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.urlImagen && Boolean(formik.errors.urlImagen)
                  }
                  helperText={
                    formik.touched.urlImagen && formik.errors.urlImagen
                  }
                  disabled={
                    props.crudAction.action === "Eliminar" ? true : isDisabled
                  }
                />
              </div>
              <div className="col mt-3">
                <button type="submit" className="btn btn-sm btn-warning w-100">
                  {props.crudAction.action} Serie Cobranza
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CrudSeriesDeCobranzaComponent;
