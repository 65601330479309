import React from "react";
import {
  Document,
  View,
  Page,
  Text,
  PDFDownloadLink,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import logoVitanova from "../../Images/LogotipoNegro.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: 30,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: "30%",
    height: "auto",
    textAlign: "center",
    alignSelf: "center",
  },
  titulo: {
    fontSize: 12,
    marginTop: 30,
    textAlign: "center",
  },
  tituloNombre: {
    fontSize: 12,
    marginTop: 10,
    textAlign: "center",
  },
  firmaConformidad: {
    fontSize: 10,
    marginTop: 200,
    textAlign: "center",
  },
  textoFirmaConformidad: {
    fontSize: 10,
    textAlign: "center",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    textAlign: "center",
    width: "25%",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    textAlign: "center",
    width: "25%",
  },
});

export const ImprimirPdfComponentOrdenesServicioPersona = (props) => {
  const generatePDF = () => (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image style={styles.image} src={logoVitanova} />
            <Text style={styles.titulo}>
              Listado de comisiones del día: {props.fechaInicio} al{" "}
              {props.fechaFin}
            </Text>
            <Text style={styles.tituloNombre}>
              Nombre: {props.nombreEmpleadoSeleccionado}
            </Text>
          </View>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableCellHeader}>Fecha</Text>
              <Text style={styles.tableCellHeader}>Folio</Text>
              <Text style={styles.tableCellHeader}>Observaciones</Text>
              <Text style={styles.tableCellHeader}>Monto</Text>
            </View>

            {props.data.map((recibo, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCell}>{recibo.fecha}</Text>
                <Text style={styles.tableCell}>{recibo.folio}</Text>
                <Text style={styles.tableCell}>{recibo.observaciones}</Text>
                <Text style={styles.tableCell}>{recibo.montoComision}</Text>
              </View>
            ))}

            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{"Total"}</Text>
              <Text style={styles.tableCell}>{""}</Text>
              <Text style={styles.tableCell}>{""}</Text>
              <Text style={styles.tableCell}> $ {props.totalComisiones}</Text>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );

  return (
    <div>
      <PDFDownloadLink
        document={generatePDF()}
        fileName={`Comisiones_OS_${props.fechaInicio}_${props.fechaFin}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Cargando documento..."
          ) : (
            <div className="btn btn-sm btn-danger mt-3">Descargar PDF</div>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};
