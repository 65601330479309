import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import {
  createNewOrder,
  editOrdenServicio,
} from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";

export const EditDatosDeDefuncionServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  let arr = props.finalData.folio.split("");
  let numeroFolio = props.finalData.folio;

  const formik = useFormik({
    initialValues: {
      letraFolio: arr[0],
      noContrato: props.finalData.noContrato,
      folio: props.finalData.folio,
      fecha: props.finalData.fecha,
      nombreFinado: props.finalData.nombreFinado,
      lugarNacimiento: props.finalData.lugarNacimiento,
      fechaNacimiento: props.finalData.fechaNacimiento,
      sexo: props.finalData.sexo,
      nacionalidad: props.finalData.nacionalidad,
      estadoCivil: props.finalData.estadoCivil,
      domicilio: props.finalData.domicilio,
      colonia: props.finalData.colonia,
      derechohabiente: props.finalData.derechohabiente,
      ocupacion: props.finalData.ocupacion,
      nombrePadre: props.finalData.nombrePadre,
      nombreMadre: props.finalData.nombreMadre,
      nombreConyugue: props.finalData.nombreConyugue,
      lugarDefuncion: props.finalData.lugarDefuncion,
      fechaDefuncion: props.finalData.fechaDefuncion,
      fechaMisa: props.finalData.fechaMisa,
      horaDefuncion: props.finalData.horaDefuncion,
      atencionMedica: props.finalData.atencionMedica,
      lugarVelacion: props.finalData.lugarVelacion,
      horaMisa: props.finalData.horaMisa,
      templo: props.finalData.templo,
      horaSepelio: props.finalData.horaSepelio,
      ubicacion: props.finalData.ubicacion,
      perpetuidad: props.finalData.perpetuidad,
      nomAexhum: props.finalData.nomAexhum,
      FDPAExhum: props.finalData.FDPAExhum,
      perpetuidadAExhum: props.finalData.perpetuidadAExhum,
      nombreSolicitante: props.finalData.nombreSolicitante,
      domicilioSolicitante: props.finalData.domicilioSolicitante,
      coloniaSolicitante: props.finalData.coloniaSolicitante,
      parentesco: props.finalData.parentesco,
      telefonoSolicitante: props.finalData.telefonoSolicitante,
      emergencia: props.finalData.emergencia,
      fechaLPago: props.finalData.fechaLPago,
      nombrePanteon: props.finalData.nombrePanteon,
      observaciones: props.finalData.observaciones,
      clasificacion: props.finalData.clasificacion,

      horaCremacion: props.finalData.horaCremacion,
      hornoCremacion: props.finalData.hornoCremacion,
      fechaCremacion: props.finalData.fechaCremacion,

      fechaExhumar: props.finalData.fechaExhumar,
      nombreExhumar: props.finalData.nombreExhumar,
      horaExhumar: props.finalData.horaExhumar,
      comentarioExhumar: props.finalData.comentarioExhumar,
    },

    onSubmit: (values) => {
      values.fechaLPago = DateTime.now().toISODate();

      values.folio = values.folio.slice(1);
      values.folio = values.letraFolio.split("")[0] + values.folio;

      const regex = /^[A-Za-z]\d+$/;

      if (!regex.test(values.folio)) {
        toast.error("El folio no tiene el formato correcto");
        toast.error("La Letra debe ser seguida de un número");
        setIsDisabled(false);
        return;
      }

      if (values.fechaMisa == "") {
        values.fechaMisa = DateTime.now().toISODate();
      }

      editOrdenServicio(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success(
            "Se ha Editado la orden de servicio, consulta pestaña de detalle"
          );
          setIsDisabled(false);
        }
      });
    },
  });

  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row">
              <div className="col mb-3 fw-bold h5">Datos de defunción</div>
            </div>
            <div className="row row-cols-3">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="lugarDefuncion"
                  name="lugarDefuncion"
                  label="Lugar Defunción"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.lugarDefuncion}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={() => formik.submitForm()}
                  variant="standard"
                  error={
                    formik.touched.lugarDefuncion &&
                    Boolean(formik.errors.lugarDefuncion)
                  }
                  helperText={
                    formik.touched.lugarDefuncion &&
                    formik.errors.lugarDefuncion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaDefuncion"
                  name="fechaDefuncion"
                  label="Fecha Defunción"
                  onBlur={() => formik.submitForm()}
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaDefuncion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaDefuncion &&
                    Boolean(formik.errors.fechaDefuncion)
                  }
                  helperText={
                    formik.touched.fechaDefuncion &&
                    formik.errors.fechaDefuncion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="horaDefuncion"
                  name="horaDefuncion"
                  label="Hora Defunción"
                  onBlur={() => formik.submitForm()}
                  type={"time"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.horaDefuncion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.horaDefuncion &&
                    Boolean(formik.errors.horaDefuncion)
                  }
                  helperText={
                    formik.touched.horaDefuncion && formik.errors.horaDefuncion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="atencionMedica"
                  name="atencionMedica"
                  label="Médico Certificante"
                  onBlur={() => formik.submitForm()}
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.atencionMedica}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.atencionMedica &&
                    Boolean(formik.errors.atencionMedica)
                  }
                  helperText={
                    formik.touched.atencionMedica &&
                    formik.errors.atencionMedica
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="lugarVelacion"
                  name="lugarVelacion"
                  label="Lugar de Velación"
                  onBlur={() => formik.submitForm()}
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.lugarVelacion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.lugarVelacion &&
                    Boolean(formik.errors.lugarVelacion)
                  }
                  helperText={
                    formik.touched.lugarVelacion && formik.errors.lugarVelacion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col"></div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaMisa"
                  name="fechaMisa"
                  label="Fecha Misa"
                  onBlur={() => formik.submitForm()}
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaMisa}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaMisa && Boolean(formik.errors.fechaMisa)
                  }
                  helperText={
                    formik.touched.fechaMisa && formik.errors.fechaMisa
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="horaMisa"
                  name="horaMisa"
                  label="Hora Misa"
                  onBlur={() => formik.submitForm()}
                  type={"time"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.horaMisa}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.horaMisa && Boolean(formik.errors.horaMisa)
                  }
                  helperText={formik.touched.horaMisa && formik.errors.horaMisa}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="templo"
                  name="templo"
                  label="Templo"
                  onBlur={() => formik.submitForm()}
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.templo}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.templo && Boolean(formik.errors.templo)}
                  helperText={formik.touched.templo && formik.errors.templo}
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="horaSepelio"
                  name="horaSepelio"
                  onBlur={() => formik.submitForm()}
                  label="Hora Sepelio"
                  type={"time"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.horaSepelio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.horaSepelio &&
                    Boolean(formik.errors.horaSepelio)
                  }
                  helperText={
                    formik.touched.horaSepelio && formik.errors.horaSepelio
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col">
                <div className="col">
                  <FormControl fullWidth>
                    <InputLabel
                      variant="standard"
                      shrink
                      htmlFor="uncontrolled-native"
                    >
                      Perpetuidad
                    </InputLabel>
                    <NativeSelect
                      value={formik.values.perpetuidad}
                      onChange={formik.handleChange}
                      onBlur={() => formik.submitForm()}
                      disabled={isDisabled}
                      inputProps={{
                        name: "perpetuidad",
                        id: "uncontrolled-native",
                      }}
                    >
                      <option value={"FALSO"}>FALSO</option>
                      <option value={"VERDADERO"}>VERDADERO</option>
                    </NativeSelect>
                  </FormControl>
                </div>
              </div>
            </div>
            <br />

            <div className="row row-cols-1">
              <div className="col">
                <h6>Datos de Cremación</h6>
              </div>
            </div>
            <div className="row row-cols-3">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="hornoCremacion"
                  name="hornoCremacion"
                  onBlur={() => formik.submitForm()}
                  label="Horno Cremación"
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.hornoCremacion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.hornoCremacion &&
                    Boolean(formik.errors.hornoCremacion)
                  }
                  helperText={
                    formik.touched.hornoCremacion &&
                    formik.errors.hornoCremacion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaCremacion"
                  name="fechaCremacion"
                  label="Fecha Cremación"
                  onBlur={() => formik.submitForm()}
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaCremacion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaCremacion &&
                    Boolean(formik.errors.fechaCremacion)
                  }
                  helperText={
                    formik.touched.fechaCremacion &&
                    formik.errors.fechaCremacion
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="horaCremacion"
                  name="horaCremacion"
                  label="Hora Cremación"
                  onBlur={() => formik.submitForm()}
                  type={"time"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.horaCremacion}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.horaCremacion &&
                    Boolean(formik.errors.horaCremacion)
                  }
                  helperText={
                    formik.touched.horaCremacion && formik.errors.horaCremacion
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>

            <br />

            <div className="row row-cols-1">
              <div className="col">
                <h6>Datos de Inhumación</h6>
              </div>
            </div>
            <div className="row row-cols-3">
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="nombreExhumar"
                  name="nombreExhumar"
                  label="Nombre Panteón"
                  onBlur={() => formik.submitForm()}
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.nombreExhumar}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.nombreExhumar &&
                    Boolean(formik.errors.nombreExhumar)
                  }
                  helperText={
                    formik.touched.nombreExhumar && formik.errors.nombreExhumar
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="fechaExhumar"
                  name="fechaExhumar"
                  label="Fecha Inhumación"
                  onBlur={() => formik.submitForm()}
                  type={"date"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.fechaExhumar}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.fechaExhumar &&
                    Boolean(formik.errors.fechaExhumar)
                  }
                  helperText={
                    formik.touched.fechaExhumar && formik.errors.fechaExhumar
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="horaExhumar"
                  name="horaExhumar"
                  label="Hora Inhumación"
                  onBlur={() => formik.submitForm()}
                  type={"time"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.horaExhumar}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.horaExhumar &&
                    Boolean(formik.errors.horaExhumar)
                  }
                  helperText={
                    formik.touched.horaExhumar && formik.errors.horaExhumar
                  }
                  disabled={isDisabled}
                />
              </div>
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="comentarioExhumar"
                  name="comentarioExhumar"
                  label="Comentarios Inhumación"
                  onBlur={() => formik.submitForm()}
                  type={"text"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.comentarioExhumar}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={
                    formik.touched.comentarioExhumar &&
                    Boolean(formik.errors.comentarioExhumar)
                  }
                  helperText={
                    formik.touched.comentarioExhumar &&
                    formik.errors.comentarioExhumar
                  }
                  disabled={isDisabled}
                />
              </div>
            </div>

            {props.permisoParaEditar == true ? (
              <>
                <div className="row row-cols-1 mt-3">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn w-100 btn-sm btn-success"
                    >
                      Editar datos de defunción
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};
