import React from "react";
import { AuthContext } from "../../auth/authContext";
import { getUsers } from "../../API/ApiUsers";
import {
  getBitacoraDeContrato,
  getBitacoraDeOrden,
} from "../../API/ApiBitacora";
import { MentionsInput, Mention } from "react-mentions";
import classNames from "./BitacoraDetalleOrdenServicioComponent.module.css";
import InputInsertarComentarioComponent from "../InputInsertarComentarioComponent/InputInsertarComentarioComponent";
import ListadoDeBitacoraComponent from "../ListadoDeBitacoraComponent/ListadoDeBitacoraComponent";
const BitacoraDetalleOrdenServicioComponent = (props) => {
  console.log(props);
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [contador, setContador] = React.useState(0);
  const [finalDataUsuarios, setFinalDataUsuarios] = React.useState([]);
  const [finalDataBitacora, setFinalDataBitacora] = React.useState([]);

  const [value, setValue] = React.useState("");
  const [nombresMencionados, setNombresMencionados] = React.useState([]);

  React.useEffect(() => {
    getUsers(user).then((resultado) => {
      if (resultado.status == 200) {
        let nuevoArray = [];

        for (const iterator of resultado.data) {
          let nuevoObjeto = {};
          nuevoObjeto.id = iterator.id;
          nuevoObjeto.display = iterator.nombre;

          nuevoArray.push(nuevoObjeto);
        }

        setFinalDataUsuarios(nuevoArray);
      } else {
        setFinalDataUsuarios([]);
      }
    });
    getBitacoraDeOrden(user, props.contrato).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataBitacora(resultado.data);
      } else {
        setFinalDataBitacora([]);
      }
    });
  }, [contador, props.finalData]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-12">
            <ListadoDeBitacoraComponent
              tipo={"Orden de Servicio"}
              listado={finalDataBitacora}
              setContador={setContador}
              contador={contador}
            />
          </div>
        </div>
      </div>

      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-md-12">
            <InputInsertarComentarioComponent
              tipo={"Orden de Servicio"}
              contrato={props.contrato}
              setContador={setContador}
              contador={contador}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BitacoraDetalleOrdenServicioComponent;
