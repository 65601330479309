import React from "react";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { AuthContext } from "../../auth/authContext";
import { ReportesDeContratoComponent } from "../../Components/ReportesComponent/ReportesDeContratoComponent";
import { ReportesDeContratoComponentPorStatus } from "../../Components/ReportesComponent/ReportesDeContratoComponentPorStatus";
import { SinPermisoComponent } from "./../../Components/SinPermisoComponent/SinPermisoComponent";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DateTime } from "luxon";
const guidVista = "ef433e95-e3b0-4caa-a533-3b599add112e";

export const ReportesScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [agregarPagoOrdenServicio, setAgregarPagoOrdenServicio] =
    React.useState(false);

  const [permisoReporte, setPermisoReporte] = React.useState(false);
  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8889a307-deb0-421b-97ab-97f548c74e3d" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }

            if (
              permiso.guidFuncion == "1e0e879b-e633-4539-b66e-4180b484a602" &&
              permiso.allowed == 1
            ) {
              setPermisoReporte(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      {permisoParaVisualizar == true && permisoReporte == true ? (
        <>
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-12"></div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <h5>Reporte de ventas por año</h5>
                <ReportesDeContratoComponent />
              </div>
              <div className="col-md-6 d-none">
                <h5>Reporte de ventas por tipo</h5>
                <ReportesDeContratoComponentPorStatus />
              </div>
            </div>
          </div>
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
