import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import { nuevoPagoOrdenServicio } from "../../API/ApiPagosOrdenes";
import { agregarJardinAPanteon } from "../../API/ApiJardines";
import {
  AddEspaciosAPanteon,
  AddPanteon,
  PostAddPersonaAEspaciosAPI,
  PostAsignarContratoByIdEspacioAPI,
} from "../../API/ApiPanteones";
import { getAllEspacios } from "../../API/ApiEspacios";
import { Autocomplete } from "@mui/material";
import { searchContracts } from "../../API/ApiContratos";
import Checkbox from "@mui/material/Checkbox";

const validationSchema = yup.object({
  id: yup.string("Ingresa").required("Requerido"),
  idcontrato: yup.string("Ingresa").required("Requerido"),
  comentarios: yup.string("Ingresa").notRequired(),
  fechaAsignacion: yup.string("Ingresa").required(),
  fechaTemporalidad: yup.string("Ingresa").notRequired(),
  tipoTiempo: yup.string("Ingresa").required(),
});

export const AddAsignarEspacioAContratoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);

  const [municipios, setMunicipios] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      id: props.crudAction.data.id,
      idcontrato:
        props.crudAction.action == "cambiarFechaEspacioAContrato"
          ? props.crudAction.data.idcontrato
          : "",
      comentarios: "",
      fechaAsignacion:
        props.crudAction.data.fechaAsignacion !== undefined
          ? props.crudAction.data.fechaAsignacion
          : DateTime.local().toISODate(),
      tipoTiempo: 1,
      fechaTemporalidad: DateTime.local().toISODate(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.tipoTiempo == 1) {
        delete values.fechaTemporalidad;
      } else {
      }

      setIsDisabled(true);
      PostAsignarContratoByIdEspacioAPI(user, values).then((resultado) => {
        props.setCrudAction({ action: null, data: null });
        toast.success("Contrato agregado a espacio exitosamente");
        setIsDisabled(false);
      });
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-4 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Agregar espacio a un contrato</h6>
                  <div className="alert alert-info me-2 w-100" role="alert">
                    <small className="text-warning-emphasis">
                      Asignarás un contrato al espacio:{" "}
                      {props.crudAction.data.espacio}{" "}
                      {props.crudAction.data.letra}{" "}
                      {props.crudAction.data.consecutivo}
                    </small>
                  </div>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Procesando información...
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="id"
                    name="id"
                    label="Identificador de espacio"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.id}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.id && Boolean(formik.errors.id)}
                    helperText={formik.touched.id && formik.errors.id}
                    disabled={true}
                    sx={{ display: "none" }}
                  />
                </div>

                <div>
                  {props.crudAction.action == "cambiarFechaEspacioAContrato" ? (
                    <>Actualizarás la fecha del espacio seleccionado</>
                  ) : null}
                </div>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={
                    props.crudAction.action == "cambiarFechaEspacioAContrato"
                      ? { display: "none" }
                      : null
                  }
                  options={finalData}
                  getOptionLabel={(option) =>
                    `${option.serie} ${option.noContrato} -${option.titular}`
                  }
                  onInputChange={(event, option) => {
                    searchContracts(user, event.target.value).then(
                      (resultado) => {
                        if (resultado.status == 200) {
                          setFinalData(resultado.data);
                        } else {
                          setFinalData([]);
                        }
                      }
                    );
                  }}
                  onChange={(event, option) => {
                    formik.setFieldValue("idcontrato", option.id);
                    setIsDisabled(true);
                  }}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Buscar Número de Contrato" />
                  )}
                />

                <div className="col mt-4">
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    id="fechaAsignacion"
                    name="fechaAsignacion"
                    label="Fecha Asignación"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaAsignacion}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={
                      formik.touched.fechaAsignacion &&
                      Boolean(formik.errors.fechaAsignacion)
                    }
                    helperText={
                      formik.touched.fechaAsignacion &&
                      formik.errors.fechaAsignacion
                    }
                  />
                </div>

                <div className="col mt-4">
                  <TextField
                    size="small"
                    sx={{ width: "100%" }}
                    id="comentarios"
                    name="comentarios"
                    label="comentarios"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.comentarios}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={
                      formik.touched.comentarios &&
                      Boolean(formik.errors.comentarios)
                    }
                    helperText={
                      formik.touched.comentarios && formik.errors.comentarios
                    }
                  />
                </div>

                <div className="col mt-4 mb-2">
                  <FormControlLabel
                    value="end"
                    control={
                      <Checkbox
                        checked={formik.values.tipoTiempo == 1 ? false : true}
                        onChange={(e) => {
                          let resultado = e.target.checked;

                          if (resultado == false) {
                            formik.setFieldValue("tipoTiempo", 1);
                          } else {
                            formik.setFieldValue("tipoTiempo", 2);
                          }
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Agregar Temporalidad"
                    labelPlacement="end"
                  />
                </div>

                <div className="col mt-2 mb-4">
                  <small>
                    Si este valor no se encuentra seleccionado se utilizará{" "}
                    <strong>perpetuidad</strong>
                  </small>
                </div>

                {formik.values.tipoTiempo == 2 ? (
                  <div className="col mt-1">
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      id="fechaTemporalidad"
                      name="fechaTemporalidad"
                      label="Fecha Temporalidad"
                      type={"date"}
                      className="mb-3"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "fechaTemporalidad",
                          e.target.value
                        );
                      }}
                      value={formik.values.fechaTemporalidad}
                      variant="outlined"
                      error={
                        formik.touched.fechaTemporalidad &&
                        Boolean(formik.errors.fechaTemporalidad)
                      }
                      helperText={
                        formik.touched.fechaTemporalidad &&
                        formik.errors.fechaTemporalidad
                      }
                    />
                  </div>
                ) : null}

                <div className="col mt-4">
                  <button
                    type="submit"
                    className="btn btn-sm btn-success w-100"
                  >
                    Asignar
                  </button>
                </div>

                <div className="col mt-5">
                  <small style={{ opacity: 0.5, fontWeight: "bold" }}>
                    Doble click para ejecutar la acción
                  </small>
                  <hr />
                  <br />
                  <div className="alert alert-info" role="alert">
                    <strong>Información: </strong>Se procederá a desvincular el
                    espacio,{" "}
                    <strong>
                      los pagos realizados a este espacio mantendrán su
                      registro, los finados contenidos en el espacio pasarán al
                      registro historico.
                    </strong>
                  </div>
                  <div
                    className="btn btn-sm btn-warning"
                    onDoubleClick={() => {
                      PostAsignarContratoByIdEspacioAPI(user, {
                        idcontrato: "0",
                        id: props.crudAction.data.id,
                      }).then((resultado) => {
                        props.setCrudAction({ action: null, data: null });
                        toast.success("Contrato desvinculado");
                        setIsDisabled(false);
                      });
                    }}
                  >
                    Desvincular Contrato de espacio
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
