import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import {
  getBitacoraDeContrato,
  postComentarioBitacoraDeContrato,
  postComentarioBitacoraDeOrden,
} from "../../API/ApiBitacora";
import { getUsers } from "../../API/ApiUsers";
import { AuthContext } from "../../auth/authContext";
import classNames from "./InputInsertarComentarioComponent.module.css";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";
const InputInsertarComentarioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [nombresMencionados, setNombresMencionados] = React.useState([]);

  const [contador, setContador] = React.useState(0);
  const [finalDataUsuarios, setFinalDataUsuarios] = React.useState([]);
  const [finalDataBitacora, setFinalDataBitacora] = React.useState([]);

  const [disabled, setDisabled] = React.useState(false);

  const mentionsInputRef = React.useRef(null);

  React.useEffect(() => {
    getUsers(user).then((resultado) => {
      if (resultado.status == 200) {
        let nuevoArray = [];

        for (const iterator of resultado.data) {
          let nuevoObjeto = {};
          nuevoObjeto.id = iterator.id;
          nuevoObjeto.display = iterator.nombre;

          nuevoArray.push(nuevoObjeto);
        }

        setFinalDataUsuarios(nuevoArray);
      } else {
        setFinalDataUsuarios([]);
      }
    });
  }, []);

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    setValue(newValue);

    const nuevosNombres = mentions.map((mention) => {
      return { id: mention.id, display: mention.display };
    });
    setNombresMencionados(nuevosNombres);
  };

  const handleSubmit = (event) => {
    event.preventDefault();


    if(disabled == true)
      {
        return;
      }
  

    let resultadoNombresMencionados = nombresMencionados.map((nombre) => {
      return parseInt(nombre.id);
    });

    let arrayToSend = {};

    

    setDisabled(true);

    if (value == "") {
      toast.error("Debes ingresar un comentario");
      setDisabled(false);
      return;
    }

    if (props.tipo == "Orden de Servicio") {
      arrayToSend = {
        idorden: props.contrato,
        comentario: value,
        etiquetas: resultadoNombresMencionados, //los ids de los usuarios a quienes queremos etiquetar
      };

      postComentarioBitacoraDeOrden(user, arrayToSend).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Comentario agregado");
          setFinalDataBitacora([]);
          setNombresMencionados([]);
          setValue("");
          props.setContador(props.contador + 1);
        } else {
          toast.error("Ha ocurrido un error al agregar el comentario");
          props.setContador(props.contador + 1);
        }

        // Quitar el focus del MentionsInput después de un retraso
        setTimeout(() => {
          if (mentionsInputRef.current) {
            mentionsInputRef.current.blur();
            setDisabled(false);
          }
        }, 1000); // 100 milisegundos de retraso
      });
    }

    if (props.tipo == "Contrato") {
      arrayToSend = {
        idcontrato: props.contrato,
        comentario: value,
        etiquetas: resultadoNombresMencionados, //los ids de los usuarios a quienes queremos etiquetar
      };

      postComentarioBitacoraDeContrato(user, arrayToSend).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Comentario agregado");
          setFinalDataBitacora([]);
          setNombresMencionados([]);
          setValue("");

          props.setContador(props.contador + 1);

          setDisabled(false);
        } else {
          toast.error("Ha ocurrido un error al agregar el comentario");
          props.setContador(props.contador + 1);
          setDisabled(false);
        }
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey && disabled == false) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="w-100">
          <div className=" container-fluid g-0">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <small>
                    Ingresa el comentario, puedes etiquetar personas con el
                    caracter @
                  </small>
                  <MentionsInput
                    value={value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    classNames={classNames}
                    inputRef={mentionsInputRef}
                  >
                    <Mention
                      trigger="@"
                      disabled={disabled}
                      className={classNames.mentions__mention}
                      data={finalDataUsuarios} // Aquí debes pasar tu lista de usuarios
                      renderSuggestion={(
                        suggestion,
                        search,
                        highlightedDisplay
                      ) => <div>{highlightedDisplay}</div>}
                    />
                  </MentionsInput>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12" style={{ textAlign: "right" }}>
                        <button
                          type="submit"
                          disabled={disabled}
                          className="btn btn-primary"
                        >
                          Enviar <SendIcon fontSize="inherit" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default InputInsertarComentarioComponent;
