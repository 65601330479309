import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { newContract } from "../../API/ApiContratos";
import { crearEmpleado } from "../../API/ApiEmpleados";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa Nombre").required("Nombre requerido"),
  apaterno: yup
    .string("Ingresa Apellido Parterno")
    .required("Apellido Paterno requerido"),
  amaterno: yup
    .string("Ingresa Apellido Materno")
    .required("Apellido Materno requerido"),
  fechaIngreso: yup.string("Ingresa fecha Ingreso").required("Fecha requerida"),
  fechaNacimiento: yup
    .string("Ingresa fecha Nacimiento")
    .required("Fecha requerida"),
  hComisionVenta: yup
    .string("Ingresa Comisión de Venta")
    .required("Comisión requerida"),
  salario: yup.string("Ingresa Salario").required("Salario requerido"),
  domicilio: yup.string("Ingresa domicilio").required("Domicilio requerido"),
  curp: yup.string("Ingresa CURP").required("CURP requerido"),
  rfc: yup.string("Ingresa RFC").required("RFC requerido"),
  nss: yup.string("Ingresa NSS").required("NSS requerido"),
  contactoFamiliar: yup
    .string("Ingresa Contacto Familiar")
    .required("Contacto requerido"),
  parentesco: yup.string("Ingresa Parentesco").required("Parentesco requerido"),
  telefono: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
  telefonoFamiliar: yup
    .string("Ingresa teléfono")
    .matches(/^[0-9]{5}[0-9]{5}$/, "Número Inválido")
    .required("Nombre requerido"),
});

export const AregarEmpleadosCrudComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apaterno: "",
      amaterno: "",
      fechaIngreso: "",
      idsucursal: "1",
      iddepartamento: "1",
      idpuesto: "1",
      idestado: "",
      idciudad: "",
      hComisionVenta: "",
      salario: "",
      domicilio: "",
      fechaNacimiento: "",
      curp: "",
      rfc: "",
      nss: "",
      hComisionVenta: "",
      telefono: "",
      contactoFamiliar: "",
      parentesco: "",
      telefonoFamiliar: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
       
      setIsDisabled(true);
      crearEmpleado(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Hemos agregado al empleado.");

          props.setCrudAction({ action: null, data: null });
        } else {
          toast.error(resultado.message);
          setIsDisabled(false);
        }
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-9 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>Agregar {props.nombre}</h6>
                <small className="text-muted">
                  Ingresa los datos del empleado, todos los datos son requeridos
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">Datos principales de contrato.</div>
                </div>
                <div className="row row-cols-md-3">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="nombre"
                      name="nombre"
                      label="Nombre"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.nombre}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.nombre && Boolean(formik.errors.nombre)
                      }
                      helperText={formik.touched.nombre && formik.errors.nombre}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="apaterno"
                      name="apaterno"
                      label="Paterno"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.apaterno}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.apaterno &&
                        Boolean(formik.errors.apaterno)
                      }
                      helperText={
                        formik.touched.apaterno && formik.errors.apaterno
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="amaterno"
                      name="amaterno"
                      label="Materno"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.amaterno}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.amaterno &&
                        Boolean(formik.errors.amaterno)
                      }
                      helperText={
                        formik.touched.amaterno && formik.errors.amaterno
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-4">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="fechaIngreso"
                      name="fechaIngreso"
                      label="fechaIngreso"
                      type="date"
                      className="mb-3"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.fechaIngreso}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.fechaIngreso &&
                        Boolean(formik.errors.fechaIngreso)
                      }
                      helperText={
                        formik.touched.fechaIngreso &&
                        formik.errors.fechaIngreso
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col d-none">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink
                      >
                        Sucursal
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idsucursal}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        inputProps={{
                          name: "idsucursal",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"1"}>1</option>
                        <option value={"2"}>2</option>
                        <option value={"3"}>3</option>
                        <option value={"4"}>4</option>
                        <option value={"5"}>5</option>
                        <option value={"6"}>6</option>
                        <option value={"7"}>7</option>
                        <option value={"8"}>8</option>
                        <option value={"9"}>9</option>
                        <option value={"10"}>10</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col d-none">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink
                      >
                        Departamento
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.iddepartamento}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"1"}
                        inputProps={{
                          name: "iddepartamento",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"1"}>Departamento 1</option>
                        <option value={"2"}>Departamento 2</option>
                        <option value={"3"}>Departamento 3</option>
                        <option value={"4"}>Departamento 4</option>
                        <option value={"5"}>Departamento 5</option>
                        <option value={"6"}>Departamento 6</option>
                        <option value={"7"}>Departamento 7</option>
                        <option value={"8"}>Departamento 8</option>
                        <option value={"9"}>Departamento 9</option>
                        <option value={"10"}>Departamento 10</option>
                        <option value={"11"}>Departamento 11</option>
                        <option value={"12"}>Departamento 12</option>
                        <option value={"13"}>Departamento 13</option>
                        <option value={"14"}>Departamento 14</option>
                        <option value={"15"}>Departamento 15</option>
                        <option value={"16"}>Departamento 16</option>
                        <option value={"17"}>Departamento 17</option>
                        <option value={"18"}>Departamento 18</option>
                        <option value={"19"}>Departamento 19</option>
                        <option value={"20"}>Departamento 20</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col d-none">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Puesto
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idpuesto}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"1"}
                        inputProps={{
                          name: "idpuesto",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"1"}>Puesto 1</option>
                        <option value={"2"}>Puesto 2</option>
                        <option value={"3"}>Puesto 3</option>
                        <option value={"4"}>Puesto 4</option>
                        <option value={"5"}>Puesto 5</option>
                        <option value={"6"}>Puesto 6</option>
                        <option value={"7"}>Puesto 7</option>
                        <option value={"8"}>Puesto 8</option>
                        <option value={"9"}>Puesto 9</option>
                        <option value={"10"}>Puesto 10</option>
                        <option value={"11"}>Puesto 11</option>
                        <option value={"12"}>Puesto 12</option>
                        <option value={"13"}>Puesto 13</option>
                        <option value={"14"}>Puesto 14</option>
                        <option value={"15"}>Puesto 15</option>
                        <option value={"16"}>Puesto 16</option>
                        <option value={"17"}>Puesto 17</option>
                        <option value={"18"}>Puesto 18</option>
                        <option value={"19"}>Puesto 19</option>
                        <option value={"20"}>Puesto 29</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="container-fluid g-0">
                <div className="row row-cols-md-4">
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Estado
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idestado}
                        disabled={isDisabled}
                        defaultValue={30}
                        inputProps={{
                          name: "idestado",
                          id: "uncontrolled-native",
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                          getMunicipios(user, e.target.value).then(
                            (resultado) => {
                              if ((resultado.status = 200)) {
                                setMunicipios(resultado.data);
                              } else {
                                setMunicipios([]);
                              }
                            }
                          );
                        }}
                      >
                        <option aria-label="Seleccionar Estado">
                          Seleccionar Estado
                        </option>

                        {estados.map((estado, index) => (
                          <option key={index} value={estado.idestado}>
                            {estado.nombreEstado}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Municipio
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.idciudad}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={30}
                        inputProps={{
                          name: "idciudad",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option aria-label="Seleccionar Ciudad">
                          Seleccionar Ciudad
                        </option>

                        {municipios.map((ciudad, index) => (
                          <option key={index} value={ciudad.idciudad}>
                            {ciudad.nombreCiudad}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="domicilio"
                      name="domicilio"
                      label="Domicilio"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.domicilio}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.domicilio &&
                        Boolean(formik.errors.domicilio)
                      }
                      helperText={
                        formik.touched.domicilio && formik.errors.domicilio
                      }
                      disabled={isDisabled}
                    />
                  </div>

                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="fechaNacimiento"
                      name="fechaNacimiento"
                      label="Fecha Nacimiento"
                      type="date"
                      className="mb-3"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.fechaNacimiento}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.fechaNacimiento &&
                        Boolean(formik.errors.fechaNacimiento)
                      }
                      helperText={
                        formik.touched.fechaNacimiento &&
                        formik.errors.fechaNacimiento
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-3">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="curp"
                      name="curp"
                      label="CURP"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.curp}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.curp && Boolean(formik.errors.curp)}
                      helperText={formik.touched.curp && formik.errors.curp}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="rfc"
                      name="rfc"
                      label="RFC"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.rfc}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                      helperText={formik.touched.rfc && formik.errors.rfc}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="nss"
                      name="nss"
                      label="Numero de Seguridad Social"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.nss}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={formik.touched.nss && Boolean(formik.errors.nss)}
                      helperText={formik.touched.nss && formik.errors.nss}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-4">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="telefono"
                      name="telefono"
                      label="Teléfono"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.telefono}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.telefono &&
                        Boolean(formik.errors.telefono)
                      }
                      helperText={
                        formik.touched.telefono && formik.errors.telefono
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="contactoFamiliar"
                      name="contactoFamiliar"
                      label="Contacto Familiar"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.contactoFamiliar}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.contactoFamiliar &&
                        Boolean(formik.errors.contactoFamiliar)
                      }
                      helperText={
                        formik.touched.contactoFamiliar &&
                        formik.errors.contactoFamiliar
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="parentesco"
                      name="parentesco"
                      label="Parentesco"
                      type={"text"}
                      className="mb-3"
                      value={formik.values.parentesco}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.parentesco &&
                        Boolean(formik.errors.parentesco)
                      }
                      helperText={
                        formik.touched.parentesco && formik.errors.parentesco
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="telefonoFamiliar"
                      name="telefonoFamiliar"
                      label="telefonoFamiliar"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.telefonoFamiliar}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.telefonoFamiliar &&
                        Boolean(formik.errors.telefonoFamiliar)
                      }
                      helperText={
                        formik.touched.telefonoFamiliar &&
                        formik.errors.telefonoFamiliar
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="row row-cols-md-2">
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="hComisionVenta"
                      name="hComisionVenta"
                      label="Comisión Venta %"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.hComisionVenta}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.hComisionVenta &&
                        Boolean(formik.errors.hComisionVenta)
                      }
                      helperText={
                        formik.touched.hComisionVenta &&
                        formik.errors.hComisionVenta
                      }
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="salario"
                      name="salario"
                      label="Salario $ MXN"
                      type={"number"}
                      className="mb-3"
                      value={formik.values.salario}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.salario && Boolean(formik.errors.salario)
                      }
                      helperText={
                        formik.touched.salario && formik.errors.salario
                      }
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>

              <button
                className="my-4 btn btn-primary w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Agregar Empleado
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
