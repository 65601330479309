import React from "react";
import SeleccionarTipoPagoComponent from "./../../Components/SeleccionarTipoPagoComponent/SeleccionarTipoPagoComponent";
import UltimosPagosRegistradosComponent from "../../Components/UltimosPagosRegistradosComponent/UltimosPagosRegistradosComponent";
import { AddPaymentsQuickComponent } from "./../../Components/AddPaymentsQuickComponent/AddPaymentsQuickComponent";
import AddPaymentOrdenServicioComponent from "./../../Components/AddPaymentOrdenServicioComponent/AddPaymentOrdenServicioComponent";
import Button from "@mui/material/Button";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { MantenimientoScreenComponent } from "./../MantenimientoScreenComponent/MantenimientoScreenComponent";
import { useLocation } from "react-router-dom";
const IngresarPagosScreenComponent = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const parametro = queryParams.get("tipopago");


  const [finalDataInicio, setFinalDataInicio] = React.useState([
    {
      id: 1,
      nombre: "Contrato",
      descripcion: "Agrega un pago a un contrato existente",
    },
    {
      id: 2,
      nombre: "Órden de servicio",
      descripcion: "Agrega un pago a una órden de servicio existente",
    },
    {
      id: 3,
      nombre: "Mantenimiento",
      descripcion: "Agrega un pago de mantenimiento a un espacio",
    },
  ]);

  const [pagoSeleccionado, setPagoSeleccionado] = React.useState(0);

  React.useEffect(() => {
    if (parametro == "mantenimiento") {
      setPagoSeleccionado(3);
    }
  }, []);

  return (
    <>
      {pagoSeleccionado === 0 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9">
              <SeleccionarTipoPagoComponent
                finalDataInicio={finalDataInicio}
                setPagoSeleccionado={setPagoSeleccionado}
              />
            </div>
            <div className="col-sm-3">
              <UltimosPagosRegistradosComponent
                finalDataInicio={finalDataInicio}
              />
            </div>
          </div>
        </div>
      ) : null}

      {pagoSeleccionado === 1 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9">
              <Button
                variant="contained"
                onClick={() => {
                  setPagoSeleccionado(0);
                }}
              >
                {" "}
                <KeyboardArrowLeftIcon /> Regresar
              </Button>
              <br />
              <br />
              <AddPaymentsQuickComponent ocultarPagos={true} />
            </div>
            <div className="col-sm-3">
              <UltimosPagosRegistradosComponent
                finalDataInicio={finalDataInicio}
              />
            </div>
          </div>
        </div>
      ) : null}

      {pagoSeleccionado === 2 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9">
              <Button
                variant="contained"
                onClick={() => {
                  setPagoSeleccionado(0);
                }}
              >
                {" "}
                <KeyboardArrowLeftIcon /> Regresar
              </Button>
              <br />
              <br />
              <AddPaymentOrdenServicioComponent />
            </div>
            <div className="col-sm-3">
              <UltimosPagosRegistradosComponent
                finalDataInicio={finalDataInicio}
              />
            </div>
          </div>
        </div>
      ) : null}
      {pagoSeleccionado === 3 ? (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-9">
              <Button
                variant="contained"
                onClick={() => {
                  setPagoSeleccionado(0);
                }}
              >
                {" "}
                <KeyboardArrowLeftIcon /> Regresar
              </Button>
              <br />
              <br />
              <MantenimientoScreenComponent />
            </div>
            <div className="col-sm-3">
              <UltimosPagosRegistradosComponent
                finalDataInicio={finalDataInicio}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default IngresarPagosScreenComponent;
