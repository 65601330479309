import React from "react";
import { AuthContext } from "../../auth/authContext";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import { getJardinesPanteon } from "./../../API/ApiJardines";
import { LoadingSkeletonComponent } from "./../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
import { AddJardinAPanteonComponent } from "../../Components/AddJardinAPanteonComponent/AddJardinAPanteonComponent";
import { EditJardinAPanteonComponent } from "../../Components/EditJardinAPanteonComponent/EditJardinAPanteonComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { TitleScreenComponent } from "../../Components/TitleScreenComponent/TitleScreenComponent";

const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const JardinesScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);

  let { panteon } = useParams();

  panteon = JSON.parse(panteon);
   

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [agregarPagoOrdenServicio, setAgregarPagoOrdenServicio] =
    React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "c84c9171-849f-42a7-8e5c-296107827bf1" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
            if (
              permiso.guidFuncion == "a94e5a0d-d8ff-4015-9202-e15676c8efb7" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getJardinesPanteon(user, panteon.id).then((resultado) => {
      if (resultado.status == 200) {

        
        setFinalData(resultado.data);
      }

      setLoading(false);
    });
  }, [crudAction]);



  return (
    <>
      {panteon.nombre === "MAUSOLEO" ? (
        <TitleScreenComponent titulo="Mausoleos" />
      ) : (
        <TitleScreenComponent titulo="Jardines de Panteon" />
      )}

      {permisoParaAgregar == true ? (
        <div className="container-fluid mb-2">
          <div className="row row-cols-1">
            <div className="col text-end">
              <button
                className="btn btn-sm btn-success"
                onClick={() => {
                  setCrudAction({
                    action: "add",
                    data: null,
                  });
                }}
              >
                Agregar a {panteon.nombre}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {loading == true ? (
        <LoadingSkeletonComponent />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-2">
              {finalData.map((jardin, index) => (
                <div key={index} className="col mb-3">
                  <div className="card">
                    <div className="card-body">{jardin.nombreJardin}</div>
                    <div className="card-footer">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {panteon.nombre === "MAUSOLEO" ? (
                          <>
                            <button
                              className="btn btn-primary btn-success btn-sm"
                              type="button"
                              onClick={() => {
                                window.open(
                                  "/panteones/ubicacion/mausoleo/" +
                                    encodeURI(JSON.stringify(jardin)),
                                  "_self"
                                );
                              }}
                            >
                            Ver Espacios
                            </button>
                          </>
                        ) : (
                          <>
                            {permisoParaEditar == true ? (
                              <button
                                className="btn btn-sm btn-primary"
                                type="button"
                                onClick={() => {
                                  setCrudAction({
                                    action: "edit",
                                    data: jardin,
                                  });
                                }}
                              >
                                Editar Jardín
                              </button>
                            ) : null}

                            <button
                              className="btn btn-primary btn-success btn-sm"
                              type="button"
                              onClick={() => {
                                window.open(
                                  "/panteones/jardin/uniplex/" +
                                    encodeURI(JSON.stringify(jardin)),
                                  "_self"
                                );
                              }}
                            >
                              Uniplex
                            </button>
                            <button
                              className="btn btn-primary btn-success btn-sm"
                              type="button"
                              onClick={() => {
                                window.open(
                                  "/panteones/jardin/duplex/" +
                                    encodeURI(JSON.stringify(jardin)),
                                  "_self"
                                );
                              }}
                            >
                              Duplex
                            </button>
                            <button
                              className="btn btn-primary btn-success btn-sm"
                              type="button"
                              onClick={() => {
                                window.open(
                                  "/panteones/jardin/murales/" +
                                    encodeURI(JSON.stringify(jardin)),
                                  "_self"
                                );
                              }}
                            >
                              Murales
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {crudAction.action == "add" ? (
        <AddJardinAPanteonComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          panteon={panteon.id}
        />
      ) : null}
      {crudAction.action == "edit" ? (
        <EditJardinAPanteonComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
          panteon={panteon.id}
        />
      ) : null}
    </>
  );
};
