import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";

import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import { LoadingComponent } from "../LoadingComponent/LoadingComponent";
import { nuevaCapillaByIdPanteon } from "../../API/ApiCapillas";
import {
  checkPermisosAEditarPorUsuario,
  setUnsetPermisoFuncionUsuario,
  setUnsetPermisoVistaUsuario,
} from "../../API/ApiPermisos";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const PermisosUsuarioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [finalData, setFinalData] = React.useState([]);
  const [actualizarData, setActualizarData] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  React.useEffect(() => {
    let ready = false;
    setIsDisabled(true);
    checkPermisosAEditarPorUsuario(user, props.crudAction.data.id).then(
      (resultado) => {
        if (resultado.status == 200) {
          setFinalData(resultado.data);
        }
        setIsDisabled(false);
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let ready = false;
    setIsDisabled(true);
    checkPermisosAEditarPorUsuario(user, props.crudAction.data.id).then(
      (resultado) => {
        if (resultado.status == 200) {
          
          setFinalData(resultado.data);
        }
        setIsDisabled(false);
      }
    );

    return () => {
      ready = true;
    };
  }, [actualizarData]);

  return (
    <>
      <div className="crudDivComponent col-md-4 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Permisos por usuario</h6>
                  <small className="text-muted">
                    Selecciona los permisos a otorgar al usuario
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            {finalData.map((permisoPantalla, index) => (
              <div className="border-bottom border-top" key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isDisabled}
                      checked={permisoPantalla.allowed == 1 ? true : false}
                      onChange={() => {
                        toast.success("Todo bien");
                        setUnsetPermisoVistaUsuario(
                          user,
                          props.crudAction.data.id,
                          permisoPantalla.guid
                        ).then((resultado) => {
                          if (resultado.status == 200) {
                            let actualizar = actualizarData + 1;
                            setActualizarData(actualizar);
                          }
                        });
                      }}
                    />
                  }
                  label={permisoPantalla.nombreVista}
                />
                <div className="ps-3">
                  {permisoPantalla.funciones.map((funcion, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          disabled={isDisabled}
                          checked={funcion.allowed == 1 ? true : false}
                          onChange={() => {
                            setUnsetPermisoFuncionUsuario(
                              user,
                              props.crudAction.data.id,
                              funcion.guid
                            ).then((resultado) => {
                              if (resultado.status == 200) {
                                let actualizar = actualizarData + 1;
                                setActualizarData(actualizar);
                              }
                            });
                          }}
                        />
                      }
                      label={funcion.nombreFuncion}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
