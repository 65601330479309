import React from "react";
import { AuthContext } from "../../auth/authContext";
import { createBrowserHistory } from "history";

import styles from "./EmpleadosScreenComponent.module.css";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { getAllEmpleados } from "../../API/ApiEmpleados";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { AregarEmpleadosCrudComponent } from "../../Components/EmpleadosCrudComponent/AregarEmpleadosCrudComponent";
import { EditarEmpleadosCrudComponent } from "../../Components/EmpleadosCrudComponent/EditarEmpleadosCrudComponent";
import { EliminarEmpleadosCrudComponent } from "../../Components/EmpleadosCrudComponent/EliminarEmpleadosCrudComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";

const guidVista = "03b35603-5099-4f9d-92d7-08be6119f007";

export const EmpleadosScreenComponent = (props) => {
  const customHistory = createBrowserHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [isloadingData, setIsLoadingData] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoVer, setPermisoVer] = React.useState(false);
  const [permisoAgregar, setPermisoAgregar] = React.useState(false);
  const [permisoEditar, setPermisoEditar] = React.useState(false);
  const [permisoEliminar, setPermisoEliminar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "8c1d8568-0b28-4391-8e83-aee0efb3d3dd" &&
              permiso.allowed == 1
            ) {
              setPermisoVer(true);
            }
            if (
              permiso.guidFuncion == "81fce5a0-c6db-4286-ae64-c4ba2cf40bfc" &&
              permiso.allowed == 1
            ) {
              setPermisoAgregar(true);
            }
            if (
              permiso.guidFuncion == "00670af6-1d99-482e-96fa-2f6c0f43bb0a" &&
              permiso.allowed == 1
            ) {
              setPermisoEditar(true);
            }
            if (
              permiso.guidFuncion == "6632a5cc-9168-4764-94f4-3f877ce3a300" &&
              permiso.allowed == 1
            ) {
              setPermisoEliminar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let ready = false;
    getAllEmpleados(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setIsLoadingData(false);
      } else {
        setFinalData([]);
        setIsLoadingData(false);
      }
    });
    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let ready = false;
    getAllEmpleados(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setIsLoadingData(false);
      } else {
        setFinalData([]);
        setIsLoadingData(false);
      }
    });
    return () => {
      ready = true;
    };
  }, [crudAction]);

  return (
    <>
      {permisoVer == true ? (
        <>
          {permisoAgregar == true ? (
            <div className="container-fluid mb-3">
              <div className="row">
                <div className="col-12 text-end">
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      setCrudAction({ data: null, action: "add" });
                    }}
                  >
                    Agregar Empleado
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {isloadingData == false ? (
            <>
              {finalData.map((empleado, index) => (
                <div className="card mb-2" key={index}>
                  <div className="card-body">
                    {empleado.nombre} {empleado.apaterno} {empleado.amaterno}
                  </div>
                  <div className="card-footer">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      {permisoEliminar == true ? (
                        <button
                          className="btn btn-sm btn-danger"
                          type="button"
                          onClick={() => {
                            setCrudAction({ data: empleado, action: "delete" });
                          }}
                        >
                          Eliminar
                        </button>
                      ) : null}

                      {permisoEditar == true ? (
                        <button
                          className="btn btn-sm btn-warning"
                          type="button"
                          onClick={() => {
                            setCrudAction({ data: empleado, action: "edit" });
                          }}
                        >
                          Editar
                        </button>
                      ) : null}

                      {permisoVer == true ? (
                        <button
                          className="btn btn-sm btn-success "
                          type="button"
                          onClick={() => {
                            setCrudAction({ data: empleado, action: "see" });
                          }}
                        >
                          Ver
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <LoadingComponent />
          )}
          {crudAction.action == "add" ? (
            <>
              <AregarEmpleadosCrudComponent
                setCrudAction={setCrudAction}
                crudAction={crudAction}
                nombre={"empleado"}
              />
            </>
          ) : null}
          {crudAction.action == "edit" ? (
            <>
              <EditarEmpleadosCrudComponent
                setCrudAction={setCrudAction}
                crudAction={crudAction}
                nombre={"empleado"}
              />
            </>
          ) : null}
          {crudAction.action == "delete" ? (
            <>
              <EliminarEmpleadosCrudComponent
                setCrudAction={setCrudAction}
                crudAction={crudAction}
                nombre={"empleado"}
              />
            </>
          ) : null}
          {crudAction.action == "see" ? (
            <>
              <EliminarEmpleadosCrudComponent
                setCrudAction={setCrudAction}
                crudAction={crudAction}
                nombre={"empleado"}
                deleteButton={false}
              />
            </>
          ) : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
