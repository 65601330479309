import React from "react";

import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import Alert from "@mui/material/Alert";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { BuscadorDeContratosParaEliminacionComponent } from "../../Components/BuscadorDeContratosParaEliminacionComponent/BuscadorDeContratosParaEliminacionComponent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { deleteDestroyContrato } from "../../API/ApiContratos";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

const guidVista = "6ac593c2-4810-4081-bb29-e80830750a85";

export const EliminarContratosScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);

  const [eliminando, setEliminando] = React.useState(false);

  const [entiendoLosRiesgos, setEntiendoLosRiesgos] = React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);
  const [permisoReporte, setPermisoReporte] = React.useState(false);

  const [numeroContratoEncontrado, setNumeroContratoEncontrado] =
    React.useState({});

  React.useEffect(() => {
    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "28929917-12fc-489a-89d8-85fd49b64b8d" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
          }
        }
      }
    );
  }, []);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <>
          {eliminando === true ? (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress color="inherit" className="me-3" />
              Eliminando, por favor espera
            </Backdrop>
          ) : null}

          <div className="container">
            <div className="row row-cols-sm-12">
              <h1 color="error">
                <PriorityHighIcon color="error" fontSize="inherit" /> Cuidado
              </h1>
              <hr />
              <Alert severity="error">
                El uso de esta herramienta{" "}
                <strong>Eliminará definitivamente</strong> el contrato, así como
                sus <strong>pagos</strong> y toda la información relevante al
                mismo.
              </Alert>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card mt-3">
                  <div className="card-body">
                    <h3 className="mb-4">
                      1.- Selecciona el contrato que deseas eliminar
                    </h3>
                    <BuscadorDeContratosParaEliminacionComponent
                      numeroContratoEncontrado={numeroContratoEncontrado}
                      setNumeroContratoEncontrado={setNumeroContratoEncontrado}
                    />

                    {Object.entries(numeroContratoEncontrado).length ===
                    0 ? null : (
                      <FormGroup className="mt-4 mb-4">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={entiendoLosRiesgos}
                              onChange={(event) => {
                                setEntiendoLosRiesgos(event.target.checked);
                                
                              }}
                            />
                          }
                          label={`Entiendo los riesgos que puede tener esta acción y deseo eliminar el contrato con identificador en sistema:  ${numeroContratoEncontrado?.id} 
                          ${numeroContratoEncontrado?.serie} 
                          ${numeroContratoEncontrado?.noContrato} 
                          a nombre de ${numeroContratoEncontrado?.titular}`}
                        />
                      </FormGroup>
                    )}

                    {entiendoLosRiesgos === true ? (
                      <button
                        className="btn btn-danger w-100 mt-3"
                        onClick={() => {
                          setEliminando(true);
                          deleteDestroyContrato(
                            user,
                            numeroContratoEncontrado.id
                          ).then((resultado) => {
                            if (resultado.status === 200) {
                              toast.success("Se ha eliminado el contrato");
                              setEliminando(false);
                              setEntiendoLosRiesgos(false);
                              setNumeroContratoEncontrado({});
                            } else {
                              toast.error(
                                "Ha habido un error al eliminar el contrato"
                              );
                              setEliminando(false);
                            }
                          });
                        }}
                      >
                        Entiendo los riesgos y quiero proceder con la
                        eliminación.
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
