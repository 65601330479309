//export const API = "https://sistema.funerariavitanova.com:3000/vitadev/services/";

export const API = process.env.REACT_APP_API_URL_ENDPOINT;

export const NuevaAPICobranza = process.env.REACT_APP_API_URL_ENDPOINT_COBRANZA;

export const APICOBRANZA = process.env.REACT_APP_COBRANZA_API;

//export const API = "http://51.161.11.114:3000/vitadev/services/";

export const APILogin = "https://intranet.tjagto.gob.mx/api/v1/login";

export const APIImpresoraTickets = process.env.REACT_APP_API_IMPRESORA_TICKETS;
