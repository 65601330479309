import React from "react";

export const TitleScreenComponent = (props) => {
  return (
    <div className="d-print-none">
      <h5 className="text-primary">{props.titulo}</h5>
      <i>
        <small>Funeraria Vita nova</small>
      </i>
    </div>
  );
};
