import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { addContratoCancelado, newContract } from "../../API/ApiContratos";
import {
  addOrdenServicioCancelada,
  eliminarFolioDesistema,
} from "../../API/ApiOrdenesServicio";

import Checkbox from "@mui/material/Checkbox";

const validationSchema = yup.object({
  folio: yup.string("Ingresa Folio").required("Número Folio requerido"),
  razon: yup.string("Ingresa Razon").required("Razón Requerida"),
});

export const CancelarFolioOrdenServicioComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  const [folioEliminar, setFolioEliminar] = React.useState("");

  const [checked, setChecked] = React.useState(false);

  const [isDisabledEliminar, setIsDisabledEliminar] = React.useState(false);

  React.useEffect(() => {
    if (checked == true) {
      setIsDisabledEliminar(false);
    } else {
      setIsDisabledEliminar(true);
    }
  }, [checked]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  React.useEffect(() => {
    let listo = false;

    getEstados(user).then((resultado) => {
      if (resultado.status == 200) {
        setEstados(resultado.data);
      } else {
        setEstados([]);
      }
    });

    return () => {
      listo = true;
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      folio: "",
      razon: "",
      serie: "C",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      values.folio = values.serie + values.folio.toString();
      addOrdenServicioCancelada(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Orden de servicio cancelada Correctamente");
          props.setCrudAction({ action: null, data: null });
        }
        setIsDisabled(false);
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-5">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6> {props.nombre}</h6>
                <small className="text-muted">
                  Ingresa los datos del folio a Cancelar.
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <form onSubmit={formik.handleSubmit} className="">
              <div className="container-fluid g-0">
                <div className="row">
                  <div className="col mb-3">
                    Datos de orden de servicio a cancelar.
                  </div>
                </div>

                <div className="row row-cols-md-2">
                  <div className="col">
                    <FormControl fullWidth>
                      <InputLabel
                        variant="standard"
                        shrink
                        htmlFor="uncontrolled-native"
                      >
                        Serie
                      </InputLabel>
                      <NativeSelect
                        value={formik.values.serie}
                        onChange={formik.handleChange}
                        disabled={isDisabled}
                        defaultValue={"C"}
                        inputProps={{
                          name: "serie",
                          id: "uncontrolled-native",
                        }}
                      >
                        <option value={"C"}>C</option>
                        <option value={"S"}>S</option>
                        <option value={"F"}>F</option>
                      </NativeSelect>
                    </FormControl>
                  </div>
                  <div className="col">
                    <TextField
                      size="small"
                      fullWidth
                      id="folio"
                      name="folio"
                      label="Folio"
                      type={"number"}
                      min="1"
                      className="mb-3"
                      value={formik.values.folio}
                      onChange={formik.handleChange}
                      variant="standard"
                      error={
                        formik.touched.folio && Boolean(formik.errors.folio)
                      }
                      helperText={formik.touched.folio && formik.errors.folio}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              </div>

              <div className="row row-cols-md-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="razon"
                    name="razon"
                    label="Razón de cancelación"
                    type={"text"}
                    className="mb-3"
                    value={formik.values.razon}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.razon && Boolean(formik.errors.razon)}
                    helperText={formik.touched.razon && formik.errors.razon}
                    disabled={isDisabled}
                  />
                </div>
              </div>

              <button
                className="my-4 btn btn-danger w-100 text-white"
                type="submit"
                disabled={isDisabled}
              >
                Proceder a cancelar folio
              </button>
            </form>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div className="row">
          <div className="col-12 h5">Eliminar Folio de sistema</div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextField
              size="small"
              fullWidth
              id="folio"
              name="folio"
              label="Folio"
              type={"text"}
              min="1"
              className="mb-3"
              value={folioEliminar}
              onChange={(e) => {
                setFolioEliminar(e.target.value);
              }}
              variant="standard"
            />
          </div>
          <div className="col-12">
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />{" "}
            Confirmar eliminación
          </div>
          <div className="col-12">
            <button
              className="my-4 btn btn-danger w-100 text-white"
              type="submit"
              disabled={isDisabledEliminar}
              onClick={(e) => {
                eliminarFolioDesistema(user, folioEliminar).then(
                  (resultado) => {
                    if (resultado.status == 200) {
                      toast.success("Folio eliminado correctamente");
                      window.location.reload(true);
                    }
                  }
                );
              }}
            >
              Eliminar Folio
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
