import { typesConfiguration } from "../types/configtypes";

export const configurationReducer = (state = {}, action) => {
  switch (action.type) {
    case typesConfiguration.ingresarDatos:
      return {
        ...action.payload,
      };

    case typesConfiguration.eliminarDatos:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};
