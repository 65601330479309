import React from "react";
import PropTypes from "prop-types";
import { Navigate, Redirect, Route } from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import { AsideMenuComponent } from "./../Components/AsideMenuComponent/AsideMenuComponent";
import styles from "../Components/AsideMenuComponent/AsideMenuComponent.module.css";
import { NavPrincipalComponent } from "./../Components/NavPrincipalComponent/NavPrincipalComponent";
import { ConfigurationContext } from "./../configuration/configurationContext";

export const PrivateRoute = ({ children }) => {
  const { user } = React.useContext(AuthContext);

  if (user.logged == false) {
    window.open(process.env.REACT_APP_LOGIN_URL, "_self");
  }

  const { configuration, dispatchConfiguration } =
    React.useContext(ConfigurationContext);
  return user.logged ? (
    <React.Fragment>
      <NavPrincipalComponent />
      <div
        className={`${
          configuration.menuAbierto === true
            ? styles.sideBarAbierto
            : styles.sideBarCerrado
        } d-print-none`}
      >
        <AsideMenuComponent />
      </div>
      <div
        className={`margenImpresionTop ${styles.contenidoSitio}`}
        style={
          configuration.menuAbierto === true
            ? { left: "230px" }
            : { left: "50px" }
        }
      >
        {children}
      </div>
    </React.Fragment>
  ) : null;
};
