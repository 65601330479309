import { API } from "../Constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllEspacios = async (user) => {
  let url = API + "contracts/cEspacios";

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const getAllDetallePersona = async (user, values) => {
  let url = API + "spaces/espacios/detallePersonaEspacio/" + values;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const AsignarFechaAContratoIdEspacio = async (user, values) => {
  let url = API + "contracts/setFechaAsignacion/" + values.idcontrato;

  if (
    values.idcontrato === 0 ||
    values.idcontrato === "" ||
    values.idcontrato === null
  ) {
    toast.error("El contrato no está asignado, debes asignarlo primero");
    return null;
  }

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }

    if (response.status === 409) {
      toast.error(
        "El contrato ya tiene pagos registrados, no es posible actualizar la fecha de asignación"
      );
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const agregaImagenMapaAEspacio = async (user, espacio, formData) => {
  let url = API + "spaces/espacios/agregarMapaEspacios/" + espacio;

  

  try {
    const response = await axios.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const getDetallePanteonImagenYMapa = async (user, espacio) => {
  let url = API + "spaces/getDetallePanteon/" + espacio;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};

export const updateCoordenadasEspacio = async (user, values) => {
  let url = API + "spaces/espacios/updateCoordenadas/";

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
maxBodyLength: Infinity,
      },
    });

    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    toast.error(error.response.data.message);
    console.error(error);
    return error;
  }
};
