import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { createUser } from "../../API/ApiUsers";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { ReactReduxContext } from "react-redux";
import { getEstados, getMunicipios } from "../../API/ApiEstadosMunicipios";
import { DateTime } from "luxon";
import { createNewOrder } from "../../API/ApiOrdenesServicio";
import InputAdornment from "@mui/material/InputAdornment";
import { FactCheckIcon } from "@mui/icons-material/FactCheck";
import {
  agregarServicioAOrdenDeServicio,
  getServiciosOrdenesServicio,
} from "../../API/ApiServiciosOrdenesServicio";
import {
  actualizarPagoOrdenServicio,
  eliminarPagoOrdenServicio,
  nuevoPagoOrdenServicio,
} from "../../API/ApiPagosOrdenes";
import MenuItem from "@mui/material/MenuItem";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const validationSchema = yup.object({
  noRecibo: yup
    .string("Ingresa Número Recibo")
    .required("Número Recibo requerido"),
  folio: yup.string("Ingresa Folio").required("Folio requerido"),
  fechaPago: yup.string("Ingresa Fecha Pago").required("Precio requerido"),
  abono1: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .required("Monto requerido")
    .min(1, "Solo números positivos"),
  abonoIva: yup
    .number("Valor Númerico")
    .positive("Valor Negativo")
    .min(0, "Solo números positivos"),
  recibio: yup.string("Ingresa Recibió").required("Recibió requerido"),
  letra: yup.string("Ingresa Letra").required("Letra requerido"),
});

export const DeletePagoOrdenServicioComponent = (props) => {
  console.log(props);
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isDisabledButton, setIsDisabledButton] = React.useState(false);

  const [finalDataServicios, setFinalDataServicios] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    getServiciosOrdenesServicio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalDataServicios(resultado.data);
      }
    });
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      id: props.crudAction.data.id,
      noRecibo: props.crudAction.data.noRecibo.slice(1),
      folio: props.crudAction.data.folio,
      fechaPago: props.crudAction.data.fechaPago,
      abono1: props.crudAction.data.montoPagado,
      abonoIva: props.crudAction.data.abonoIva,
      recibio: props.crudAction.data.recibio,
      letra: props.crudAction.data.folio.split("")[0],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      setIsDisabledButton(false);
      eliminarPagoOrdenServicio(user, props.crudAction.data.id).then(
        (resultado) => {
          if (resultado.status == 200) {
            toast.success("Pago eliminado exitosamente");
            props.setCrudAction({ action: null, data: null });
            window.location.reload(true);
          } else {
            setIsDisabled(false);
            setIsDisabledButton(true);
          }
        }
      );
    },
  });

  return (
    <>
      <div className="crudDivComponent col-md-3 px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-2 mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6>Eliminar pago a orden</h6>
                  <small className="text-muted">
                    Ingresa los datos para editar el pago
                  </small>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {isDisabled == true ? (
                <>
                  <Alert className="mb-3" severity="info">
                    Confirma que deseas realizar la eliminación
                  </Alert>
                </>
              ) : null}
              <hr />
            </div>
          </div>
          <div className="row">
            <form onSubmit={formik.handleSubmit} className="container-fluid">
              <div className="row row-cols-1">
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="letra"
                    name="letra"
                    label="Letra"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.letra}
                    onChange={formik.handleChange}
                    variant="standard"
                    select
                    error={formik.touched.letra && Boolean(formik.errors.letra)}
                    helperText={formik.touched.letra && formik.errors.letra}
                    disabled={isDisabled}
                  >
                    <MenuItem value={"C"}>C</MenuItem>
                    <MenuItem value={"SM"}>SM</MenuItem>
                    <MenuItem value={"P"}>P</MenuItem>
                  </TextField>

                  <TextField
                    size="small"
                    fullWidth
                    id="noRecibo"
                    name="noRecibo"
                    label="No. Recibo"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.noRecibo}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.noRecibo && Boolean(formik.errors.noRecibo)
                    }
                    helperText={
                      formik.touched.noRecibo && formik.errors.noRecibo
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="folio"
                    name="folio"
                    label="Folio"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.folio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={formik.touched.folio && Boolean(formik.errors.folio)}
                    helperText={formik.touched.folio && formik.errors.folio}
                    disabled={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="fechaPago"
                    name="fechaPago"
                    label="Fecha Pago"
                    type={"date"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.fechaPago}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.fechaPago &&
                      Boolean(formik.errors.fechaPago)
                    }
                    helperText={
                      formik.touched.fechaPago && formik.errors.fechaPago
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="abono1"
                    name="abono1"
                    label="Abono"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.abono1}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: "any" },
                    }}
                    variant="standard"
                    error={
                      formik.touched.abono1 && Boolean(formik.errors.abono1)
                    }
                    helperText={formik.touched.abono1 && formik.errors.abono1}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="abonoIva"
                    name="abonoIva"
                    label="Abono IVA"
                    type={"number"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.abonoIva}
                    onChange={formik.handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: "any" },
                    }}
                    variant="standard"
                    error={
                      formik.touched.abonoIva && Boolean(formik.errors.abonoIva)
                    }
                    helperText={
                      formik.touched.abonoIva && formik.errors.abonoIva
                    }
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    fullWidth
                    id="recibio"
                    name="recibio"
                    label="Recibió"
                    type={"text"}
                    className="mb-3"
                    InputLabelProps={{ shrink: true }}
                    value={formik.values.recibio}
                    onChange={formik.handleChange}
                    variant="standard"
                    error={
                      formik.touched.recibio && Boolean(formik.errors.recibio)
                    }
                    helperText={formik.touched.recibio && formik.errors.recibio}
                    disabled={isDisabled}
                  />
                </div>
                <div className="col">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isDisabledButton}
                          label={`Confirmo la eliminación del pago ${formik.values.letra} ${formik.values.noRecibo}`}
                          onChange={(e) => {
                            setIsDisabledButton(e.target.checked);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label={`Confirmo la eliminación del pago: ${formik.values.letra} ${formik.values.noRecibo}`}
                    ></FormControlLabel>
                  </FormGroup>
                </div>
                <div className="col">
                  <button
                    type="submit"
                    className="btn btn-sm btn-danger w-100"
                    disabled={!isDisabledButton}
                  >
                    Eliminar el pago: {formik.values.letra}
                    {formik.values.noRecibo}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
