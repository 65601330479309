import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
} from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import { AddPaymentsQuickComponent } from "../Components/AddPaymentsQuickComponent/AddPaymentsQuickComponent";
import { AsideMenuComponent } from "../Components/AsideMenuComponent/AsideMenuComponent";
import { ButtonSecretarioEstudioCuentaComponent } from "../Components/ButtonSecretarioEstudioCuentaComponent/ButtonSecretarioEstudioCuentaComponent";
import { HallSelectorComponent } from "../Components/HallSelectorComponent/HallSelectorComponent";
import { NavPrincipalComponent } from "../Components/NavPrincipalComponent/NavPrincipalComponent";
import { TitleScreenComponent } from "../Components/TitleScreenComponent/TitleScreenComponent";
import { SetWindowNameHelperComponent } from "../Helpers/SetWindowNameHelperComponent";
import { AssignScreenComponent } from "../Screens/AssignScreenComponent/AssignScreenComponent";
import { ContratosScreenComponent } from "../Screens/ContratosScreenComponent/ContratosScreenComponent";
import { DetalleContratoScreenComponent } from "../Screens/DetalleContratoScreenComponent/DetalleContratoScreenComponent";
import { LoginScreenComponent } from "../Screens/LoginScreenComponent/LoginScreenComponent";
import { MainScreenComponent } from "../Screens/MainScreenComponent/MainScreenComponent";
import { RestorePasswordComponent } from "../Screens/RestorePasswordComponent/RestorePasswordComponent";
import { UsersScreenComponent } from "../Screens/UsersScreenComponent/UsersScreenComponent";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import styles from "../Components/AsideMenuComponent/AsideMenuComponent.module.css";
import { EmpleadosScreenComponent } from "../Screens/EmpleadosScreenComponent/EmpleadosScreenComponent";
import { PerfilEmpleadoScreenComponent } from "../Screens/PerfilEmpleadoScreenComponent/PerfilEmpleadoScreenComponent";
import { ReportesScreenComponent } from "../Screens/ReportesScreenComponent/ReportesScreenComponent";
import { OrdenesServicioScreenComponent } from "../Screens/OrdenesServicioScreenComponent/OrdenesServicioScreenComponent";
import { DetalleOrdenServicioScreenComponent } from "../Screens/DetalleOrdenServicioScreenComponent/DetalleOrdenServicioScreenComponent";
import { ServiciosOrdenesServicioScreenComponent } from "../Screens/ServiciosOrdenesServicioScreenComponent/ServiciosOrdenesServicioScreenComponent";
import { PanteonesScreenComponent } from "../Screens/PanteonesScreenComponent/PanteonesScreenComponent";
import { JardinesScreenComponent } from "../Screens/JardinesScreenComponent/JardinesScreenComponent";
import { UniplexScreenComponent } from "../Screens/UniplexScreenComponent/UniplexScreenComponent";
import { CapillasDePanteonComponent } from "../Screens/CapillasDePanteonComponent/CapillasDePanteonComponent";
import { EspaciosDeCapillaComponent } from "../Screens/EspaciosDeCapillaComponent/EspaciosDeCapillaComponent";
import { DuplexScreenComponent } from "../Screens/DuplexScreenComponent/DuplexScreenComponent";
import { MuralesScreenComponent } from "../Screens/MuralesScreenComponent/MuralesScreenComponent";
import { ReporteResumenSerieScreenComponent } from "../Screens/ReporteResumenSerieScreenComponent/ReporteResumenSerieScreenComponent";
import { InicioScreenComponent } from "../Screens/InicioScreenComponent/InicioScreenComponent";
import { BusquedaDePagosPorContratoComponent } from "../Components/BusquedaDePagosPorContratoComponent/BusquedaDePagosPorContratoComponent";
import { BusquedaDePagosScreenComponent } from "../Screens/BusquedaDePagosScreenComponent/BusquedaDePagosScreenComponent";
import { ReporteCobranzaRangoFechaScreenComponent } from "../Screens/ReporteCobranzaRangoFechaScreenComponent/ReporteCobranzaRangoFechaScreenComponent";
import { ReporteCuboComisionesScreenComponent } from "../Screens/ReporteCuboComisionesScreenComponent/ReporteCuboComisionesScreenComponent";
import { ReporteCuboComisionesContratosScreenComponent } from "../Screens/ReporteCuboComisionesContratosScreenComponent/ReporteCuboComisionesContratosScreenComponent";
import { ReporteDeNominaMasComisionesScreenComponent } from "../Screens/ReporteDeNominaMasComisionesScreenComponent/ReporteDeNominaMasComisionesScreenComponent";
import { EstadoDeCuentaContratosScreenComponent } from "../Screens/EstadoDeCuentaContratosScreenComponent/EstadoDeCuentaContratosScreenComponent";
import { EstadoDeCuentaOrdenesDeServicioScreenComponent } from "../Screens/EstadoDeCuentaOrdenesDeServicioScreenComponent/EstadoDeCuentaOrdenesDeServicioScreenComponent";
import { ReporteDeNominaSoloSalarioScreenComponent } from "../Screens/ReporteDeNominaSoloSalarioScreenComponent/ReporteDeNominaSoloSalarioScreenComponent";
import { ReporteDeNominaComisionesPrevisionScreenComponent } from "../Screens/ReporteDeNominaComisionesPrevisionScreenComponent/ReporteDeNominaComisionesPrevisionScreenComponent";
import { ReporteDeNominaComisioneOrdenServicioScreenComponent } from "../Screens/ReporteDeNominaComisioneOrdenServicioScreenComponent/ReporteDeNominaComisioneOrdenServicioScreenComponent";
import { ReporteOrdenesServicioCreditoContado } from "../Screens/ReporteOrdenesServicioCreditoContado/ReporteOrdenesServicioCreditoContado";
import { ReportesAccesoScreenComponent } from "../Screens/ReportesAccesoScreenComponent/ReportesAccesoScreenComponent";
import { ReporteTotalVentasPrevisionPorFechaComponent } from "../Screens/ReporteTotalVentasPrevisionPorFechaComponent/ReporteTotalVentasPrevisionPorFechaComponent";
import { ReporteTotalVentaOrdenesPorFechas } from "../Screens/ReporteTotalVentaOrdenesPorFechas/ReporteTotalVentaOrdenesPorFechas";
import { NotFound404ScreenComponent } from "../Screens/NotFound404ScreenComponent/NotFound404ScreenComponent";
import { MausoleoScreenComponent } from "../Screens/MausoleoScreenComponent/MausoleoScreenComponent";
import { ReporteDeIngresosScreenComponent } from "../Screens/ReporteDeIngresosScreenComponent/ReporteDeIngresosScreenComponent";
import { MantenimientoScreenComponent } from "../Screens/MantenimientoScreenComponent/MantenimientoScreenComponent";
import { ReporteMantenimientoScreenComponent } from "../Screens/ReporteMantenimientoScreenComponent/ReporteMantenimientoScreenComponent";
import { PanelDeControlScreenComponent } from "../Screens/PanelDeControlScreenComponent/PanelDeControlScreenComponent";
import { AuditoriaScreenComponent } from "../Screens/AuditoriaScreenComponent/AuditoriaScreenComponent";
import { ReporteOrdenesServicioScreenComponent } from "../Screens/ReporteOrdenesServicioScreenComponent/ReporteOrdenesServicioScreenComponent";
import { ReportePagosOrdenesServicioScreenComponent } from "../Screens/ReportePagosOrdenesServicioScreenComponent/ReportePagosOrdenesServicioScreenComponent";
import { VideosScreenComponent } from "../Screens/VideosScreenComponent/VideosScreenComponent";
import IngresarPagosScreenComponent from "../Screens/IngresarPagosScreenComponent/IngresarPagosScreenComponent";
import ReporteZScreenComponent from "./../Screens/ReporteZScreenComponent/ReporteZScreenComponent";
import { EliminarContratosScreenComponent } from "../Screens/EliminarContratosScreenComponent/EliminarContratosScreenComponent";
import { ListadoPanteonesDosPuntoCeroScreenComponent } from "../Screens/ListadoPanteonesDosPuntoCeroScreenComponent/ListadoPanteonesDosPuntoCeroScreenComponent";
import { DetalleDeEspacioScreenComponent } from "../Screens/DetalleDeEspacioScreenComponent/DetalleDeEspacioScreenComponent";
import ReporteExhumacionesScreenComponent from "../Screens/ReporteExhumacionesScreenComponent/ReporteExhumacionesScreenComponent";
import { BuscadorEnEspaciosScreenComponent } from "../Screens/BuscadorEnEspaciosScreenComponent/BuscadorEnEspaciosScreenComponent";
import { ComprobarLigaCambioContrasenaComponent } from "../Screens/ComprobarLigaCambioContrasenaComponent/ComprobarLigaCambioContrasenaComponent";
import CatalogoDeDocumentosScreenComponent from "../Screens/CatalogoDeDocumentosScreenComponent/CatalogoDeDocumentosScreenComponent";
import SeriesDeCobranzaScreenComponent from "../Screens/SeriesDeCobranzaScreenComponent/SeriesDeCobranzaScreenComponent";
import SeriesDeCobranzaCobradoresScreenComponent from "../Screens/SeriesDeCobranzaCobradoresScreenComponent/SeriesDeCobranzaCobradoresScreenComponent";

export const AppRouter = () => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [tipoMonitor, setTipoMonitor] = React.useState("");

  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <SetWindowNameHelperComponent windowName={"Iniciar Sesión"} />
              <LoginScreenComponent />
            </PublicRoute>
          }
        ></Route>

        <Route
          path="/restorepassword"
          element={
            <PublicRoute>
              <SetWindowNameHelperComponent
                windowName={"Recuperar Contraseña"}
              />
              <RestorePasswordComponent />
            </PublicRoute>
          }
        ></Route>

        <Route
          path="/cambiarcontrasena/:id"
          element={
            <PublicRoute>
              <SetWindowNameHelperComponent windowName={"Cambiar Contraseña"} />
              <ComprobarLigaCambioContrasenaComponent />
            </PublicRoute>
          }
        ></Route>

        <Route
          path="/"
          element={
            <PrivateRoute>
              <InicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/paneldecontrol"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Panel de Control" />
              <PanelDeControlScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/seriesdecobranza"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Series de Cobranza" />
              <SeriesDeCobranzaScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/seriescobradores"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Series / Cobradores" />
              <SeriesDeCobranzaCobradoresScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/eliminarcontratos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Eliminar Contratos" />
              <EliminarContratosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/catalogodocumentos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Catálogo de Documentos" />
              <CatalogoDeDocumentosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/preciosmantenimiento"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Panel de Control" />
              <MantenimientoScreenComponent precios={true} />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/preciosmantenimientoconfiguracion"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Panel de Control" />
              <MantenimientoScreenComponent precios={true} />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/auditoria"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Auditoria" />
              <AuditoriaScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/usuarios"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Usuarios de sistema" />
              <UsersScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/contratos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Contratos de Previsión" />
              <ContratosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/pagoscontratos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Agregar pagos" />
              <AddPaymentsQuickComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/videos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Videos" />
              <VideosScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/buscadordepagos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Buscador de recibos" />
              <BusquedaDePagosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/mantenimiento"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Mantenimiento" />
              <MantenimientoScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/contrato/:numerocontrato/:tababrir"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Detalle de Contrato" />
              <DetalleContratoScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/ordenes"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Órdenes de Servicio" />
              <OrdenesServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/404"
          element={
            <PrivateRoute>
              <NotFound404ScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/ordenservicio/:idordenservicio/:tababrir"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Detalle de Servicio" />
              <DetalleOrdenServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/reportes"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reportes" />
              <ReportesAccesoScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reporteventas"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de Ventas" />

              <ReportesScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/mantenimiento"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de Mantenimiento" />

              <ReporteMantenimientoScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/buscadorenespacios"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Buscador en espacios" />

              <BuscadorEnEspaciosScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/reportes/concentradoordenesservicio"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte concentrado de órdenes de servicio" />

              <ReporteOrdenesServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/pagosordenesservicio"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de órdenes de servicio" />

              <ReportePagosOrdenesServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/reportes/reportez"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Z" />
              <ReporteZScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reporteexhumaciones"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Exhumaciones" />
              <ReporteExhumacionesScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/ingresos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de Ingresos" />

              <ReporteDeIngresosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/resumenserie"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de cartera" />
              <ReporteResumenSerieScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/cobranzarangofecha"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte de cobranza por fechas (Serie Gestor)" />
              <ReporteCobranzaRangoFechaScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportetotalventaprevisionporfechas"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte total ventas previsión por fecha" />
              <ReporteTotalVentasPrevisionPorFechaComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportetotalventaprevisionporfechas/:fechainicio/:fechafin/:ejecutar"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte total ventas previsión por fecha" />
              <ReporteTotalVentasPrevisionPorFechaComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportetoralventaordenesporfechas"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte total ventas Ordenes de servicio por fecha" />
              <ReporteTotalVentaOrdenesPorFechas />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/cubocomisiones"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Cubo de comisiones órdenes de servicio" />
              <ReporteCuboComisionesScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/cubocomisionescontratos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Cubo de comisiones de contratos" />
              <ReporteCuboComisionesContratosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportenomina"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Nómina + Comisiones" />
              <ReporteDeNominaMasComisionesScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportenominasalario"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte nómina quincenal (General, Particular)" />
              <ReporteDeNominaSoloSalarioScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportenominacomisionesprevision"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Comisiones de venta (contratos)" />
              <ReporteDeNominaComisionesPrevisionScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reportenominacomisionesordenesservicio"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Nómina - Comisiones Ordenes de Servicio" />
              <ReporteDeNominaComisioneOrdenServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/reportes/reporteordenesserviciocreditocontado"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Reporte Ordenes de Servicio Crédito Contado" />
              <ReporteOrdenesServicioCreditoContado />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/administracion"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Administración" />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/serviciosordenesservicio"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Agregar y Editar servicios para órdenes de servicio" />
              <ServiciosOrdenesServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/empleados"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Empleados" />
              <EmpleadosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/empleado/:id"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Detalle Empleado" />
              <PerfilEmpleadoScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/panteones"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Espacios" />
              <PanteonesScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/catalogoespacios"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Catálogo de Serentia" />

              <ListadoPanteonesDosPuntoCeroScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/estadodecuentacontratos"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Estado de Cuenta Contratos" />
              <EstadoDeCuentaContratosScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/estadodecuentaordenesservicio"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Estado de Cuenta Ordenes de Servicio" />
              <EstadoDeCuentaOrdenesDeServicioScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/ingresarpagos"
          element={
            <PrivateRoute>
              <IngresarPagosScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/jardines/panteon/:panteon"
          element={
            <PrivateRoute>
              <JardinesScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/detalleubicacion/:panteon"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Detalle de Ubicación" />
              <DetalleDeEspacioScreenComponent />
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/panteones/jardin/uniplex/:jardin"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Uniplex" />
              <UniplexScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/panteones/jardin/duplex/:jardin"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Duplex" />
              <DuplexScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/panteones/jardin/murales/:jardin"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Murales" />
              <MuralesScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/panteones/ubicacion/mausoleo/:jardin"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Mausoleo" />
              <MausoleoScreenComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/panteon/capillas/:panteon"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Capillas de Familiares" />

              <CapillasDePanteonComponent />
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/panteon/capillas/espacios/:idcapilla"
          element={
            <PrivateRoute>
              <TitleScreenComponent titulo="Espacios de capilla" />
              <EspaciosDeCapillaComponent />
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </Router>
  );
};
