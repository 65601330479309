import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { getAllCapillasByIdPanteon } from "../../API/ApiCapillas";
import { checkPermisosPorUsuarioPorGuidPantalla } from "../../API/ApiPermisos";
import { getAllUniplexByIdJardin } from "../../API/ApiUniplex";
import { AuthContext } from "../../auth/authContext";
import { AddCapillaToPantonComponent } from "../../Components/AddCapillaToPantonComponent/AddCapillaToPantonComponent";
import { AddUniplexScreenComponent } from "../../Components/AddUniplexScreenComponent/AddUniplexScreenComponent";
import { EditCapillaToPanteonComponent } from "../../Components/EditCapillaToPanteonComponent/EditCapillaToPanteonComponent";
import { LoadingSkeletonComponent } from "../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
const guidVista = "6b203d5f-1db7-4edf-a929-d8e21c15e9aa";

export const CapillasDePanteonComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  const [finalData, setFinalData] = React.useState([]);

  let { panteon } = useParams();

  panteon = JSON.parse(panteon);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoEditar, setPermisoEditar] = React.useState(false);
  const [permisoAgregar, setPermisoAgregar] = React.useState(false);

  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "6e23ccf5-f469-4084-9dfd-2044091b1dc4" &&
              permiso.allowed == 1
            ) {
              setPermisoEditar(true);
            }
            if (
              permiso.guidFuncion == "ade2fe41-44e6-4f07-8554-18a04382fc6f" &&
              permiso.allowed == 1
            ) {
              setPermisoAgregar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getAllCapillasByIdPanteon(user, panteon.id).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
      setLoading(false);
    });
  }, []);
  React.useEffect(() => {
    getAllCapillasByIdPanteon(user, panteon.id).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
      setLoading(false);
    });
  }, [crudAction]);

  return (
    <>
      {permisoAgregar == true ? (
        <>
          {" "}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-end my-2">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  Agregar Capillas Familiares
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {loading == true ? (
        <LoadingSkeletonComponent />
      ) : (
        <>
          <div className="container-fluid">
            <div className="row row-cols-4">
              {finalData.map((capilla, index) => (
                <div key={index} className="col">
                  <div className="card">
                    <div className="card-body">
                      {capilla.nombre}
                      <br />
                      <small>{capilla.nombrePanteon}</small>
                    </div>
                    <div className="card-footer">
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {permisoEditar == true ? (
                          <>
                            {" "}
                            <button
                              className="btn btn-sm btn-warning"
                              type="button"
                              onClick={() => {
                                setCrudAction({
                                  action: "edit",
                                  data: capilla,
                                });
                              }}
                            >
                              Editar
                            </button>
                          </>
                        ) : null}

                        <button
                          className="btn btn-sm btn-success"
                          type="button"
                          onClick={() => {
                            window.open(
                              "/panteon/capillas/espacios/" +
                                encodeURI(JSON.stringify(capilla)),
                              "_self"
                            );
                          }}
                        >
                          Acceder a espacios
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {crudAction.action == "add" ? (
        <>
          <AddCapillaToPantonComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            panteon={panteon}
          />
        </>
      ) : null}
      {crudAction.action == "edit" ? (
        <>
          <EditCapillaToPanteonComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            panteon={panteon}
          />
        </>
      ) : null}
    </>
  );
};
