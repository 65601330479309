import React from "react";
import styles from "./SinPermisoComponent.module.css";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
export const SinPermisoComponent = () => {
  return (
    <>
      <div className={styles.sinPermisoDiv}>
        <h1>
          <DoDisturbIcon className="h1" fontSize="large" style={{color: 'red'}} />
        </h1>
        <h3>No tienes permiso para acceder a este recurso</h3>
      </div>
    </>
  );
};
