import React from "react";
import { getAllOrdenesDeServicio } from "../../API/ApiOrdenesServicio";
import { AuthContext } from "../../auth/authContext";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import imageCross from "../../Images/cross.png";
import { AddOrdenServicioComponent } from "../../Components/AddOrdenServicioComponent/AddOrdenServicioComponent";
import { LoadingSkeletonComponent } from "../../Components/LoadingSkeletonComponent/LoadingSkeletonComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "./../../API/ApiPermisos";
import { SinPermisoComponent } from "../../Components/SinPermisoComponent/SinPermisoComponent";
import { BuscadorDeOrdenesDeServicioComponent } from "../../Components/BuscadorDeOrdenesDeServicioComponent/BuscadorDeOrdenesDeServicioComponent";
import RuleFolderIcon from "@mui/icons-material/RuleFolder";
import { CancelarFolioOrdenServicioComponent } from "../../Components/CancelarFolioOrdenServicioComponent/CancelarFolioOrdenServicioComponent";
import CambiarFormatoFechaHelper from "../../Helpers/CambiarFormatoFechaHelper";
import TablaUltimasOrdenesComponent from "../../Components/TablaUltimasOrdenesComponent/TablaUltimasOrdenesComponent";
const guidVista = "d5376ba2-cefd-4edd-8402-362d5bd4c75d";

export const OrdenesServicioScreenComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [agregarPagoOrdenServicio, setAgregarPagoOrdenServicio] =
    React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "3a7e89a7-f78d-4d76-861d-23d227c839d7" &&
              permiso.allowed == 1
            ) {
              setPermisoParaAgregar(true);
            }
            if (
              permiso.guidFuncion == "0e25097d-f4e0-48a5-a333-e88c5344a89c" &&
              permiso.allowed == 1
            ) {
              setPermisoParaVisualizar(true);
            }
            if (
              permiso.guidFuncion == "de774da3-8f3a-438b-a392-90a181b53081" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    getAllOrdenesDeServicio(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
        setIsLoaded(true);
      } else {
        setIsLoaded([]);
      }
    });
  }, []);

  return (
    <>
      {permisoParaVisualizar == true ? (
        <>
          {permisoParaAgregar == true ? (
            <>
              <div className="container-fluid mt-2 text-end">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setCrudAction({
                      action: "add",
                      data: null,
                    });
                  }}
                >
                  Agregar Orden de Servicio
                </button>
                <br />
                <br />
                <button
                  className="btn btn-warning btn-sm ms-1"
                  onClick={() => {
                    setCrudAction({
                      action: "cancelarFolioPrevision",
                      data: null,
                    });
                  }}
                >
                  <RuleFolderIcon /> Cancelar Folio de Orden de Servicio
                </button>
              </div>
            </>
          ) : null}

          {isLoaded == false ? (
            <LoadingSkeletonComponent />
          ) : (
            <>
              <div className="container-fluid">
                <div className="row row-cols-md-3">
                  <div className="col my-2">
                    <h5>Últimas órdenes creadas en el mes actual</h5>
                  </div>
                </div>
              </div>

              <div className="container-fluid mt3">
                <BuscadorDeOrdenesDeServicioComponent />
              </div>

              <TablaUltimasOrdenesComponent />

              <div className="container-fluid mt-3 d-none">
                <div className="row row-cols-md-3">
                  {finalData.map((ordenServicio, index) => (
                    <div className="col" key={index}>
                      <div className="card mb-2">
                        <div className="card-body small">
                          <img
                            height={"17"}
                            alt={ordenServicio.nombreFinado}
                            src={imageCross}
                          />{" "}
                          {CambiarFormatoFechaHelper(ordenServicio.fecha)}{" "}
                          <br />
                          <strong> {ordenServicio.folio}</strong> -
                          <strong> {ordenServicio.nombreFinado}</strong>
                        </div>
                        <div className="card-footer">
                          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button
                              className="btn btn-success btn-sm"
                              type="button"
                              onClick={() => {
                                window.open(
                                  `/ordenservicio/${ordenServicio.folio}/false`,
                                  "_self"
                                );
                              }}
                            >
                              Abrir orden <ChevronRightIcon fontSize="small" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {crudAction.action === "add" ? (
            <AddOrdenServicioComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Agregar Nueva Orden de Servicio"
            />
          ) : null}

          {crudAction.action === "cancelarFolioPrevision" ? (
            <CancelarFolioOrdenServicioComponent
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              nombre="Cancelar un folio de orden de servicio"
            />
          ) : null}
        </>
      ) : (
        <SinPermisoComponent />
      )}
    </>
  );
};
