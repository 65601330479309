import React from "react";
import { AuthContext } from "../../auth/authContext";
import { useFormik } from "formik";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { addPrecioMantenimientoAPI } from "../../API/ApiMantenimientos";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import { getAllEspacios } from "../../API/ApiEspacios";

const validationSchema = yup.object({
  ano: yup.string("Año").required("Año requerido"),
  precio: yup.string("Precio").required("Precio Requerido"),
  idubicacion: yup.string("Precio").required("Precio Requerido"),
});

export const AddPrecioMantenimientoComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [finalData, setFinalData] = React.useState([]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  React.useEffect(() => {
    getAllEspacios(user).then((resultado) => {
      if (resultado.status == 200) {
        setFinalData(resultado.data);
      } else {
        setFinalData([]);
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      ano: new Date().getFullYear().toString(),
      precio: "",
      idubicacion: "1",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      addPrecioMantenimientoAPI(user, values).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Precio Agregado a Mantenimiento Correctamente");
          props.setCrudAction({ action: null, data: null });
        }
      });
    },
  });

  return (
    <div className="crudDivComponent col-md-3 px-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>Agregar Precio de Mantenimiento</h6>
                <small className="text-muted">
                  Ingresa los datos para agregar el Precio
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {isDisabled == true ? (
              <>
                <Alert className="mb-3" severity="info">
                  Procesando información...
                </Alert>
              </>
            ) : null}
            <hr />
          </div>
        </div>
        <div className="row">
          <form onSubmit={formik.handleSubmit} className="container-fluid">
            <div className="row row-cols-1">
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" shrink htmlFor="ano" >
                    Año
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: "ano",
                      id: "ano",
                    }}
                    onChange={formik.handleChange}
                  >
                    <option value={2014}>2014</option>
                    <option value={2015}>2015</option>
                    <option value={2016}>2016</option>
                    <option value={2017}>2017</option>
                    <option value={2018}>2018</option>
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                    <option value={2021}>2021</option>
                    <option value={2022}>2022</option>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                    <option value={2026}>2026</option>
                    <option value={2027}>2027</option>
                    <option value={2028}>2028</option>
                    <option value={2029}>2029</option>
                    <option value={2030}>2030</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <br />
              <br />
              <br />
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel variant="standard" shrink htmlFor="idubicacion" >
                    Tipo Espacio
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: "idubicacion",
                      id: "idubicacion",
                    }}
                    onChange={formik.handleChange}
                  >
                    {finalData.map((espacio, index) => (
                      <option key={index} value={espacio.id}>
                        {espacio.espacio}
                      </option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </div>
              <br />
              <br />
              <br />
              <div className="col">
                <TextField
                  size="small"
                  fullWidth
                  id="precio"
                  name="precio"
                  label="Precio"
                  type={"number"}
                  className="mb-3"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.precio}
                  onChange={formik.handleChange}
                  variant="standard"
                  error={formik.touched.precio && Boolean(formik.errors.precio)}
                  helperText={formik.touched.precio && formik.errors.precio}
                  disabled={isDisabled}
                />
              </div>

              <div className="col">
                <button type="submit" className="btn btn-sm btn-success w-100">
                  Agregar Precio
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
